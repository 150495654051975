import React, { useEffect } from "react";
import styles from "./our-team.module.scss";
import CEO from "../../../assets/images/CEO.png";
import PropTypes from "prop-types";

import LeftArr from "../../../assets/images/leftArrowsHowWeWork.svg";
import RightArr from "../../../assets/images/rightArrowsHowWeWork.svg";
import letterO from "../../../assets/images/o.png";

import { AboutUsOurTeamLogo } from "../../components";

import { animate as animateLetter } from "../../components/AboutUsOurTeamLogo/anime";
import { animate as animateOther } from "./anime";
import { useTranslation } from "react-i18next";

const OurTeam = props => {
  const { team, getTeam } = props;

  useEffect(() => {
    getTeam();
  }, [getTeam]);

  const content = {
    CEO: {
      img: CEO,
      name: "Artem Mykhailov",
      position: "Chief Executive Officer & Founder"
    }
  };

  useEffect(() => {
    if (window.matchMedia("(min-width: 769px)").matches) {
      animateLetter();
      animateOther();
    }
  }, []);

  const { t } = useTranslation('translations');
  
  function compare(a, b) {
    if (a.location < b.location) {
      return -1;
    }
    if (a.location > b.location) {
      return 1;
    }
    return 0;
  }

  team.sort(compare);

  return (
    <div className={styles.root}>
      <img src={LeftArr} className={styles.leftArr} alt="" />
      <img src={RightArr} className={styles.rigthArr} alt="" />

      <div className={`${styles.backGroundRectangleLeft}`} />
      <div className={`${styles.backGroundRectangleRight} ${styles.left}`} />
      <div className={`${styles.backGroundRectangleLeftSec} ${styles.left}`} />

      <div className={styles.section}>
        <div className={styles.logoBlock}>
          <AboutUsOurTeamLogo />
          <img src={letterO} className={styles.letterO} alt="" />
          <div className={styles.description}>
            {t("team_description_text")
              .split("\n")
              .map((p, key) => (
                <p key={key}>{p}</p>
              ))}
          </div>
        </div>
      </div>
      <div className={styles.teamCEO}>
        <img className={styles.teamCEOImg} src={CEO} alt="" />
        <h2>{content.CEO.name}</h2>
        <h3>{content.CEO.position}</h3>
      </div>
      <div className={`${styles.section} ${styles.center}`}>
        <div className={styles.team}>
          {team.map((item, key) => {
            return (
              <div key={key} className={styles.member}>
                <img
                  src={`https://magisoft.solutions/assets/${item.image}`}
                  alt={`${item.name} photography`}
                />
                <h2>{t(item.name)}</h2>
                <h3>{t(item.position)}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

OurTeam.defaultProps = {};

OurTeam.propTypes = {
  team: PropTypes.array,
  getTeam: PropTypes.func
};

export default OurTeam;

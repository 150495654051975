import React from "react";
import styles from "./admin-header.module.scss";
import logo from "../../../assets/images/magisoftHeaderLogo.png";

const AdminHeader = () => {
  const logOut = e => {
    e.preventDefault();
    localStorage.removeItem("usertoken");
    window.location.reload(false);
  };

  return (
    <div className={styles.root}>
      <img className={styles.img} src={logo} alt="logo" />
      <h2>Magisoft</h2>
      <h3>Admin panel</h3>
      {localStorage.usertoken ? (
        <button onClick={logOut} className={styles.logOut}>
          Log out
        </button>
      ) : null}
    </div>
  );
};

export default AdminHeader;

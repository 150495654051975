export const transformTeamObjToEditorObj = (teamObj, enT, uaT) => {
  return {
    nameEn: enT(teamObj.name),
    nameUa: uaT(teamObj.name),
    nameRu: '',
    positionEn: enT(teamObj.position),
    positionUa: uaT(teamObj.position),
    positionRu: '',
    image: null,
    imageName: teamObj.image,
    location: teamObj.location,
    id: teamObj.id
  }
}


const srtToServerFormat = (str) => {
  return JSON.stringify(str.split('\n'))
}

const formatStr = (str) => {
  let arr = [];

  try {
    arr = JSON.parse(str);
  }
  catch (e) { }

  if (Array.isArray(arr) && arr.length > 0) {
    return arr.join('\n');
  }

  else return str;
}

export const transformEditorObjToTechStackObj = (obj) => {
  return {
    ...obj,
    descEn: srtToServerFormat(obj.descriptionEn),
    descUa: srtToServerFormat(obj.descriptionUa),
    descRu: srtToServerFormat(obj.descriptionRu),
  }
}

export const transformTechStackObjToEditorObj = (techObj, enT, uaT) => {
  return {
    titleEn: enT(techObj.title),
    titleUa: uaT(techObj.title),
    titleRu: '',
    summaryEn: enT(techObj.summary),
    summaryUa: uaT(techObj.summary),
    summaryRu: '',
    descriptionEn: formatStr(enT(techObj.description)),
    descriptionUa: formatStr(uaT(techObj.description)),
    descriptionRu: '',
    logo: null,
    logoName: techObj.logo,
    id: techObj.id
  }
};
import React from "react";
import styles from "./about-us-title.module.scss";
import { useTranslation } from "react-i18next";

const AboutUsTitle = () => {
  const { t } = useTranslation("translations");
  return (
    <div id="about-us-title-container" className={styles.container}>
      <h2 id="about-us-part-title" className={styles.title}>
        {t("about_us_section_name_text")}
      </h2>
      <svg className={styles.aboutUsLetterAnim} id="about-us-letter-anim">
        <g className={styles.aboutUsLetterAnimContent}>
          <path
            id="about-us-part-1"
            d="M262.141 167.5L205.859 167.5L234 216.988L262.141 167.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-2"
            d="M262.141 161.5L205.859 161.5L234 112.012L262.141 161.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-3"
            d="M228.141 111.5L171.859 111.5L200 160.988L228.141 111.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-4"
            d="M228.141 106.5L171.859 106.5L200 57.0115L228.141 106.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-5"
            d="M136.867 56.5L194.133 56.5L165.5 106.001L136.867 56.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-6"
            d="M136.867 50.5L194.133 50.5L165.5 0.998601L136.867 50.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-7"
            d="M159.148 0.499999L103.852 0.5L131.5 49.975L159.148 0.499999Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-8"
            d="M126.133 50.5L68.8668 50.5L97.5 0.998603L126.133 50.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-9"
            d="M68.8668 56.5L126.133 56.5L97.5 106.001L68.8668 56.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />

          <path
            id="about-us-part-10"
            d="M34.8595 106.5L91.1405 106.5L63 57.0115L34.8595 106.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-11"
            d="M34.8595 111.5L91.1405 111.5L63 160.988L34.8595 111.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-12"
            d="M0.859497 161.5L57.1405 161.5L29 112.012L0.859497 161.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-13"
            d="M0.859497 167.5L57.1405 167.5L29 216.988L0.859497 167.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-14"
            d="M68.8595 161.5L125.141 161.5L97 112.012L68.8595 161.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />

          <path
            id="about-us-part-15"
            d="M103.852 111.5L159.148 111.5L131.5 160.975L103.852 111.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="about-us-part-16"
            d="M194.133 161.5L136.867 161.5L165.5 111.999L194.133 161.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
      </svg>
    </div>
  );
};

export default AboutUsTitle;

import React from "react";
import styles from "./career-frontend-logo.module.scss";

const CareerBackendLogo = () => {
  return (
    <>
      <h2 className={styles.title}>
        <span>Front-end</span>
        <span>Developer</span>
      </h2>
      <svg
        className={styles.letterAnim}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={styles.letterTop}>
          <path
            d="M50.5 103.433L50.5 159.996L1.00774 131.714L50.5 103.433Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M56.5 35.1474L56.5 91.7099L105.992 63.4287L56.5 35.1474Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M0.499997 125.71L0.5 69.1474L49.9923 97.4286L0.499997 125.71Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M106.5 57.4242L106.5 0.86159L57.0077 29.1429L106.5 57.4242Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M112.5 57.4242L112.5 0.86159L161.992 29.1429L112.5 57.4242Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M50.5 34.8616L50.5 91.4242L1.00774 63.1429L50.5 34.8616Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M0.499998 194.281L0.5 137.719L49.9923 166L0.499998 194.281Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
        <g className={styles.letterBottom}>
          <path
            d="M50.5 172.005L50.5 228.567L1.00774 200.286L50.5 172.005Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M0.500005 206.29L0.5 262.853L49.9923 234.572L0.500005 206.29Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M106.5 137.862L106.5 194.424L57.0077 166.143L106.5 137.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M50.5 297.138L50.5 240.576L1.00774 268.857L50.5 297.138Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M56.5 228.71L56.5 172.147L105.992 200.428L56.5 228.71Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M0.499998 331.138L0.5 274.576L49.9923 302.857L0.499998 331.138Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
      </svg>
    </>
  );
};

export default CareerBackendLogo;

import React, { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTranslation } from "./stores/translation/actions";
import "./App.css";
import { Header } from "./views/components";
import Routes from "./routes";
import Footer from "./views/components/Footer";
import Spinner from "./views/components/Spinner";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/src/styles/index.scss";
import ScrollToTop from "./views/components/ScrollToTop/ScrollToTop";
const App = props => {
  const { getTranslation } = props;

  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    getTranslation().then(res => {
      setLoaded(true);
    });
  }, [getTranslation]);

  return (
   loaded ? <div className="app">
      <ScrollToTop>
        {props.location.pathname !== "/admin-panel" && <Header />}
        <div className="app-content">
          <Routes />
        </div>
        <ReduxToastr
          timeOut={3000}
          newestOnTop={true}
          preventDuplicates
          position="top-right"
          getState={state => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        {props.location.pathname !== "/admin-panel" && <Footer />}
      </ScrollToTop>
    </div> : <Spinner />
  );
};

const mapDispatchToProps = {
  getTranslation
};

export default withRouter(connect(null, mapDispatchToProps)(App));

import axios from "axios";
import constants from "./constants";
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from '../../enviroments';
import { getTranslation } from "../translation/actions";
/**
 * @TODO PRIORITY LOW reduce boilerplate
 */

/**
 * GET LIST OF TECH
 */

export const getTechStack = () => {
  return async dispatch => {
    dispatch(request());
    return await axios
      .get(`${PROD_BASE_URL}/technology`)
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: constants.TECH_STACK_GET_REQUEST };
  }
  function success(data) {
    return { type: constants.TECH_STACK_GET_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.TECH_STACK_GET_FAILURE, error };
  }
};

/**
 * REMOVE ONE TECH
 */

export const deleteTech = id => {
  return async dispatch => {
    dispatch(request());
    return await axios
      .delete(`${PROD_BASE_URL}/technology/${id}`)
      .then(response => {
        dispatch(success(id));
        toastr.success("Success", "Deleted");

        return response;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request() {
    return { type: constants.TECH_STACK_DELETE_ITEM_REQUEST };
  }
  function success(id) {
    return { type: constants.TECH_STACK_DELETE_ITEM_SUCCESS, payload: { id } };
  }
  function failure(error) {
    return { type: constants.TECH_STACK_DELETE_ITEM_FAILURE, error };
  }
};

/**
 * ADD NEW TECH
 */

export const addTech = data => {
  return async dispatch => {
    dispatch(request(data));
    return await axios
      .post(`${PROD_BASE_URL}/technology/`, data)
      .then(response => {
        /**
         * Shitty decision, refactor back, then refactor this
         */
        dispatch(getTranslation()).then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Created");
        return response.data;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request() {
    return { type: constants.TECH_STACK_ADD_ITEM_REQUEST };
  }
  function success(data) {
    return { type: constants.TECH_STACK_ADD_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.TECH_STACK_ADD_ITEM_FAILURE, error };
  }
};

/**
 * UPDATE ONE TECH
 */

export const updateTech = (data, id) => {
  const tmId = id || data.id || data._id;
  return async dispatch => {
    dispatch(request(data));
    return await axios
      .put(`${PROD_BASE_URL}/technology/${tmId}`, data)
      .then(response => {
        dispatch(getTranslation()).then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Updated");

        return response;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request() {
    return { type: constants.TECH_STACK_UPDATE_ITEM_REQUEST, payload: data };
  }
  function success(data) {
    return { type: constants.TECH_STACK_UPDATE_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.TECH_STACK_UPDATE_ITEM_FAILURE, error };
  }
};

import React from "react";
import PropTypes from "prop-types";
import styles from "./admin-save-button.module.scss";
import cn from "classnames";

const Button = props => {
  const { view, children, onClick, type = "button", disabled } = props;

  const clsName = cn(styles.root, {
    [styles.primary]: view === "primary",
    [styles.danger]: view === "danger",
    [styles.add]: view === "add",
    [styles.disabled]: disabled
  });

  return (
    <button className={clsName} onClick={onClick} type={type}>
      {children}
    </button>
  );
};

Button.defaultProps = {};

Button.propTypes = {
  view: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;

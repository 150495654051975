import anime from "animejs/lib/anime.es.js";
import styles from './portfolio.module.scss';

export function animate(opts) {
  
    anime({
      targets: `.${styles.trap}`,
      translateX: ['-800px', '0'],
      ...opts
    });

    anime({
      targets: `.${styles.sliderBlock}`,
      translateX: ['200vh', '0vh'],
      ...opts
    });

}
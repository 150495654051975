import React from "react";
import styles from "./main-admin.module.scss";
import AdminContentTitle from "../../components/AdminContentTitle";

const MainAdmin = () => {
  return (
    <div className={styles.root}>
      <AdminContentTitle title="Main" />
      <div className={styles.content}>
        <div className={styles.fields}></div>
        <div className={styles.fields}></div>
      </div>
    </div>
  );
};

export default MainAdmin;

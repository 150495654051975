import React from "react";
import styles from "./contact-us-title.module.scss";
import { useTranslation } from "react-i18next";

const ContactUsTitle = () => {
  const { t } = useTranslation("translations");
  return (
    <div id="contact-us-container" className={styles.root}>
      <h2 className={styles.title}>{t("contact_us_section_name_text")}</h2>
      <svg id="contact-us-letter-anim" className={styles.contactUsLetterAnim}>
        <g className={styles.contactUsLetterGroup}>
          <path
            id="part1"
            d="M170.416 51.3403L226.983 51.3403L198.7 1.84456L170.416 51.3403Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part2"
            d="M192.695 1.33643L136.128 1.33643L164.411 50.8322L192.695 1.33643Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part3"
            d="M101.84 51.3403L158.407 51.3403L130.123 1.84456L101.84 51.3403Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part4"
            d="M124.119 1.33643L67.552 1.33643L95.8353 50.8322L124.119 1.33643Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part5"
            d="M33.2638 51.3403L89.8305 51.3403L61.5471 1.84456L33.2638 51.3403Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part6"
            d="M35.1497 57.1401L91.7163 57.1401L63.433 106.636L35.1497 57.1401Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part7"
            d="M57.4282 107.144L0.86159 107.144L29.1449 57.6478L57.4282 107.144Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part8"
            d="M57.4282 112.944L0.86159 112.944L29.1449 162.44L57.4282 112.944Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part9"
            d="M91.7163 162.948L35.1497 162.948L63.433 113.452L91.7163 162.948Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part10"
            d="M89.8305 168.748L33.2638 168.748L61.5471 218.243L89.8305 168.748Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part11"
            d="M67.5519 218.751L124.119 218.751L95.8352 169.256L67.5519 218.751Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part12"
            d="M158.407 168.748L101.84 168.748L130.123 218.243L158.407 168.748Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part13"
            d="M136.128 218.751L192.695 218.751L164.411 169.256L136.128 218.751Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="part14"
            d="M226.983 168.748L170.416 168.748L198.7 218.243L226.983 168.748Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
      </svg>
    </div>
  );
};

export default ContactUsTitle;

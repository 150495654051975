import axios from "axios";
import constants from "./constants";
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from '../../enviroments';
import { getTranslation } from "../translation/actions";
/**
 * GET LIST OF PORTFOLIO PROJECTS
 */

export const getPortfolio = () => {
  return async dispatch => {
    dispatch(request());
    return await axios
      .get(`${PROD_BASE_URL}/portfolio`)
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: constants.PORTFOLIO_GET_REQUEST };
  }
  function success(data) {
    return { type: constants.PORTFOLIO_GET_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.PORTFOLIO_GET_FAILURE, error };
  }
};

/**
 *  ADD NEW PORTFOLIO PROJECT
 */

export const addPortfolioItem = data => {
  return async dispatch => {
    dispatch(request(data));
    return await axios
      .post(`${PROD_BASE_URL}/portfolio`, data)
      .then(response => {
        dispatch(getTranslation()).then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Created");

        return response.data;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request(data) {
    return { type: constants.PORTFOLIO_ADD_ITEM_REQUEST, payload: data };
  }
  function success(data) {
    return { type: constants.PORTFOLIO_ADD_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.PORTFOLIO_ADD_ITEM_FAILURE, error };
  }
};

/**
 * DELETE ONE PORTFOLIO PROJECT
 */

export const deletePortfolioItem = id => {
  return async dispatch => {
    dispatch(request(id));
    return await axios
      .delete(`${PROD_BASE_URL}/portfolio/${id}`)
      .then(response => {
        // dispatch(success(response.data));
        dispatch(success(id));
        toastr.success("Success", "Deleted");
        return response;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request(id) {
    return { type: constants.PORTFOLIO_DELETE_ITEM_REQUEST, payload: id };
  }
  function success(id) {
    return { type: constants.PORTFOLIO_DELETE_ITEM_SUCCESS, payload: { id } };
  }
  function failure(error) {
    return { type: constants.PORTFOLIO_DELETE_ITEM_FAILURE, error };
  }
};

/**
 * UPDATE ONE PORTFOLIO PROJECT
 */

export const updatePortfolioItem = (data, id) => {
  const prId = id || data.id || data._id;

  return async dispatch => {
    dispatch(request(id));
    return await axios
      .put(`${PROD_BASE_URL}/portfolio/${prId}`, data)
      .then(response => {
        dispatch(getTranslation()).then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Updated");

        return response;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
        return error;
      });
  };

  function request(id) {
    return { type: constants.PORTFOLIO_UPDATE_ITEM_REQUEST, payload: id };
  }
  function success(data) {
    return { type: constants.PORTFOLIO_UPDATE_ITEM_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.PORTFOLIO_UPDATE_ITEM_FAILURE, error };
  }
};

import React from "react";
import styles from "./about-us-block.module.scss";
import { useTranslation } from "react-i18next";
import ClientButton from "../ClientButton/ClientButton";

const AboutUsBlock = () => {
  const { t } = useTranslation("translations");
  return (
    <div className={styles.aboutUs}>
      <h2 className={styles.title}>{t("about_us_title_text")}</h2>
      {t("about_us_paragraph_text")
        .split("\n")
        .map((p, key) => (
          <p key={key} className={styles.text}>
            {p}
          </p>
        ))}
      <ClientButton
        link
        to={"/our-team"}
        className={styles.btn}
        text={"about_us_btn_text"}
        position={"right"}
      />
    </div>
  );
};

export default AboutUsBlock;

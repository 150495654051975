const srtToServerFormat = (str) => {
    return JSON.stringify(str.split('\n'))
    // .replace(/\"/g,'\\"');
}
const formatStr = (str) => {

    let arr = [];
    try {
        arr = JSON.parse(str);
    }
    catch (e) { }

    if (Array.isArray(arr) && arr.length > 0) {
        return arr.join('\n');
    }

    else return str;
}
export const transformeditorObjToVacancyObj = (obj) => {
    const res = {
        ...obj,
        requirementsEn: srtToServerFormat(obj.requirementsEn),
        requirementsUa: srtToServerFormat(obj.requirementsUa),
        requirementsRu: srtToServerFormat(obj.requirementsRu),
        willBePlusEn: srtToServerFormat(obj.willBePlusEn),
        willBePlusUa: srtToServerFormat(obj.willBePlusUa),
        willBePlusRu: srtToServerFormat(obj.willBePlusRu),
        responsibilitiesEn: srtToServerFormat(obj.responsibilitiesEn),
        responsibilitiesUa: srtToServerFormat(obj.responsibilitiesUa),
        responsibilitiesRu: srtToServerFormat(obj.responsibilitiesRu),
        conditionsEn: srtToServerFormat(obj.conditionsEn),
        conditionsUa: srtToServerFormat(obj.conditionsUa),
        conditionsRu: srtToServerFormat(obj.conditionsRu),
    }
    return res;
}

export const transformVacancyObjToEditorObj = (obj, enT, uaT) => {
    return {
        name: obj.name,
        requirementsEn: formatStr(enT(obj.requirements)),
        requirementsRu: '',
        requirementsUa: formatStr(uaT(obj.requirements)),
        willBePlusEn: formatStr(enT(obj.willBePlus)),
        willBePlusRu: '',
        willBePlusUa: formatStr(uaT(obj.willBePlus)),
        responsibilitiesEn: formatStr(enT(obj.responsibilities)),
        responsibilitiesRu: '',
        responsibilitiesUa: formatStr(uaT(obj.responsibilities)),
        conditionsEn: formatStr(enT(obj.conditions)),
        conditionsRu: '',
        conditionsUa: formatStr(uaT(obj.conditions)),
        specialization: obj.specialization,
        id: obj.id
    }
}
import React, { useState, useEffect } from "react";
import styles from "./header.module.scss";
import Navbar from "../Navbar";
import logo from "../../../assets/images/magisoftHeaderLogo.png";
import menu from "../../../assets/images/mobileMenu.svg";
import cross from "../../../assets/images/cross.svg";

import Accordion from "../Accordion";

import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation("translations");

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const isHome = currentPathname === "/" || currentPathname === "home";

    if (!isHome) {
      document.querySelector("header").classList.add(`${styles.headerVisible}`);
    }
  }, []);

  const About = {
    links: [
      {
        title: t("company_story_nav"),
        path: "/company-story",
      },
      {
        title: t("our_team_nav"),
        path: "/our-team",
      },
      {
        title: t("tech_stack_nav"),
        path: "/technology-stack",
      },
    ],
  };

  const Howdowework = {
    links: [
      {
        title: t("outstaff_nav"),
        path: "/outstaff",
      },
      {
        title: t("outsource_nav"),
        path: "/outsource",
      },
      {
        title: t("legal_basis_nav"),
        path: "/legal-basis",
      },
    ],
  };

  const Career = {
    links: [
      {
        title: t("front_dev_nav"),
        path: "/career-front-end",
      },
      {
        title: t("back_dev_nav"),
        path: "/career-back-end",
      },
      {
        title: t("trainee_nav"),
        path: "/trainee",
      },
      {
        title: t("school_nav"),
        path: "/school",
      },
    ],
  };
  const noContent = {
    links: [],
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const [activeLangEN, setActiveLangEN] = useState(true);

  const openMenu = () => {
    setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const setLang = (lang) =>
    i18n.changeLanguage(lang).then((res) => console.log(i18n.language));

  return (
    <header className={styles.headerRoot}>
      <Link to="/">
        <img className={`${styles.img} `} src={logo} alt="logo" />
      </Link>
      <img
        className={`${styles.img} ${styles.imgMobile}  ${styles.menuMobile} `}
        src={menu}
        alt="logo"
        onClick={openMenu}
      />

      {/* Mobile */}
      {menuOpen ? (
        <div className={styles.menu}>
          <div onClick={closeMenu}>
            <img src={cross} alt="" />
          </div>
          <Accordion
            onClick={closeMenu}
            title={t("about_us_nav")}
            content={About}
          />
          <Accordion
            onClick={closeMenu}
            title={t("how_do_we_work_nav")}
            content={Howdowework}
          />
          <Link onClick={closeMenu} to="/portfolio" className={styles.noArrow}>
            <Accordion title={t("portfolio_nav")} content={noContent} />
          </Link>
          <Accordion
            onClick={closeMenu}
            title={t("career_nav")}
            content={Career}
          />
          <Link onClick={closeMenu} to="/contact-us" className={styles.noArrow}>
            <Accordion title={t("contact_us_nav")} content={noContent} />
          </Link>
          <div className={styles.lang}>
            <div onClick={closeMenu}>
              <div
                onClick={() => {
                  setLang("en");
                  setActiveLangEN(true);
                }}
              >
                <button
                  style={{ color: activeLangEN ? "#FFFFFF" : "#a7a7a7" }}
                  className={styles.mobileTranslate}
                >
                  EN
                </button>
              </div>
              <div
                onClick={() => {
                  setLang("ua");
                  setActiveLangEN(false);
                }}
              >
                <button
                  style={{ color: activeLangEN ? "#a7a7a7" : "#FFFFFF" }}
                  className={styles.mobileTranslate}
                >
                  UA
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* Mobile */}
      <Navbar />
      <div className={styles.translation}>
        <div
          onClick={() => {
            setLang("en");
            setActiveLangEN(true);
          }}
        >
          <button
            style={{
              color: activeLangEN ? "#FFFFFF" : "#a7a7a7",
            }}
          >
            EN
          </button>
        </div>
        <div
          onClick={() => {
            setLang("ua");
            setActiveLangEN(false);
          }}
        >
          <button style={{ color: activeLangEN ? "#a7a7a7" : "#FFFFFF" }}>
            UA
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./accordion.module.scss";
import { Link } from "react-router-dom";

const Accordion = props => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState(`${styles.accordion__icon}`);

  const content = useRef(null);

  function toggleAccordion() {
    //@REFACTOR THIS
    //use cn
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active"
        ? `${styles.accordion__icon}`
        : `${styles.accordion__icon} ${styles.rotate}`
    );
  }
  return (
    <div className={styles.root}>
      <button
        className={`${styles.accordion} ${setActive}`}
        onClick={toggleAccordion}
      >
        <p>{props.title}</p>
        <span className={`${setRotate} ${styles.menuItem}`}></span>
      </button>

      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={styles.accordionContent}
        onClick={props.onClick}
      >
        <ul>
          {props.content.links.map((item, key) => {
            return (
              <li key={key}>
                {item.icon ? <img src={item.icon} alt="" /> : null}
                <Link to={`${item.path}`}>{item.title}</Link>
                {item.hasOwnProperty("p") ? <p>{item.p}</p> : null}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

Accordion.defaultProps = {};

Accordion.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  content: PropTypes.objectOf(PropTypes.array)
};

export default Accordion;


import anime from "animejs/lib/anime.es.js";
import styles from './company-story.module.scss';

export function animate() {
    window.setTimeout(() => {
        const companyStoryRoot = document.querySelector(`.${styles.root}`);
        if(companyStoryRoot){
            companyStoryRoot.classList.add(styles.animate);
        }
    }, 0);

    

    anime({
        targets: `.${styles.content} p`,
        translateX: ['-100vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
        delay: (el, i) => 2100 - 30 * i,
    });
}
import anime from "animejs/lib/anime.es.js";

export function animate() {
    anime({
        targets: "#contact-us-form",
        width: [700, 480],
        duration: 2000,
        easing: 'easeOutExpo'
    });
    anime({
        targets: "#form-container",
        translateX: [1000, 0],
        duration: 2000,
        easing: 'easeOutExpo'
    });
    anime({
        targets: "#form-submit-btn",
        translateY: [500, 0],
        duration: 2000,
        easing: 'easeOutExpo'
    });
    anime({
        targets: "#form-title",
        translateY: [125, 0],
        width: [700, 720],
        fontSize: [36, 26],
        duration: 2000,
        easing: 'easeOutExpo'
    });
}

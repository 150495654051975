import styles from './main-logo.module.scss'

export const animate = () => {
	if (window.matchMedia('(min-width: 768px)').matches) {
		const logo = document.querySelector(`.${styles.mainLogoLetterGroup}`)

		window.setTimeout(() => {
			if (logo) {
				logo.classList.add(styles.logoAnim)
			}
		}, 2000)

		window.setTimeout(() => {
			if (
				navigator.userAgent.indexOf('Safari') != -1 &&
				navigator.userAgent.indexOf('Chrome') == -1
			) {
				return
			}
			if (logo) {
				logo.setAttribute('filter', 'url(#filter0_d)')
			}
		}, 1300)

		const pathArr = Array.from(
			document.querySelectorAll(`.${styles.mainLogoLetterGroup} >path`)
		)

		pathArr.forEach((p, i) => {
			p.classList.add('change')
		})

		window.setTimeout(() => {
			pathArr.forEach((p, i) => {
				if (p) {
					p.classList.add('origin')
				}
			})
		}, 200)
	}
}

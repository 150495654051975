import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./techstack.module.scss";

import Slider from "react-slick";

import "../../components/SlickSlider/slick-slider.scss";
import ArrowsTech from "../../../assets/images/techarrows.svg";
import { AboutUsTechStackLogo } from "../../components";

import { animate as animateLetter } from "../../components/AboutUsTechStackLogo/anime";
import { animate as animateOther } from "./anime";

import Slide from "./components/Slide";

const TechnologyStack = props => {
  const { techStack, getTechStack } = props;
  const settings = {
    className: "",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    adaptiveHeight: true,
    customPaging: function(i) {
      return <span className="slider-bullet" />;
    }
  };

  useEffect(() => {
    getTechStack().then(() => {
      if (window.matchMedia("(min-width: 769px)").matches) {
        animateLetter();
        animateOther();
      }
    });
  }, [getTechStack]);

  return (
    <div className={styles.root}>
      <img className={styles.leftArrows} src={ArrowsTech} alt="" />
      <div className={`${styles.backGroundRectangleLeft}`} />
      <div className={styles.slider}>
        <Slider {...settings}>
          {techStack.map(item => {
            return <Slide slideItem={item} key={item.id} />;
          })}
        </Slider>
      </div>
      <div className={styles.techStackLogo}>
        <AboutUsTechStackLogo />
      </div>
    </div>
  );
};

TechnologyStack.defaultProps = {};

TechnologyStack.propTypes = {
  techStack: PropTypes.array,
  getTechStack: PropTypes.func
};

export default TechnologyStack;

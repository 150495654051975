import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./contact-form.module.scss";
import { sendUserMessage } from "../../../stores/contactForm/actions";
import { useTranslation } from "react-i18next";
import ClientButton from "../ClientButton/ClientButton";

const initialState = {
  fullname: "",
  email: "",
  message: ""
};

const ContactForm = props => {
  const [{ fullname, email, message }, setState] = useState(initialState);
  const { t } = useTranslation("translations");

  const clearState = () => {
    setState({ ...initialState });
  };

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = { fullname, email, message };
    props.sendUserMessage(data).then(res => {
      const modal = document.getElementById("modal");
      if (res) {
        modal.style.display = "flex";
        setTimeout(() => {
          if (modal) {
            modal.style.display = "none";
          }
        }, 2000);
      }
      clearState();
    });
  };

  return (
    <form
      id="contact-us-form"
      className={styles.rootContainer}
      onSubmit={handleSubmit}
    >
      <h2 id="form-title" className={styles.formTitle}>
        {t("contact_us_form_title_text")}
      </h2>
      <div id="form-container" className={styles.formContainer}>
        <div id="modal" className={styles.modalRes}>
          <p>Your message has been sent !</p>
        </div>

        <div className={styles.formContent}>
          <input
            placeholder={t("contact_us_form_full_name_ph_text")}
            className={styles.input}
            type="text"
            value={fullname}
            name="fullname"
            onChange={onChange}
          />
          <input
            placeholder={t("contact_us_form_email_ph_text")}
            className={styles.input}
            type="email"
            value={email}
            name="email"
            onChange={onChange}
          />
          <textarea
            placeholder={t("contact_us_form_message_ph_text")}
            className={`${styles.input} ${styles.inputLast}`}
            type="text"
            value={message}
            name="message"
            onChange={onChange}
          />
        </div>
      </div>
      <ClientButton
        button
        id={"form-submit-btn"}
        type={"submit"}
        className={styles.formSubmitBtn}
        text={"contact_us_form_submit_btn_text"}
        position={"right"}
      />
    </form>
  );
};

ContactForm.defaultProps = {};

ContactForm.propTypes = {
  sendUserMessage: PropTypes.func,
  data: PropTypes.object
};

const mapStateToProps = state => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendUserMessage: bindActionCreators(sendUserMessage, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);

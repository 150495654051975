import React, { useRef } from "react";
import ContactForm from "./ContactForm";

const ContactFormWithAnime = () => {
  const ref = useRef(null);

  return (
    <div ref={ref}>
      <ContactForm />
    </div>
  );
};

export default ContactFormWithAnime;

import anime from "animejs/lib/anime.es.js";
import styles from './how-we-work-legal-basis-logo.module.scss';

export function animate() {
    
    /**
     * Letter animation
     */

    anime({
      targets: `.${styles.letterTop}`,
      translateY: ['-100vh', 0],
      easing: "easeOutExpo",
      duration: 3400,
    });

    anime({
      targets: `.${styles.letterBottom}`,
      translateY: ['-100vh', 0],
      translateX: ['50vh', 0],
      easing: "easeOutExpo",
      duration: 3400,
    });

    anime({
      targets: `.${styles.title}`,
      translateX: ['100vw', 0],
      easing: "easeOutExpo",
      duration: 3600,
    });

}
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./admin-portfolio.module.scss";

import AdminUpload from "../../components/AdminUpload";
import Button from "../../components/AdminButton";
import LgTextarea from "../../components/AdminTextareas/LgTextarea";
import AdminTitleEditDelete from "../../components/AdminTitleEditDelete";

const EditForm = props => {
  const { onSubmit, onCancel, initialState } = props;

  const [formData, setFormData] = useState(initialState);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);

  useEffect(() => {
    const hasDataChanged =
      JSON.stringify(initialState) !== JSON.stringify(formData);
    setHasUnsavedData(hasDataChanged);
  }, [initialState, formData]);

  const setFormDataField = field => {
    return e => {
      let newStateObj = { ...formData };

      if (e.target.files && e.target.files[0]) {
        newStateObj[field] = e.target.files[0];
        newStateObj[field + "Name"] = e.target.value;
      } else {
        newStateObj[field] = e.target.value;
      }

      setFormData(newStateObj);

      const hasDataChanged =
        JSON.stringify(initialState) !== JSON.stringify(newStateObj);
      setHasUnsavedData(hasDataChanged);
    };
  };
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(formData);
      }}
    >
      {formData && (
        <>
          <div className={styles}>
            <div className={styles.uploads}>
              <AdminUpload
                name={formData.logoName || "Logo image (recommended 120x120px)"}
                onChange={setFormDataField("logo")}
              />
              <AdminUpload
                name={
                  formData.slideName || "Slide image (recommended 500x500px)"
                }
                onChange={setFormDataField("slide")}
              />
            </div>

            <div className={styles.flex}>
              <div className={styles.leaderItem}>
                <AdminTitleEditDelete
                  title={formData.title || "Title not set"}
                />

                <h3 className={styles.subTitle}>Title</h3>
                <LgTextarea
                  value={formData.title}
                  onChange={setFormDataField("title")}
                />
              </div>
              <div className={styles.leaderItem}>
                <AdminTitleEditDelete
                  title={formData.title || "Title not set"}
                />
                <h3 className={styles.subTitle}>Заголовок</h3>
                <LgTextarea
                  value={formData.title}
                  onChange={setFormDataField("title")}
                />
              </div>
            </div>

            <div className={styles.flex}>
              <div className={styles.leaderItem}>
                <h3 className={styles.subTitle}>Description</h3>
                <LgTextarea
                  value={formData.descEn}
                  onChange={setFormDataField("descEn")}
                />
              </div>
              <div className={styles.leaderItem}>
                <h3 className={styles.subTitle}>Описання</h3>
                <LgTextarea
                  value={formData.descUa}
                  onChange={setFormDataField("descUa")}
                />
              </div>
            </div>
          </div>
          <div className={styles.actionBlockBottom}>
            <Button
              view="primary"
              type="submit"
              disabled={!hasUnsavedData}
            >
              {" "}
              Save{" "}
            </Button>
            <Button
              view="danger"
              type="button"
              onClick={() => {
                if (hasUnsavedData) {
                  if (
                    window.confirm(
                      "Ви впевнені, що хочете скасувати внесені зміни?"
                    )
                  ) {
                    onCancel();
                  }
                } else {
                  onCancel();
                }
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

EditForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initialState: PropTypes.object
};

export default EditForm;

import React from "react";
import styles from "./do-best-title.module.scss";
import { useTranslation } from "react-i18next";

const DoBestTitle = () => {
  const { t } = useTranslation("translations");
  return (
    <div id="do-best-container" className={styles.root}>
      <h2 id="do-best-title" className={styles.doBestTitle}>
        {t("what_we_do_best_section_name_text")}
      </h2>
      <svg
        id="do-best-letter-anim"
        className={styles.doBestLetterAnim}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="do-best-part-1">
          <path
            d="M131.502 176.387L187.939 176.387L159.72 225.769L131.502 176.387Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M124.989 176.387L68.552 176.387L96.7705 225.769L124.989 176.387Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M194.451 176.387L250.888 176.387L222.67 225.769L194.451 176.387Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M100.21 230.016L156.647 230.016L128.429 180.634L100.21 230.016Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M313.838 176.387L257.401 176.387L285.62 225.769L313.838 176.387Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M282.547 230.016L226.11 230.016L254.328 180.634L282.547 230.016Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
        <g id="do-best-part-2">
          <path
            d="M313.838 170.597L257.401 170.597L285.62 121.215L313.838 170.597Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          {/* <path d="M380.675 50.3901L324.238 50.3901L352.456 1.00783L380.675 50.3901Z" fill="#0C0B0B" stroke="#555555" /> */}
          <path
            d="M124.599 170.597L68.1623 170.597L96.3808 121.215L124.599 170.597Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M187.939 170.597L131.502 170.597L159.72 121.215L187.939 170.597Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M346.339 116.96L289.902 116.96L318.121 166.343L346.339 116.96Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M35.49 110.978L91.9269 110.978L63.7084 61.5956L35.49 110.978Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M219.67 116.96L163.233 116.96L191.451 166.343L219.67 116.96Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M35.49 116.96L91.9269 116.96L63.7084 166.343L35.49 116.96Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          {/* <path d="M1.36159 50.3901L57.7985 50.3901L29.5801 1.00783L1.36159 50.3901Z" fill="#0C0B0B" stroke="#555555" /> */}
          <path
            d="M290.922 110.978L347.359 110.978L319.14 61.5956L290.922 110.978Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M2.559 56.6063L58.9959 56.6063L30.7775 105.989L2.559 56.6063Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M251.278 170.597L194.841 170.597L223.059 121.215L251.278 170.597Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M379.754 56.6063L323.317 56.6063L351.536 105.989L379.754 56.6063Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
      </svg>
    </div>
  );
};

export default DoBestTitle;

import React, { useRef } from "react";
import styles from "./doBest.module.scss";

// import images
import doBestLogo1 from "../../../../../assets/images/doLogo1.svg";
import doBestLogo2 from "../../../../../assets/images/doLogo2.svg";
import doBestLogo3 from "../../../../../assets/images/doLogo3.svg";
import doBestLogo4 from "../../../../../assets/images/doLogo4.svg";
import doBestLogo5 from "../../../../../assets/images/doLogo5.svg";
import doBestLogo6 from "../../../../../assets/images/doLogo6.svg";
import doBestLogo7 from "../../../../../assets/images/doLogo7.svg";
import leftImg from "../../../../../assets/images/doBestSectionLeftImg.svg";
import letterW from "../../../../../assets/images/w.png";
import ClientButton from "../../../../components/ClientButton/ClientButton";

import { DoBestTitleWithAnime } from "./../../../../components";

import { animate as animateBlock } from "./anime";
import { animate as animateLetter } from "../../../../components/DoBestTitle/anime";

import { useVisibility } from "./../../../../../utils/hooks";
import { useTranslation } from "react-i18next";

const DoBest = () => {
  const ref = useRef(null);
  const { t } = useTranslation("translations");

  useVisibility(
    ref,
    () => {
      if (window.matchMedia("(min-width: 769px)").matches) {
        animateBlock();
        animateLetter();
      }
    },
    {
      intersectionRatio: 0.5
    }
  );
  return (
    <section ref={ref} id="what-we-do-best" className={styles.root}>
      <img className={styles.leftImg} src={leftImg} alt="" />
      <div className={styles.inner}>
        <DoBestTitleWithAnime />
        <img src={letterW} className={styles.letterW} alt="" />
        <div className={styles.capabilities}>
          <span className={styles.capabilitiesBack} />
          <span className={styles.capabilitiesBackTrap} />
          <h2 className={styles.capabilitiesTitle}>
            {t("what_we_do_best_capabilities_title_text")}
          </h2>
          <div id="" className={styles.capabilitiesText}>
            {t("what_we_do_best_capabilities_paragraph_text")}
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.iconCards}>
            <div className={styles.iconCard}>
              <img src={doBestLogo1} alt="do best img" />
              <h3 className={styles.cardTitle}>
                {t("what_we_do_best_block_1_text")}
              </h3>
            </div>
            <div className={styles.iconCard}>
              <img src={doBestLogo2} alt="do best img" />
              <h3 className={styles.cardTitle}>
                {t("what_we_do_best_block_2_text")}
              </h3>
            </div>
            <div className={styles.iconCard}>
              <img src={doBestLogo3} alt="do best img" />
              <h3 className={styles.cardTitle}>
                {t("what_we_do_best_block_3_text")}
              </h3>
            </div>
            <div className={styles.iconCard}>
              <img src={doBestLogo4} alt="do best img" />
              <h3 className={styles.cardTitle}>
                {t("what_we_do_best_block_4_text")}
              </h3>
            </div>
            <div className={styles.iconCard}>
              <img src={doBestLogo5} alt="do best img" />
              <h3 className={styles.cardTitle}>
                {t("what_we_do_best_block_5_text")}
              </h3>
            </div>
            <div className={styles.iconCard}>
              <img src={doBestLogo6} alt="do best img" />
              <h3 className={styles.cardTitle}>
                {t("what_we_do_best_block_6_text")}
              </h3>
            </div>
            <div className={styles.iconCard}>
              <img src={doBestLogo7} alt="do best img" />
              <h3 className={styles.cardTitle}>
                {t("what_we_do_best_block_7_text")}
              </h3>
            </div>
          </div>
        </div>
        <ClientButton
          link
          to={"/contact-us"}
          className={styles.button}
          text={"what_we_do_best_action_btn_text"}
          position="center"
        />
      </div>
    </section>
  );
};

export default DoBest;

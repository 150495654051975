import anime from "animejs/lib/anime.es.js";
import styles from './logo-section.module.scss';

export function animate(delay) {
  
  setTimeout(() => {
    /**
   * ANIMATE "MAGISOFT"
   */
    const textWrapper = document.querySelector(`.${styles.magisoft}`);
    if(textWrapper){
      textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    }
  
    anime({
      targets: `.${styles.magisoft}`,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 100,
    });
  
    anime({
      targets: `.${styles.magisoft} .letter`,
      translateX: [2000, 10],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 100 * i
    });
  
    /**
     * ANIMATE "solutions"
     */
    anime({
      targets: `.${styles.solutions}`,
      letterSpacing: ['-1rem', 0],
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 3400,
      delay: 800
    });
  
    /**
     * ANIMATE DESC AND BUTTON
     */
    anime({
      targets: `.${styles.descr}`,
      translateY: ['60vw', 0],
      easing: "easeOutExpo",
      duration: 2400,
      delay:  1000
    });
  
    anime({
      targets: `.${styles.button}`,
      translateY: ['60vw', 0],
      easing: "easeOutExpo",
      duration: 2400,
      delay: 1000
    });
  }, delay); 
 

}
import constants from "./constants";

export default function jobApplicationsReducer(state = {}, action) {
  switch (action.type) {
    case constants.JOB_APPLICATION_CREATE_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}

import React from "react";
import styles from "../AdminAboutUs/admin-about-us.module.scss";
import "react-tabs/style/react-tabs.css";
import { Tabs } from "react-tabs";
import AdminContentTitle from "../../components/AdminContentTitle";
import AdminTitleEditDelete from "../../components/AdminTitleEditDelete";
import i18next from "i18next";
import LgTextarea from "../../components/AdminTextareas/LgTextarea";
import { useState } from "react";
import Button from "../../components/AdminButton";
import { updateTranslations } from "../../../stores/translation/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AdminTrainee = (props) => {
  const traineeInfoKey = "trainee_text";

  const { updateTranslations } = props;

  const uaT = i18next.getFixedT("ua", "translations");
  const enT = i18next.getFixedT("en", "translations");

  const [traineeInfo, setTraineeInfo] = useState({
    key: traineeInfoKey,
    ua: uaT(traineeInfoKey),
    en: enT(traineeInfoKey),
  });

  const setTraineeInfoField = (field) => (e) =>
    setTraineeInfo({ ...traineeInfo, [field]: e.target.value });

  const onUpdate = () => {
    updateTranslations([traineeInfo]);
  };
  return (
    <div className={styles.root}>
      <AdminContentTitle title="Trainee camp info" />
      <Tabs>
        <div>
          {" "}
          <>
            <div className={styles.flexContent}>
              <div className={styles.typeContent}>
                <AdminTitleEditDelete title="Trainee info en" />
                <LgTextarea
                  value={traineeInfo.en}
                  onChange={setTraineeInfoField("en")}
                />
              </div>
              <div className={styles.typeContent}>
                <AdminTitleEditDelete title="Trainee info ua" />
                <LgTextarea
                  value={traineeInfo.ua}
                  onChange={setTraineeInfoField("ua")}
                />
              </div>
            </div>

            <Button onClick={() => onUpdate()} view="primary">
              Update
            </Button>
          </>
        </div>{" "}
      </Tabs>{" "}
    </div>
  );
};

AdminTrainee.defaultProps = {};

AdminTrainee.propTypes = {
  updateTranslations: PropTypes.func,
};

const mapDispatchToProps = {
  updateTranslations,
};

export default connect(null, mapDispatchToProps)(AdminTrainee);

import axios from "axios";
import { toastr } from "react-redux-toastr";
import constants from "./constants";
import { PROD_BASE_URL } from "../../enviroments";

export const getOffers = () => {
  return async (dispatch) => {
    dispatch(request());
    return await axios
      .get(`${PROD_BASE_URL}/clientContacts`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: constants.OFFERS_GET_REQUEST };
  }
  function success(data) {
    return { type: constants.OFFERS_GET_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.OFFERS_GET_FAILURE, error };
  }
};

/**
 * DELETE OFFER
 */

export const deleteOffer = (id) => {
  return async (dispatch) => {
    dispatch(request(id));
    return await axios
      .delete(`${PROD_BASE_URL}/clientContacts/${id}`)
      .then((response) => {
        dispatch(getOffers()).then(() => {
          dispatch(success(id));
        });
        console.log(response);
        toastr.success("Success", "Deleted");
        return response;
      })
      .catch((error) => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !== "") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request(id) {
    return { type: constants.OFFER_DELETE_REQUEST, payload: id };
  }
  function success(id) {
    return { type: constants.OFFER_DELETE_SUCCESS, payload: { id } };
  }
  function failure(error) {
    return { type: constants.OFFER_DELETE_FAILURE, error };
  }
};

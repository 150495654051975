import constants from "./constants";

export default function contactUsInfoReducer(
  state = {
    list: [],
    errors: []
  },
  action
) {
  switch (action.type) {
    case constants.CONTACT_US_INFO_GET_SUCCESS:
      return { ...state, list: [...action.payload] };
    case constants.CONTACT_US_INFO_UPDATE_ITEM_SUCCESS:
      const idx = state.list.findIndex(item => item.id === action.payload.id);
      const newList = [...state.list];
      newList[idx] = action.payload;
      return { ...state, list: newList };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import styles from './card.module.scss';

const Card = ({ cardLogo, cardContent, tilt }) => {

  return (
    <div className={styles.root}>
      <img src={cardLogo} alt="card logo" className={styles.cardLogo}/>
      <div className={`${styles.cardText} ${tilt === 0 ? styles.gradLeftToRight : styles.gradRightToLeft }`}>
        {cardContent}
      </div>
    </div>
  );
};

Card.defaultProps = {

};

Card.propTypes = {
  cardLogo: PropTypes.string,
  cardContent: PropTypes.array,
  tilt: PropTypes.number,
};

export default Card;
import React from "react";
import styles from "./home.module.scss";
import ContactUsSection from "./components/ContactUsSection";
import AboutUsSection from "./components/AboutUsSection";
import Benefits from "./components/Benefits";
import DoBest from "./components/DoBest";
import LogoSection from "./components/LogoSection";

const Home = () => {
  return (
    <main className={styles.root}>
      <LogoSection />
      <AboutUsSection />
      <DoBest />
      <Benefits />
      <ContactUsSection />
    </main>
  );
};

export default Home;

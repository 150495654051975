
import anime from "animejs/lib/anime.es.js";
import styles from './contact-us.module.scss';

export function animate() {
  
    anime({
        targets: `.${styles.logoBlock}`,
        translateX: ['-100vw', 0],
        skewX: ['-10deg', '-10deg'],
        easing: "easeOutExpo",
        duration: 3000,
    });

    anime({
        targets: `.${styles.contactForm}`,
        translateX: ['100vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
    });

    anime({
        targets: `.${styles.rightArr}, .${styles.leftArr}`,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1000,
        delay: 2000,
    });
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import configureStore from './stores';
import i18n from './i18n';

ReactDOM.render(
	<Provider store={configureStore()}>
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root'));

serviceWorker.unregister();

import React from "react";
import styles from "./client-button.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

const ClientButton = props => {
  const { t } = useTranslation("translations");
  const { position } = props;

  const positionClass = cn(styles.root, {
    [styles.right]: position === "right",
    [styles.center]: position === "center"
  });

  return (
    <>
      {props.link ? (
        <Link
          to={props.to}
          className={`${props.className} ${positionClass} ${styles.btn}`}
        >
          {t(props.text)}
        </Link>
      ) : props.button ? (
        <button
          id={props.id}
          className={`${props.className} ${positionClass} ${styles.btn}`}
          type={props.type}
        >
          {t(props.text)}
        </button>
      ) : null}
    </>
  );
};

export default ClientButton;

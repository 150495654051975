import React, { useState, useEffect } from 'react';
import styles from './admin-career.module.scss';
import PropTypes from 'prop-types';

import Button from '../../components/AdminButton';
import LgTextarea from '../../components/AdminTextareas/LgTextarea';
import AdminTitleEditDelete from '../../components/AdminTitleEditDelete';

const EditForm = (props) => {
    const { onSubmit, onCancel, initialState } = props;

    const [formData, setFormData] = useState(initialState);
    const [hasUnsavedData, setHasUnsavedData] = useState(false);

    useEffect(() => {
        setFormData(initialState);
    }, [initialState]);
    useEffect(() => {
        const hasDataChanged =
            JSON.stringify(initialState) !== JSON.stringify(formData);
        setHasUnsavedData(hasDataChanged);
    }, [formData, initialState]);

    const setFormDataField = (field) => {
        return (e) => {
            const newStateObj = {
                ...formData,
                [field]:
                    e.target.files && e.target.files[0]
                        ? e.target.files[0]
                        : e.target.value,
            };
            setFormData(newStateObj);

            const hasDataChanged =
                JSON.stringify(initialState) !== JSON.stringify(newStateObj);
            setHasUnsavedData(hasDataChanged);
        };
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(formData);
            }}
        >
            {formData && (
                <>
                    <div className={styles.flex}>
                        <div className={styles.leaderItem}>
                            <AdminTitleEditDelete title={formData.name} />
                            <LgTextarea
                                title="Job title:"
                                value={formData.name}
                                onChange={setFormDataField('name')}
                            />
                            <LgTextarea
                                value={formData.requirementsEn}
                                onChange={setFormDataField('requirementsEn')}
                                title="Requirements"
                            />
                            <LgTextarea
                                value={formData.willBePlusEn}
                                onChange={setFormDataField('willBePlusEn')}
                                title="Will be plus:"
                            />
                            <LgTextarea
                                value={formData.responsibilitiesEn}
                                onChange={setFormDataField(
                                    'responsibilitiesEn'
                                )}
                                title="Responsibilities:"
                            />
                            <LgTextarea
                                value={formData.conditionsEn}
                                onChange={setFormDataField('conditionsEn')}
                                title="Working condition:"
                            />
                        </div>
                        <div className={styles.leaderItem}>
                            <AdminTitleEditDelete title={formData.name} />
                            <LgTextarea
                                title="Job title:"
                                value={formData.name}
                                onChange={setFormDataField('name')}
                            />
                            <LgTextarea
                                value={formData.requirementsUa}
                                onChange={setFormDataField('requirementsUa')}
                                title="Requirements"
                            />
                            <LgTextarea
                                value={formData.willBePlusUa}
                                onChange={setFormDataField('willBePlusUa')}
                                title="Will be plus:"
                            />
                            <LgTextarea
                                value={formData.responsibilitiesUa}
                                onChange={setFormDataField(
                                    'responsibilitiesUa'
                                )}
                                title="Responsibilities:"
                            />
                            <LgTextarea
                                value={formData.conditionsUa}
                                onChange={setFormDataField('conditionsUa')}
                                title="Working condition:"
                            />
                        </div>
                    </div>
                    <div className={styles.actionBlockBottom}>
                        <Button
                            view="primary"
                            type="submit"
                            disabled={!hasUnsavedData}
                        >
                            {' '}
                            Save{' '}
                        </Button>
                        <Button
                            view="danger"
                            type="button"
                            onClick={() => {
                                if (hasUnsavedData) {
                                    if (
                                        window.confirm(
                                            'Ви впевнені, що хочете скасувати внесені зміни?'
                                        )
                                    ) {
                                        onCancel();
                                    }
                                } else {
                                    onCancel();
                                }
                            }}
                        >
                            {' '}
                            Cancel{' '}
                        </Button>
                    </div>
                </>
            )}
        </form>
    );
};

EditForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    initialState: PropTypes.object,
};

export default EditForm;

import anime from "animejs/lib/anime.es.js";
import styles from './techstack.module.scss';

export function animate() {

    anime({
        targets: `.${styles.slider}`,
        translateX: ['-50vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
    });

    anime({
        targets: `.${styles.backGroundRectangleLeft}`,
        translateX: [0, '50vw'],
        easing: "easeOutExpo",
        duration: 3000,
    });
}
import React from "react";
import PropTypes from "prop-types";
import styles from "./admin-title-edit-delete.module.scss";

const AdminTitleEditDelete = props => {
  return (
    <div className={styles.root}>
      <h4>{props.title}</h4>
    </div>
  );
};

AdminTitleEditDelete.defaultProps = {};

AdminTitleEditDelete.propTypes = {
  title: PropTypes.string,
};

export default AdminTitleEditDelete;

import SEND_USER_MESSAGE from "./actions";

export default function contactReducer(state = {}, action) {
  switch (action.type) {
    case SEND_USER_MESSAGE:
      return { ...action.payload};
    default:
      return state;
  }
}

import anime from "animejs/lib/anime.es.js";

export const animate = () => {

  anime({
    targets: "#benefitsLetterAnim",
    opacity: [0, 1],
    duration: 3000,
    easing: "easeOutExpo"
  });
  anime({
    targets: "#benefits-title-1",
    translateX: [-2000, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-title-2",
    translateX: [2000, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });


  anime({
    targets: "#benefits-part-1",
    translateX: [500, 0],
    translateY: [500, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-2",
    translateX: [500, 0],
    translateY: [500, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-3",
    translateX: [800, 0],
    translateY: [800, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-4",
    translateX: [800, 0],
    translateY: [800, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });


  anime({
    targets: "#benefits-part-5",
    translateX: [1200, 0],
    translateY: [1200, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-6",
    translateX: [1200, 0],
    translateY: [1200, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-7",
    translateX: [1200, 0],
    translateY: [1200, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });


  anime({
    targets: "#benefits-part-8",
    translateX: [1600, 0],
    translateY: [1600, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-9",
    translateX: [1600, 0],
    translateY: [1600, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });


  anime({
    targets: "#benefits-part-1-b",
    translateX: [-500, 0],
    translateY: [-500, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-2-b",
    translateX: [-500, 0],
    translateY: [-500, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-3-b",
    translateX: [-800, 0],
    translateY: [-800, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-4-b",
    translateX: [-800, 0],
    translateY: [-800, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });


  anime({
    targets: "#benefits-part-5-b",
    translateX: [-1200, 0],
    translateY: [-1200, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-6-b",
    translateX: [-1200, 0],
    translateY: [-1200, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-7-b",
    translateX: [-1200, 0],
    translateY: [-1200, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-8-b",
    translateX: [-1600, 0],
    translateY: [-1600, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });

  anime({
    targets: "#benefits-part-9-b",
    translateX: [-1600, 0],
    translateY: [-1600, 0],
    duration: 3000,
    easing: "easeOutExpo"
  });
}



import React from "react";
import PropTypes from "prop-types";
import styles from "./admin-textareas.module.scss";

const LgTextarea = (props) => {
  const { value, onChange } = props;
  return (
    <div className={styles.root}>
      <textarea
        value={value}
        onChange={onChange}
        className={styles.largeTextarea}
        name=""
        id=""
      />
    </div>
  );
};

LgTextarea.defaultProps = {};

LgTextarea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default LgTextarea;

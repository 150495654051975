import React, { useEffect } from "react";
import styles from "./how-do-we-work.module.scss";
import LeftArr from "../../../assets/images/leftArrowsHowWeWork.svg";
import RightArr from "../../../assets/images/rightArrowsHowWeWork.svg";
import { animate as animateLetter } from "../../components/HowWeWorkLegalBasisLogo/anime";
import { animate as animateOther } from "./anime";
import { useTranslation } from "react-i18next";

const HowDoWeWork = props => {
  useEffect(() => {
    if (window.matchMedia("(min-width: 769px)").matches) {
      animateLetter();
      animateOther();
    }
  }, []);

  const { t } = useTranslation("translations");

  return (
    <div className={styles.root}>
      <img src={LeftArr} className={styles.leftArr} alt="" />
      <img src={RightArr} className={styles.rigthArr} alt="" />
      <div className={`${styles.logoBlock} ${styles.legalLogo}`}>
        {props.logo}
      </div>
      <div className={`${styles.backGroundRectangleLeft}`} />
      <div
        className={`${styles.content} ${styles.outsource} ${styles.outstaff} ${styles.legalBasis}`}
      >
        <h3 className={styles.title}>{t(props.title)}</h3>
        {t(props.text)
          .split("\n")
          .map((p, key) => (
            <p key={key}>{p}</p>
          ))}
      </div>
    </div>
  );
};

export default HowDoWeWork;

import { connect } from 'react-redux';

import { getPortfolio } from '../../../stores/portfolio/actions';
import Portfolio from './Portfolio';



const mapStateToProps = (state) => ({
    slides: state.portfolio.list
})

const mapDispatchToProps = {
    getSlides: getPortfolio
}

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio)

import anime from "animejs/lib/anime.es.js";

export const animate = () => {
        anime({
          targets: "#contact-us-container",
          opacity: [0, 1],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#contact-us-letter-anim *",
          opacity: [0, 1],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
    
        anime({
          targets: "#part4, #part5, #part6, #part7, #part8",
          translateX: [-100, 0],
          translateY: [100, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part2",
          translateY: [-100, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part1",
          translateX: [100, 0],
          translateY: [-100, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part3",
          translateX: [-100, 0],
          translateY: [-100, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part12",
          translateY: [100, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part9",
          translateX: [-100, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part10",
          translateY: [100, 0],
          translateX: [-50, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part14",
          translateY: [100, 0],
          translateX: [50, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part11",
          translateY: [200, 0],
          translateX: [-50, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
        
        anime({
          targets: "#part13",
          translateY: [200, 0],
          translateX: [50, 0],
          duration: 2000,
          easing: "easeOutExpo",
          delay: 1000
        });
}
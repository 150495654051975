import React from "react";
import styles from "./navbar.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation("translations");

  return (
    <nav className={styles.navbarRoot}>
      <ul className={styles.menu}>
        <li>
          <Link className={styles.head} to="/company-story">
            {t("about_us_nav")} <span className={styles.menuItem}></span>
          </Link>
          <ul className={styles.dropdown}>
            <li>
              <Link to="/company-story">{t("company_story_nav")}</Link>
            </li>
            <li>
              <Link to="/our-team">{t("our_team_nav")}</Link>
            </li>
            <li>
              <Link to="/technology-stack">{t("tech_stack_nav")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link className={styles.head} to="#">
            {t("how_do_we_work_nav")}
            <span className={styles.menuItem}></span>
          </Link>
          <ul className={styles.dropdown}>
            <li>
              <Link to="/outstaff">{t("outstaff_nav")}</Link>
            </li>
            <li>
              <Link to="/outsource">{t("outsource_nav")}</Link>
            </li>
            <li>
              <Link to="/legal-basis">{t("legal_basis_nav")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/portfolio">{t("portfolio_nav")}</Link>
        </li>
        <li>
          <Link className={styles.head} to="#">
            {t("career_nav")}
            <span className={styles.menuItem}></span>
          </Link>
          <ul className={styles.dropdown}>
            <li>
              <Link to="/career-front-end">{t("front_dev_nav")}</Link>
            </li>
            <li>
              <Link to="/career-back-end">{t("back_dev_nav")}</Link>
            </li>
            <li>
              <Link to="/trainee">{t("trainee_nav")}</Link>
            </li>
            <li>
              <Link to="/school">{t("school_nav")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/contact-us">{t("contact_us_nav")}</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;

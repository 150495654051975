import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import styles from "./admin-portfolio.module.scss";
import { jsonToFormData } from "../../../utils/index";
import AdminContentTitle from "../../components/AdminContentTitle";
import PropTypes from "prop-types";
import Button from "../../components/AdminButton";
import { transformPortfolioObjToEditorObj } from "./helpers";
import { ModalWindow } from "../../components";

import EditForm from "./EditForm";

import i18next from "i18next";
import Delete from "../../../assets/images/delete.svg";

import cn from "classnames";

const initialSlideData = {
  titleEn: "new slide",
  titleUa: "новий слайд",
  titleRu: "",
  descEn: "description",
  descUa: "опис",
  descRu: "",
  slide: null,
  logo: null
};

const AdminPortfolio = props => {
  const {
    portfolio,
    getPortfolio,
    addPortfolioItem,
    deletePortfolioItem,
    updatePortfolioItem
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const uaT = i18next.getFixedT("ua", "translations");
  const enT = i18next.getFixedT("en", "translations");

  useEffect(() => {
    getPortfolio();
  }, [getPortfolio]);

  const onDelete = id => {
    deletePortfolioItem(id);
  };

  const onUpdate = fromData => {
    updatePortfolioItem(jsonToFormData(fromData), fromData.id);
  };

  const onCreate = formData => {
    addPortfolioItem(jsonToFormData(formData)).then(res => {
      if (res) {
        setIsOpenModal(false);
      }
    });
  };

  const handleTabSelect = tabIndex => {
    setTabIndex(tabIndex);
  };

  return (
    <div className={styles.root}>
      <AdminContentTitle title="Porfolio" />
      <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Slide</th>
              <th>Slide info</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TabList>
                  {portfolio.map((item, index) => {
                    const itemClass = cn(styles.textTab, {
                      [styles.textTabHighlighted]: index === tabIndex
                    });
                    return (
                      <Tab key={index} className={itemClass}>
                        <div className={styles.tab}>
                          <h4 className={styles.textTab}>
                            {enT(item.title) || "Title not set"}
                          </h4>
                          <img
                            onClick={() => onDelete(item.id)}
                            src={Delete}
                            alt=""
                          />
                        </div>
                      </Tab>
                    );
                  })}
                </TabList>
                <Button
                  view="add"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  Add new slide
                </Button>
              </td>
              <td className={styles.fields}>
                {portfolio.map((t, i) => (
                  <TabPanel key={i}>
                    <EditForm
                      initialState={transformPortfolioObjToEditorObj(
                        t,
                        enT,
                        uaT
                      )}
                      onSubmit={val => onUpdate(val)}
                      onCancel={() => {
                        alert("cancel");
                      }}
                    />
                  </TabPanel>
                ))}
                <ModalWindow
                  isOpen={isOpenModal}
                  onClose={() => {
                    setIsOpenModal(false);
                  }}
                  className={styles.modalContent}
                >
                  <EditForm
                    initialState={initialSlideData}
                    onSubmit={val => onCreate(val)}
                    onCancel={() => setIsOpenModal(false)}
                  />
                </ModalWindow>
              </td>
            </tr>
          </tbody>
        </table>
      </Tabs>
    </div>
  );
};

AdminPortfolio.defaultProps = {};

AdminPortfolio.propTypes = {
  portfolio: PropTypes.array,
  getPortfolio: PropTypes.func,
  addPortfolioItem: PropTypes.func,
  deletePortfolioItem: PropTypes.func,
  updatePortfolioItem: PropTypes.func
};

export default AdminPortfolio;

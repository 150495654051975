import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./admin-panel.module.scss";
import Sidebar from "../../components/Sidebar";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import AdminRoutes from "../../../routes/adminRoutes";
import { HashRouter } from "react-router-dom";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import { login, checkToken } from "../../../stores/login/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const AdminPanel = props => {
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [state, setState] = useState({ email: "", password: "" });

  // eslint-disable-next-line
  const [hasAdminPermission, setHasAdminPermission] = useState(false);

  const { login, checkToken } = props;

  useEffect(() => {
    const userToken = localStorage.usertoken;
    if (userToken) {
      checkToken(userToken).then(res => {
        if (res && res.authenticate) {
          setHasAdminPermission(true);
          setIsOpenModal(false);
        }
      });
    }
  }, [checkToken]);

  const onChange = e => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const user = {
      email: state.email,
      password: state.password
    };
    login(user).then(res => {
      if (res) {
        setIsOpenModal(false);
        setHasAdminPermission(true);
      }
    });
  };

  return (
    <>
      {isOpenModal ? (
        <ModalWindow
          isOpen={isOpenModal}
          onClose={() => {}}
          className={styles.modalContent}
          login
        >
          <form
            action="login"
            onSubmit={handleSubmit}
            className={styles.loginForm}
          >
            <input
              type="email"
              placeholder="Email"
              onChange={onChange}
              name="email"
            />
            <input
              type="password"
              placeholder="Password"
              onChange={onChange}
              name="password"
            />
            <button type="submit">Log In</button>
          </form>
        </ModalWindow>
      ) : (
        <>
          <ModalWindow
            isOpen={isOpenModal}
            onClose={() => {}}
            className={styles.modalContent}
            login
          >
            <form
              action="login"
              onSubmit={handleSubmit}
              className={styles.loginForm}
            >
              <input
                type="email"
                placeholder="Email"
                onChange={onChange}
                name="email"
              />
              <input
                type="password"
                placeholder="Password"
                onChange={onChange}
                name="password"
              />
              <button type="submit">Log In</button>
            </form>
          </ModalWindow>
          <HashRouter>
            <div className={styles.root}>
              <AdminHeader />
              <div className={styles.flex}>
                <Sidebar />
                <div className={styles.content}>
                  <AdminRoutes />
                </div>
              </div>
            </div>
          </HashRouter>
        </>
      )}
    </>
  );
};

AdminPanel.defaultProps = {};

AdminPanel.propTypes = {
  login: PropTypes.func,
  checkToken: PropTypes.func
};

const mapStateToProps = state => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: bindActionCreators(login, dispatch),
    checkToken: bindActionCreators(checkToken, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./portfolio.module.scss";
import { PortfolioTitle } from "./../../components";
import Slider from "react-slick";
import "../../components/SlickSlider/slick-slider.scss";
import Slide from "./components/Slide";
import { animate as animateLetter } from "./../../components/PortfolioTitle/anime";
import { animate as animateTrap } from "./anime";
import letterP from '../../../assets/images/p.svg';

const Portfolio = props => {
  const { slides, getSlides } = props;
  const settings = {
    className: "",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    adaptiveHeight: true,
    customPaging: function(i) {
      return <span className="slider-bullet"/>;
    }
  };
  useEffect(() => {
    getSlides();

    if (window.matchMedia("(min-width: 769px)").matches) {
      animateLetter({
        delay: 500,
        duration: 2000,
        easing: "easeOutExpo"
      });
      animateTrap({
        delay: 500,
        duration: 2000,
        easing: "easeOutExpo"
      });
    }
  }, [getSlides]);

  return (
    <div className={styles.root}>
      <span className={styles.trap} />
      <div className={styles.logoBlock}>
        <img className={styles.letterP} src={letterP} alt=""/>
        <PortfolioTitle />
      </div>
      <div className={styles.sliderBlock}>
        <Slider {...settings}>
          {slides.map((s, key) => (
            <Slide key={key} slideItem={s} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

Portfolio.defaultProps = {};

Portfolio.propTypes = {
  slides: PropTypes.array,
  getSlides: PropTypes.func
};

export default Portfolio;

import constants from "./constants";

export default function teamReducer(state = {
    list: [],
    errors: [],
}, action) {
  switch (action.type) {
    case constants.TEAM_GET_SUCCESS:
      return { ...state, list: [...action.payload ]};
    case constants.TEAM_ADD_MEMBER_SUCCESS: 
      return { ...state, list: state.list.concat(action.payload) };
    case constants.TEAM_DELETE_MEMBER_SUCCESS: 
      return { ...state, list: state.list.filter(item => item.id !== action.payload.id) }
    case constants.TEAM_UPDATE_MEMBER_SUCCESS: 
      const idx = state.list.findIndex(item => item.id === action.payload.id);
      const newList = [...state.list];
      newList[idx] = action.payload;
      return { ...state, list: newList }
    default:
      return state;
  }
}

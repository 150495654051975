import anime from "animejs/lib/anime.es.js";
import styles from './how-do-we-work.module.scss';

export function animate() {

    anime({
        targets: `.${styles.title}`,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 5000,
    });

    anime({
        targets: `.${styles.content} p`,
        translateX: ['-50vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
        delay: (el, i) => i * 80
    });

    anime({
        targets: `.${styles.backGroundRectangleLeft}`,
        translateX: ['-50vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
    });

  

 
}
import axios from "axios";
import constants from './constants';
import { getTranslation } from '../translation/actions';
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from '../../enviroments';

export const getVacancies = () => {
  return async dispatch => {
    dispatch(request());
    return await axios
      .get(`${PROD_BASE_URL}/vacancy`)
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() { return { type: constants.VACANCIES_GET_REQUEST } }
  function success(data) { return { type: constants.VACANCIES_GET_SUCCESS, payload: data } }
  function failure(error) { return { type: constants.VACANCIES_GET_FAILURE, error } }
};


export const addVacancy = (data) => {
  return async dispatch => {
    dispatch(request(data));
    return await axios
      .post(`${PROD_BASE_URL}/vacancy`, data)
      .then(response => {
        dispatch(getTranslation())
        .then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Created");

        return response.data;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request(data) { return { type: constants.VACANCIES_ADD_REQUEST, payload: data } }
  function success(data) { return { type: constants.VACANCIES_ADD_SUCCESS, payload: data } }
  function failure(error) { return { type: constants.VACANCIES_ADD_FAILURE, error } }
};


export const updateVacancy = (data, id) => {
  return async dispatch => {
    dispatch(request(data));
    return await axios
      .put(`${PROD_BASE_URL}/vacancy/${id}`, data)
      .then(response => {
        dispatch(getTranslation())
        .then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Updated");

        return response.data;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request(data) { return { type: constants.VACANCIES_UPDATE_REQUEST, payload: data } }
  function success(data) { return { type: constants.VACANCIES_UPDATE_SUCCESS, payload: data } }
  function failure(error) { return { type: constants.VACANCIES_UPDATE_FAILURE, error } }
};

export const deleteVacancy = (id) => {
  return async dispatch => {
    dispatch(request(id));
    return await axios
      .delete(`${PROD_BASE_URL}/vacancy/${id}`)
      .then(response => {
        dispatch(success(id));
        toastr.success("Success", "Deleted");

        return response;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      })
        
  };

  function request(id) { return { type: constants.VACANCIES_DELETE_REQUEST, payload: id } }
  function success(id) { return { type: constants.VACANCIES_DELETE_SUCCESS, payload: { id } } }
  function failure(error) { return { type: constants.VACANCIES_DELETE_FAILURE, error } }
}
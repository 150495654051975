import React, { useRef } from "react";
import styles from "./about-us-section.module.scss";
import {
  AboutUsTitleWithAnime,
  AboutUsBlockWithAnime
} from "../../../../components";
import { useVisibility } from "./../../../../../utils/hooks";

import reghtImg from "../../../../../assets/images/aboutUsSectionRightImg.svg";

import { animate as animateLetter } from "../../../../components/AboutUsTitle/anime";
import { animate as animateBlock } from "../../../../components/AboutUsBlock/anime";
import letterA from "../../../../../assets/images/a.png";

const AboutUsSection = () => {
  const ref = useRef(null);

  useVisibility(
    ref,
    () => {
      if (window.matchMedia("(min-width: 769px)").matches) {
        animateBlock();
        animateLetter();
      }
    },
    {
      intersectionRatio: 0.75
    }
  );
  return (
    <section ref={ref} id='about-us' className={styles.root}>
      <div className={styles.container}>
        <div className={styles.sectionTitle}>
          <AboutUsTitleWithAnime />
          <img src={letterA} className={styles.letterA} alt="" />
        </div>
        <AboutUsBlockWithAnime />
      </div>
      <img className={styles.rightImg} src={reghtImg} alt=""/>
    </section>
  );
};


export default AboutUsSection;

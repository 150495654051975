import React from "react";
import { connect } from "react-redux";

import ClientButton from "../../components/ClientButton/ClientButton";

import styles from "./school-page-form.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import sendSchoolOffer from "../../../stores/contactSchoolForm/actions";

const initialState = {
  fullname: "",
  email: "",
  city: "",
  phone: "",
  message: "",
};

const MagiSchoolPageForm = (props) => {
  const { t } = useTranslation("translations");

  const [{ fullname, email, city, phone, message }, setState] =
    useState(initialState);

  const { sendSchoolOffer } = props;
  const clearState = () => {
    setState({ ...initialState });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { fullname, email, city, phone, message };
    console.log(data);
    sendSchoolOffer(data).then((res) => {
      const modalContact = document.getElementById("modalContact");
      if (res) {
        modalContact.style.display = "flex";
        setTimeout(() => {
          if (modalContact) {
            modalContact.style.display = "none";
          }
        }, 2000);
      }
      clearState();
    });
  };

  return (
    <form
      id="contact-us-form"
      onSubmit={handleSubmit}
      className={styles.rootContainer}
    >
      <h2 id="form-title" className={styles.formTitle}>
        {t("school_form_title")}
      </h2>
      <div id="form-container" className={styles.formContainer}>
        <div className={styles.formContent}>
          <div id="modalContact" className={styles.modalRes}>
            <p>Your message has been sent !</p>
          </div>
          <input
            placeholder="Full Name*"
            className={styles.input}
            type="text"
            name="fullname"
            value={fullname}
            onChange={onChange}
            required
          />
          <input
            placeholder="E-mail*"
            className={styles.input}
            type="text"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
          <input
            placeholder="City"
            name="city"
            value={city}
            className={styles.input}
            type="text"
            onChange={onChange}
          />

          <input
            placeholder="Phone number"
            name="phone"
            value={phone}
            className={styles.input}
            type="number"
            onChange={onChange}
          />

          <textarea
            placeholder="Message*"
            value={message}
            name="message"
            className={`${styles.input} ${styles.inputLast}`}
            type="text"
            onChange={onChange}
          />
        </div>
      </div>
      <ClientButton
        button
        id={"cv-form-submit-btn"}
        type="submit"
        text="Send"
        position="center"
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
  };
};

export default connect(mapStateToProps, { sendSchoolOffer })(
  MagiSchoolPageForm
);

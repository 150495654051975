import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  MainPageTitleSectionKeys,
  MainPageAboutUsSectionKeys,
  MainPageWhatWeDoBestSectionKeys,
  MainPageOurBenefitsKeys,
  MainPageContactUsPageKeys,
  AboutUsPageCompanyStoryKeys,
  HowDoWeWorkOutstaffKeys,
  HowDoWeWorkOutsourceKeys,
  HowDoWeWorkLegalBasisKeys,
  HeaderNavTitlesKeys,
  FooterTitlesKeys,
} from "./setupKeys";

// import pages

import MainAdmin from "../views/pages/MainAdmin";
import TextBlockEdit from "../views/components/TextBlockEdit/TextBlockEdit";

// import AdminCompanyStory from '../views/pages/AdminAboutUs/AdminCompanyStory'
// import AdminLegalBasis from '../views/pages/AdminHowDoWeWork/AdminLegalBasis';

import AdminOurTeam from "../views/pages/AdminAboutUs/AdminOurTeam";
import AdminPortfolio from "../views/pages/AdminPortfolio";
import AdminVacancy from "../views/pages/AdminCareer/AdminVacancy";
import AdminCv from "../views/pages/AdminCareer/AdminCv";
import AdminOffers from "../views/pages/AdminOffers/AdminOffers";
import AdminTechnologyStack from "../views/pages/AdminAboutUs/AdminTechnologyStack";
import AdminContactUs from "../views/pages/AdminContactUs";
import AdminSchool from "../views/pages/AdminCareer/AdminSchool";
import AdminTrainee from "../views/pages/AdminCareer/AdminTrainee";

const AdminRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/admin-panel" component={MainAdmin} />
        <Route
          exact
          path="/company-story"
          render={() => (
            <TextBlockEdit
              blockName="About Us Page Company Story"
              editKeys={AboutUsPageCompanyStoryKeys}
            />
          )}
        />
        <Route exact path="/our-team" component={AdminOurTeam} />
        <Route exact path="/portfolio" component={AdminPortfolio} />
        <Route exact path="/vacancy" component={AdminVacancy} />
        <Route exact path="/cv" component={AdminCv} />
        <Route exact path="/school" component={AdminSchool} />
        <Route exact path="/trainee" component={AdminTrainee} />
        <Route exact path="/incoming-offers" component={AdminOffers} />

        <Route
          exact
          path="/technology-stack"
          component={AdminTechnologyStack}
        />
        <Route exact path="/contact-us-form" component={AdminContactUs} />
        {/* render={() => (
            <TextBlockEdit
              blockName="Contact us page"
              editKeys={ContactUsPageKeys}
            />
          )}  */}

        <Route
          exact
          path="/outstaff"
          render={() => (
            <TextBlockEdit
              blockName="How Do We Work Page, Outstaff"
              editKeys={HowDoWeWorkOutstaffKeys}
            />
          )}
        />
        <Route
          exact
          path="/outsource"
          render={() => (
            <TextBlockEdit
              blockName="How Do We Work Page, Outsource"
              editKeys={HowDoWeWorkOutsourceKeys}
            />
          )}
        />
        <Route
          exact
          path="/legal-basis"
          render={() => (
            <TextBlockEdit
              blockName="How Do We Work Page, Legal Basis"
              editKeys={HowDoWeWorkLegalBasisKeys}
            />
          )}
        />

        <Route
          exact
          path="/title-page"
          render={() => (
            <TextBlockEdit
              blockName="Main Page Title Section Texts"
              editKeys={MainPageTitleSectionKeys}
            />
          )}
        />
        <Route
          exact
          path="/about-us"
          render={() => (
            <TextBlockEdit
              blockName="Main Page About Us Section Texts"
              editKeys={MainPageAboutUsSectionKeys}
            />
          )}
        />
        <Route
          exact
          path="/what-we-do"
          render={() => (
            <TextBlockEdit
              blockName="Main Page What We Do Best Section Texts"
              editKeys={MainPageWhatWeDoBestSectionKeys}
            />
          )}
        />
        <Route
          exact
          path="/our-benefits"
          render={() => (
            <TextBlockEdit
              blockName="Main Page Our Benefits Section Texts"
              editKeys={MainPageOurBenefitsKeys}
            />
          )}
        />
        <Route
          exact
          path="/contact-us"
          render={() => (
            <TextBlockEdit
              blockName="Main Page Contact Us Section Texts"
              editKeys={MainPageContactUsPageKeys}
            />
          )}
        />
        <Route
          exact
          path="/header"
          render={() => (
            <TextBlockEdit
              blockName="Header Navigation Titles"
              editKeys={HeaderNavTitlesKeys}
            />
          )}
        />
        <Route
          exact
          path="/footer"
          render={() => (
            <TextBlockEdit
              blockName="Footer Navigation Titles"
              editKeys={FooterTitlesKeys}
            />
          )}
        />
      </Switch>
    </>
  );
};
export default AdminRoutes;

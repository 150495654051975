import anime from "animejs/lib/anime.es.js";
import styles from './about-us-block.module.scss';

export const animate = () => {
    anime({
        targets: `.${styles.aboutUs}`,
        maxWidth: [700, 536],
        easing: "easeOutExpo",
        duration: 1400,
    });
    anime({
        targets: `.${styles.text}`,
        translateX: [1000, 0],
        easing: "easeOutExpo",
        duration: 1400,
        delay: (el, i) => 600 + 150 * i
    });
    anime({
        targets: `.${styles.btn}`,
        translateY: [1000, 0],
        easing: "easeOutExpo",
        duration: 1400,
        delay: 800,
    });
    anime({
        targets: `.${styles.title}`,
        translateY: [125, 0],
        fontSize: 26,
        lineHeight: 26,
        duration: 3400,
        easing: 'easeOutExpo'
      });
}
import { connect } from 'react-redux';
import { getTeam } from './../../../stores/team/actions';

import OurTeam from './OurTeam';

const mapStateToProps = (state) => ({
    team: state.team.list
})

const mapDispatchToProps = {
    getTeam
}

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam)

import { connect } from 'react-redux';
import { createJobApplication } from '../../../stores/jobApplication/actions';
import { getVacancies } from '../../../stores/vacancies/actions';

import Career from './Career';

const mapStateToProps = (state) => ({
    vacancies: state.vacancies.list,
})

const mapDispatchToProps = {
    createJobApplication,
    getVacancies
}

export default connect(mapStateToProps, mapDispatchToProps)(Career)

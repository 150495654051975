import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Google from "../../../../../assets/images/google.svg";
import Apple from "../../../../../assets/images/apple.svg";

import { useVisibility } from "./../../../../../utils/hooks";
import { withTranslation } from "react-i18next";
import { getContactUsInfo } from "../../../../../stores/contactUsInfo/actions";
import { connect } from "react-redux";

import { animate as animateForm } from "../../../../components/ContactForm/anime";
import { animate as animateLetter } from "../../../../components/ContactUsTitle/anime";

import styles from "./contact-us-section.module.scss";
import {
  ContactFormWithAnime,
  ContactUsTitleWithAnime
} from "../../../../components";
import letterC from "../../../../../assets/images/c.png";

import leftImg from "../../../../../assets/images/contactUsSectionLeftImg.svg";

const ContactUsSection = props => {
  const ref = useRef(null);
  const { getContactUsInfo, contactUsInfo, i18n } = props;

  const [content, setContect] = useState();
  useEffect(() => {
    getContactUsInfo();
  }, [getContactUsInfo]);

  useEffect(() => {
    const info = contactUsInfo
      ? contactUsInfo.find(item => {
          return i18n.language === item.lang;
        })
      : null;
    setContect(info && info.translation);
  }, [contactUsInfo, i18n.language]);

  useVisibility(
    ref,
    () => {
      if (window.matchMedia("(min-width: 769px)").matches) {
        animateForm();
        animateLetter();
      }
    },
    {
      intersectionRatio: 0.5
    }
  );

  return (
    <section ref={ref} id="contact-us" className={styles.root}>
      <div className={styles.mainContent}>
        <div className={styles.title}>
          <ContactUsTitleWithAnime />
          <img src={letterC} className={styles.letterC} alt="" />
        </div>
        <div className={styles.contactFormContainer}>
          <ContactFormWithAnime />
        </div>
      </div>

      <div className={styles.downloads}>
        <h3>{content && content.contact_us_app_title}</h3>
        <div className={styles.downloadsButtons}>
          <a
            target="blank"
            href="https://apps.apple.com/ua/app/appprice/id1486838280"
          >
            <img src={Apple} alt="" />
          </a>
          <a
            target="blank"
            href="https://play.google.com/store/apps/details?id=com.estimatemyapp"
          >
            <img src={Google} alt="" />
          </a>
        </div>
      </div>
      <img src={leftImg} className={styles.leftImg} alt=""/>
    </section>
  );
};

ContactUsSection.defaultProps = {};

ContactUsSection.propTypes = {
  getContactUsInfo: PropTypes.func,
  contactUsInfo: PropTypes.array,
  i18n: PropTypes.object
};

const mapStateToProps = state => ({
  contactUsInfo: state.contactUsInfo.list
});

const mapDispatchToProps = {
  getContactUsInfo
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ContactUsSection)
);

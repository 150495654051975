import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./accordion-c-vand-offers.module.scss";
import Download from "../../../assets/images/download.svg";
import CvArrow from "../../../assets/images/cvArrow.svg";
import Delete from "../../../assets/images/delete.svg";
import { deleteCV } from "../../../stores/cv/actions";
import { deleteOffer } from "../../../stores/incomingOffers/actions";
import { connect } from "react-redux";

const AccordionCVandOffers = (props) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState(`${styles.accordion__icon}`);
  const { deleteCV, deleteOffer } = props;

  const content = useRef(null);

  /**
   * @refactor this component identical to accordion
   */

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active"
        ? `${styles.accordion__icon}`
        : `${styles.accordion__icon} ${styles.rotate}`
    );
  }
  return (
    <div className={styles.root}>
      <div className={styles.cvItem}>
        <div className={`${styles.flex} ${styles.spaceBetween}`}>
          <div className={styles.cvInfo}>
            <div className={styles.flex}>
              <span className={styles.infoTitle}>Name:</span>
              <p className={styles.infoName}>{props.name}</p>
            </div>
            <div className={styles.flex}>
              <span className={styles.infoTitle}>Date:</span>
              <p className={styles.infoName}>{props.date.slice(0, 10)}</p>
            </div>

            <div className={styles.flex}>
              <span className={`${styles.infoTitle} ${styles.start}`}>
                E-mail:
              </span>
              <p className={styles.infoEmail}>{props.email}</p>
            </div>

            {props.skype ? (
              <div className={styles.flex}>
                <span className={`${styles.infoTitle}`}>Skype:</span>
                <p className={styles.infoEmail}>{props.skype}</p>
              </div>
            ) : null}

            {props.company ? (
              <div className={styles.flex}>
                <span className={`${styles.infoTitle}`}>Company name:</span>
                <p className={styles.infoName}>{props.company}</p>
              </div>
            ) : null}

            {props.project ? (
              <div className={styles.flex}>
                <span className={`${styles.infoTitle}`}>Type of Project:</span>
                <p className={styles.infoName}>{props.project}</p>
              </div>
            ) : null}

            {props.cv ? (
              <div className={styles.flex}>
                <span className={`${styles.infoTitle} `}>CV:</span>
                <a
                  href={`https://magisoft.solutions/assets/${props.cv}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={props.cv}
                  className={styles.infoCVfile}
                >
                  {props.cv} <img src={Download} alt="" />
                </a>
              </div>
            ) : null}
          </div>
          <div className={styles.cvIcons}>
            <button
              className={`${styles.accordion} ${setActive}`}
              onClick={toggleAccordion}
            >
              <img className={`${setRotate}`} src={CvArrow} alt="" />
            </button>
            {props.cv ? (
              <button
                className={`${styles.accordion} ${setActive}`}
                onClick={() => deleteCV(props.id)}
              >
                <img className={styles.delete} src={Delete} alt="" />
              </button>
            ) : props.offer ? (
              <button
                className={`${styles.accordion} ${setActive}`}
                onClick={() => deleteOffer(props.id)}
              >
                <img className={styles.delete} src={Delete} alt="" />
              </button>
            ) : null}
          </div>
        </div>
        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className={styles.accordionContent}
        >
          <div
            className={styles.accordion__text}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </div>
      </div>
    </div>
  );
};

AccordionCVandOffers.defaultProps = {};

AccordionCVandOffers.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  skype: PropTypes.string,
  company: PropTypes.string,
  project: PropTypes.string,
  cv: PropTypes.string,
  content: PropTypes.string,
  date: PropTypes.string,
};

const mapDispatchToProps = {
  deleteCV,
  deleteOffer,
};

export default connect(null, mapDispatchToProps)(AccordionCVandOffers);

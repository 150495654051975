import React, { useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import styles from "./admin-career.module.scss";
import AdminContentTitle from "../../components/AdminContentTitle";
import AccordionCVandOffers from "../../components/AccordionCVandOffers";
import { connect } from "react-redux";
import { getCV } from "../../../stores/cv/actions";

const AdminCV = (props) => {
  const { getCV, cv } = props;

  useEffect(() => {
    getCV();
  }, [getCV]);

  return (
    <div className={styles.root}>
      <Tabs forceRenderTabPanel defaultIndex={0}>
        <AdminContentTitle title="CV" />
        <div className={styles.buttonTabs}>
          <TabList className={styles.buttons}>
            <Tab>New</Tab>
            <Tab>All</Tab>
          </TabList>
        </div>
        <TabPanel>
          <div className={styles.cvTitle}>
            <h1>New CV</h1>
          </div>
          {cv
            .map((item) => {
              return (
                <AccordionCVandOffers
                  key={item.id}
                  name={item.name}
                  email={item.email}
                  cv={item.cv}
                  content={item.message}
                  date={item.createdAt}
                  id={item.id}
                />
              );
            })
            .reverse()}
        </TabPanel>
        <TabPanel>
          <div className={styles.cvTitle}>
            <h1>All CV</h1>
          </div>
          {cv
            .map((item) => {
              return (
                <AccordionCVandOffers
                  key={item.id}
                  name={item.name}
                  email={item.email}
                  cv={item.cv}
                  content={item.message}
                  date={item.createdAt}
                  id={item.id}
                />
              );
            })
            .reverse()}
        </TabPanel>
      </Tabs>
    </div>
  );
};

AdminCV.defaultProps = {};

AdminCV.propTypes = {
  getCv: PropTypes.func,
  cv: PropTypes.array,
};

const mapStateToProps = (state) => ({
  cv: state.cv.list,
});

const mapDispatchToProps = {
  getCV,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminCV);

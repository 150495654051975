import React from "react";
import styles from "./how-we-work-legal-basis-logo.module.scss";
import legacyBasisImg from "../../../assets/images/legal-basis-for-mobile.svg";

const HowWeWorkLegalBasisLogo = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <span>Legal</span>
        <span>Basis</span>
      </h2>
      <svg
        className={styles.letterAnim}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={styles.letterPos}>
          <g className={styles.letterTop}>
            <path
              d="M0.500002 57.4241L0.5 0.861529L49.9923 29.1428L0.500002 57.4241Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M51.1006 228.424L51.1006 171.862L1.60833 200.143L51.1006 228.424Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.500005 125.996L0.5 69.4331L49.9923 97.7143L0.500005 125.996Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M51.1006 103.719L51.1006 160.281L1.60833 132L51.1006 103.719Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.500005 194.567L0.5 138.004L49.9923 166.286L0.500005 194.567Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M51.1006 35.1475L51.1006 91.7101L1.60832 63.4288L51.1006 35.1475Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.500003 206.148L0.5 262.71L49.9923 234.429L0.500003 206.148Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
          <g className={styles.letterBottom}>
            <path
              d="M51.1006 296.996L51.1006 240.433L1.60832 268.714L51.1006 296.996Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M56.1006 240.862L56.1006 297.424L105.593 269.143L56.1006 240.862Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M163.101 230.424L163.101 173.862L113.608 202.143L163.101 230.424Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M106.101 262.424L106.101 205.862L56.6083 234.143L106.101 262.424Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M111.101 205.862L111.101 262.424L160.593 234.143L111.101 205.862Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
        </g>
      </svg>
      <div className={styles.letterMobile}>
        <img src={legacyBasisImg} alt="" />
        <h2 className={styles.titleMobile}>
          <span>Legal</span>
          <span>Basis</span>
        </h2>
      </div>
    </div>
  );
};

export default HowWeWorkLegalBasisLogo;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    getVacancies,
    addVacancy,
    updateVacancy,
    deleteVacancy,
} from '../../../stores/vacancies/actions';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import styles from './admin-career.module.scss';
import AdminContentTitle from '../../components/AdminContentTitle';

import Button from '../../components/AdminButton';
import EditForm from './EditForm';
import { ModalWindow } from '../../components';

import {
    transformeditorObjToVacancyObj,
    transformVacancyObjToEditorObj,
} from './helpers';

import i18next from 'i18next';
import Delete from '../../../assets/images/delete.svg';

import cn from 'classnames';

export const SPECIALIZATIONS = {
    FRONTEND: 'frontend',
    BACKEND: 'backend',
};

const initialSlideData = {
    name: 'new slide',
    requirementsEn: 'requirements',
    requirementsRu: '',
    requirementsUa: 'вимоги',
    willBePlusEn: 'will be plus',
    willBePlusRu: '',
    willBePlusUa: 'Буде плюсом',
    responsibilitiesEn: 'responsibilities',
    responsibilitiesRu: '',
    responsibilitiesUa: 'обовьязки',
    conditionsEn: 'conditions',
    conditionsRu: '',
    conditionsUa: 'умови',
    specialization: 'frontend',
};

const AdminVacancy = (props) => {
    const {
        getVacancies,
        addVacancy,
        updateVacancy,
        deleteVacancy,
        vacancies,
    } = props;
    const [spTab, setSpTab] = useState(SPECIALIZATIONS.FRONTEND);

    const [tabIndex, setTabIndex] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [vacList, setVacList] = useState([]);

    useEffect(() => {
        getVacancies();
    }, [getVacancies]);

    useEffect(() => {
        setVacList(
            vacancies.filter((v) => v.specialization === spTab && v.isEnabled)
        );
    }, [spTab, vacancies]);

    const uaT = i18next.getFixedT('ua', 'translations');
    const enT = i18next.getFixedT('en', 'translations');

    const handleTabSelect = (tabIndex) => {
        setTabIndex(tabIndex);
    };

    const onDelete = (id) => {
        deleteVacancy(id);
    };

    const onUpdate = (val) => {
        updateVacancy(transformeditorObjToVacancyObj(val), val.id);
    };

    const onCreate = (val) => {
        addVacancy(transformeditorObjToVacancyObj(val)).then((res) => {
            if (res) {
                setIsOpenModal(false);
            }
        });
    };

    return (
        <div className={styles.root}>
            <Tabs
                forceRenderTabPanel
                defaultIndex={0}
                onSelect={(index) =>
                    setSpTab(
                        index === 0
                            ? SPECIALIZATIONS.FRONTEND
                            : SPECIALIZATIONS.BACKEND
                    )
                }
            >
                <AdminContentTitle title="Vacancy" />
                <div className={styles.buttonTabs}>
                    <TabList className={styles.buttons}>
                        <Tab>Front-end developer</Tab>
                        <Tab>Back-end developer</Tab>
                    </TabList>
                </div>
                <>
                    <Tabs
                        forceRenderTabPanel
                        onSelect={handleTabSelect}
                        selectedIndex={tabIndex}
                    >
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>Vacancy</th>
                                    <th>Vacancy info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <TabList>
                                            {vacList?.map((item, index) => {
                                                const itemClass = cn(
                                                    styles.textTab,
                                                    {
                                                        [styles.textTabHighlighted]:
                                                            index === tabIndex,
                                                    }
                                                );
                                                return (
                                                    <Tab
                                                        key={index}
                                                        className={itemClass}
                                                    >
                                                        <div
                                                            className={
                                                                styles.tab
                                                            }
                                                        >
                                                            <h4
                                                                className={
                                                                    styles.textTab
                                                                }
                                                            >
                                                                {enT(
                                                                    item.name
                                                                ) ||
                                                                    'Name not set'}
                                                            </h4>
                                                            <img
                                                                onClick={() =>
                                                                    onDelete(
                                                                        item.id
                                                                    )
                                                                }
                                                                src={Delete}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Tab>
                                                );
                                            })}
                                        </TabList>
                                        <Button
                                            view="add"
                                            onClick={() => {
                                                setIsOpenModal(true);
                                            }}
                                        >
                                            Add new vacancy
                                        </Button>
                                    </td>
                                    <td className={styles.fields}>
                                        {vacList.map((t, i) => (
                                            <TabPanel key={i}>
                                                <EditForm
                                                    initialState={transformVacancyObjToEditorObj(
                                                        t,
                                                        enT,
                                                        uaT
                                                    )}
                                                    onSubmit={(val) =>
                                                        onUpdate(val)
                                                    }
                                                    onCancel={() => {
                                                        alert('cancel');
                                                    }}
                                                />
                                            </TabPanel>
                                        ))}

                                        <ModalWindow
                                            isOpen={isOpenModal}
                                            onClose={() => {
                                                setIsOpenModal(false);
                                            }}
                                            className={styles.modalContent}
                                        >
                                            <EditForm
                                                initialState={initialSlideData}
                                                onSubmit={(val) =>
                                                    onCreate(val)
                                                }
                                                onCancel={() =>
                                                    setIsOpenModal(false)
                                                }
                                            />
                                        </ModalWindow>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Tabs>
                </>
            </Tabs>
        </div>
    );
};

AdminVacancy.defaultProps = {};

AdminVacancy.propTypes = {
    getVacancies: PropTypes.func,
    addVacancy: PropTypes.func,
    updateVacancy: PropTypes.func,
    deleteVacancy: PropTypes.func,
    vacancies: PropTypes.array,
};

const mapStateToProps = (state) => ({
    vacancies: state.vacancies.list,
});

const mapDispatchToProps = {
    getVacancies,
    addVacancy,
    updateVacancy,
    deleteVacancy,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminVacancy);

import React from "react";
import styles from "./about-us-company-story-logo.module.scss";

const AboutUsCompanyStoryLogo = () => {
  return (
    <>
      <h2 className={styles.title}>
        <span>Company</span>
        <span>Story</span>
      </h2>
      <svg
        className={styles.letterAnim}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={styles.letterTop}>
          <path
            d="M101.84 50.5039L158.406 50.5039L130.123 1.00813L101.84 50.5039Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M33.2637 50.5039L89.8303 50.5039L61.547 1.00813L33.2637 50.5039Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M192.695 0.499999L136.128 0.5L164.411 49.9958L192.695 0.499999Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M35.1499 56.3037L91.7165 56.3037L63.4332 105.799L35.1499 56.3037Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M170.416 50.5039L226.983 50.5039L198.699 1.00813L170.416 50.5039Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M124.119 0.500003L67.552 0.5L95.8353 49.9958L124.119 0.500003Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
        <g className={styles.letterBottom}>
          <path
            d="M89.8305 167.911L33.2639 167.911L61.5472 217.407L89.8305 167.911Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M91.7163 162.111L35.1497 162.111L63.433 112.616L91.7163 162.111Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M57.4282 112.108L0.86159 112.108L29.1449 161.604L57.4282 112.108Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M158.407 167.911L101.84 167.911L130.123 217.407L158.407 167.911Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M57.4282 106.307L0.86159 106.307L29.1449 56.8114L57.4282 106.307Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M136.128 217.915L192.695 217.915L164.411 168.419L136.128 217.915Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M226.983 167.911L170.416 167.911L198.7 217.407L226.983 167.911Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M67.5518 217.915L124.118 217.915L95.8351 168.419L67.5518 217.915Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
      </svg>
    </>
  );
};

export default AboutUsCompanyStoryLogo;

import constants from "./constants";

export default function offersReducer(state = {
    list: [],
    errors: [],
}, action) {
  switch (action.type) {
    case constants.OFFERS_GET_SUCCESS:
      return { ...state, list: [...action.payload ]};
    default:
      return state;
  }
}

import React, { useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import styles from "./admin-offers.module.scss";
import AdminContentTitle from "../../components/AdminContentTitle";
import AccordionCVandOffers from "../../components/AccordionCVandOffers";
import { connect } from "react-redux";
import { getOffers } from "../../../stores/incomingOffers/actions";

const AdminOffers = (props) => {
  const { getOffers, incomingOffers } = props;

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  return (
    <div className={styles.root}>
      <Tabs forceRenderTabPanel defaultIndex={0}>
        <AdminContentTitle title="Incoming offers" />
        <div className={styles.buttonTabs}>
          <TabList className={styles.buttons}>
            <Tab>New</Tab>
            <Tab>All</Tab>
          </TabList>
        </div>
        <TabPanel>
          <div className={styles.cvTitle}>
            <h1>New</h1>
          </div>
          {incomingOffers
            .map((item) => {
              return (
                <AccordionCVandOffers
                  offer
                  key={item.id}
                  name={item.fullname}
                  email={item.email}
                  skype={item.skype}
                  company={item.companyName}
                  project={item.projectType}
                  content={item.message}
                  date={item.createdAt}
                  id={item.id}
                />
              );
            })
            .reverse()}
        </TabPanel>
        <TabPanel>
          <div className={styles.cvTitle}>
            <h1>All</h1>
          </div>
          {incomingOffers
            .map((item) => {
              return (
                <AccordionCVandOffers
                  offer
                  key={item.id}
                  name={item.fullname}
                  email={item.email}
                  skype={item.skype}
                  company={item.companyName}
                  project={item.projectType}
                  content={item.message}
                  date={item.createdAt}
                  id={item.id}
                />
              );
            })
            .reverse()}
        </TabPanel>
      </Tabs>
    </div>
  );
};

AdminOffers.defaultProps = {};

AdminOffers.propTypes = {
  getOffers: PropTypes.func,
  incomingOffers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  incomingOffers: state.incomingOffers.list,
});

const mapDispatchToProps = {
  getOffers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOffers);

import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";

import portfolioReducer from "./portfolio/reducer";
import contactReducer from "./contactForm/reducer";
import jobApplicationReducer from "./jobApplication/reducer";
import vacanciesReducer from "./vacancies/reducer";
import translationReducer from "./translation/reducer";
import teamReducer from "./team/reducer";
import techReducer from "./techStack/reducer";
import offersReducer from "./incomingOffers/reducer";
import CVReducer from "./cv/reducer";
import contactUsInfoReducer from "./contactUsInfo/reducer";
import sendOfferReducer from "./contactUsForm/reducer";
import loginReducer from "./login/reducer";
import sendSchoolReducer from "./contactSchoolForm/reducer";
import sendTraineeReducer from "./contactTraineeForm/reducer";

import { reducer as toastrReducer } from "react-redux-toastr";
import axios from "axios";

export const rootReducer = combineReducers({
  contactReducer,
  vacancies: vacanciesReducer,
  jobApplications: jobApplicationReducer,
  portfolio: portfolioReducer,
  translations: translationReducer,
  team: teamReducer,
  techStack: techReducer,
  incomingOffers: offersReducer,
  cv: CVReducer,
  contactUsInfo: contactUsInfoReducer,
  form: formReducer,
  toastr: toastrReducer,
  sendOffer: sendOfferReducer,
  login: loginReducer,
  sendSchoolReducer,
  sendTraineeReducer,
});

export default function configureStore() {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
}

const token = localStorage.getItem("usertoken");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

import anime from "animejs/lib/anime.es.js";

export function animate() {
    const textWrapper = document.querySelector('#about-us-part-title');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    anime({
      targets: '#about-us-title-container',
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 2000,
    })
    anime({
      targets: '#about-us-part-title .letter',
      translateY: [-1000, 0],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 30 * i
    })
  
    anime({
      targets: "#about-us-part-1",
      translateX: [-527, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-2",
      translateX: [-707, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-3",
      translateX: [-901, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-4",
      translateX: [-1001, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-5",
      translateX: [-1245, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-6",
      translateX: [-1305, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-7",
      translateX: [-1405, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-8",
      translateX: [-1616, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });

    anime({
      targets: "#about-us-part-9",
      translateX: [-1730, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });
    anime({
      targets: "#about-us-part-10",
      translateX: [-2124, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });
    anime({
      targets: "#about-us-part-11",
      translateX: [-2183, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });
    anime({
      targets: "#about-us-part-12",
      translateX: [-2273, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });
    anime({
      targets: "#about-us-part-13",
      translateX: [-2688, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });
    anime({
      targets: "#about-us-part-14",
      translateX: [-2718, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });
    anime({
      targets: "#about-us-part-15",
      translateX: [-3242, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });
    anime({
      targets: "#about-us-part-16",
      translateX: [-3382, 0],
      translateY: [100, 0],
      duration: 2000,
      easing: "easeOutExpo",
    });


}
import React from "react";
import PropTypes from "prop-types";
import styles from "./admin-content-title.module.scss";

const AdminContentTitle = props => {
  return (
    <div className={styles.root}>
      <h1>{props.title}</h1>
    </div>
  );
};

AdminContentTitle.defaultProps = {};

AdminContentTitle.propTypes = {
  title: PropTypes.string
};

export default AdminContentTitle;

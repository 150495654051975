import anime from "animejs/lib/anime.es.js";
import styles from './about-us-tech-stack-logo.module.scss';

export function animate() {
    
    /**
     * Letter animation
     */

     window.setTimeout(() => {
      const letter = document.querySelector(`.${styles.letterAnim}`);
      if(letter){
        letter.classList.add(`${styles.letterAnimAfter}`);
      }
     }, 3500);


    anime({
      targets: `.${styles.letterTop}`,
      translateX: ['80vh', 0],
      easing: "easeOutExpo",
      duration: 3400,
    });

    anime({
      targets: `.${styles.letterBottom}`,
      translateY: ['100vh', 0],
      translateX: ['-50vh', 0],
      easing: "easeOutExpo",
      duration: 3400,
    });

    
    anime({
      targets: `.${styles.title}>span:first-child`,
      translateX: ['80vh', 0],
      easing: "easeOutExpo",
      duration: 2800,
    });

    anime({
      targets: `.${styles.title}>span:last-child`,
      translateX: ['80vh', 0],
      easing: "easeOutExpo",
      duration: 3000,
      delay: 200
    });

}
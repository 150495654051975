import React, { useRef } from "react";
import styles from "./logo-section.module.scss";
import MainLogo from "./../../../../components/MainLogo/";
import leftImg from "../../../../../assets/images/logoSectionLeftImg.svg";
import rightImg from "../../../../../assets/images/logoSectionRightImg.svg";
import { useVisibility } from "./../../../../../utils/hooks";
import ClientButton from "../../../../components/ClientButton/ClientButton";
import { animate as animateLogo } from "../../../../components/MainLogo/anime";
import { animate as animateSection } from "./anime";
import { animate as animateHeader } from "../../../../components/Header/anime";
import MainLogoMobile from "../../../../../assets/images/mobileMainLogo.svg";
import { animDelay } from "./constants";
import { useTranslation } from "react-i18next";

const LogoSection = () => {
  const ref = useRef(null);
  const { t } = useTranslation("translations");
  useVisibility(
    ref,
    () => {
      if (window.matchMedia("(min-width: 769px)").matches) {
        animateHeader(animDelay.DELAY_HEADER_ANIM);
        animateLogo(animDelay.DELAY_LOGO_ANIM);
        animateSection(animDelay.DELAY_SECTION_ANIM);
      }
    },
    {
      intersectionRatio: 0.5
    }
  );

  return (
    <section ref={ref} className={styles.root}>
      <img src={leftImg} className={styles.leftImg} alt="bg img" />
      <div className={styles.mainContent}>
        <div className={styles.mainPart}>
          <MainLogo />
          <img className={styles.mainLogo} src={MainLogoMobile} alt="" />
          <div className={styles.title}>
            <div className={styles.magisoft}>{t("company_name_text")}</div>
            <div className={styles.solutions}>
              {t("company_second_name_text")}
            </div>
          </div>
        </div>
        <div className={styles.descr}>{t("company_slogan_text")}</div>

        <ClientButton
          link
          to={"/contact-us"}
          className={styles.button}
          text={"company_get_in_touch_btn_text"}
          position="center"
        />
      </div>
      <img src={rightImg} className={styles.rightImg} alt="bg img" />
    </section>
  );
};

export default LogoSection;

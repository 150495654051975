import React from "react";
import styles from "./benefits-title.module.scss";
import { useTranslation } from "react-i18next";

const BenefitsTitle = () => {
  const { t } = useTranslation("translations");
  return (
    <div className={styles.root}>
      <h2 id="benefits-title-1" className={styles.benefitsTitle}>
        {" "}
        {t("our_benefits_section_name_text").split(" ")[0]}&nbsp;
      </h2>
      <h2 id="benefits-title-2" className={styles.benefitsTitle}>
        {" "}
        {t("our_benefits_section_name_text").split(" ")[1]}
      </h2>
      <svg
        id="benefitsLetterAnim"
        className={styles.benefitsLetterAnim}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={styles.benefitsLetterGroup}>
          <path
            id="benefits-part-1-b"
            d="M194.567 0.499999L138.004 0.5L166.286 49.9923L194.567 0.499999Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-2-b"
            d="M91.1956 169.7L34.633 169.7L62.9143 219.192L91.1956 169.7Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-3-b"
            d="M103.719 50.5001L160.281 50.5001L132 1.0078L103.719 50.5001Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-4-b"
            d="M91.7097 163.9L35.1472 163.9L63.4284 114.408L91.7097 163.9Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-5-b"
            d="M125.996 0.500003L69.433 0.5L97.7142 49.9923L125.996 0.500003Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-6-b"
            d="M35.1474 56.8996L91.71 56.8996L63.4287 106.392L35.1474 56.8996Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-7-b"
            d="M57.4242 113.3L0.86159 113.3L29.1429 162.792L57.4242 113.3Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-8-b"
            d="M35.1474 50.5001L91.71 50.5001L63.4287 1.0078L35.1474 50.5001Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-9-b"
            d="M57.4242 107.5L0.86159 107.5L29.1429 58.0075L57.4242 107.5Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-1"
            d="M172.29 51.1006L228.853 51.1006L200.571 1.60839L172.29 51.1006Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-2"
            d="M68.9189 220.3L125.482 220.3L97.2002 170.808L68.9189 220.3Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-3"
            d="M172.29 56.8422L228.853 56.8422L200.571 106.334L172.29 56.8422Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-4"
            d="M159.767 169.7L103.204 169.7L131.486 219.192L159.767 169.7Z"
            fill="#0C0B0B"
            stroke="#555555"
          />

          <path
            id="benefits-part-5"
            d="M206.576 107.442L263.138 107.442L234.857 57.9501L206.576 107.442Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-6"
            d="M228.853 163.842L172.29 163.842L200.571 114.35L228.853 163.842Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-7"
            d="M137.49 220.3L194.053 220.3L165.772 170.808L137.49 220.3Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-8"
            d="M206.576 113.242L263.138 113.242L234.857 162.734L206.576 113.242Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            id="benefits-part-9"
            d="M228.338 169.7L171.776 169.7L200.057 219.192L228.338 169.7Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
      </svg>
    </div>
  );
};

export default BenefitsTitle;

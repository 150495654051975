import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './contact-us.module.scss';
import Logo from '../../../assets/images/contactUsLogo.svg';
import LeftArr from '../../../assets/images/leftArrowsHowWeWork.svg';
import RightArr from '../../../assets/images/rightArrowsHowWeWork.svg';
import Apple from '../../../assets/images/apple.svg';
import Google from '../../../assets/images/google.svg';
import { getContactUsInfo } from '../../../stores/contactUsInfo/actions';
import ContactUsPageForm from '../../components/ContactUsPageForm';
import contactMobileImg from '../../../assets/images/contact-us-mobile.svg';
import { withTranslation } from 'react-i18next';

import { animate as animateOther } from './anime';

const ContactUs = (props) => {
    const { getContactUsInfo, contactUsInfo, i18n } = props;

    const [content, setContect] = useState();

    useEffect(() => {
        getContactUsInfo();
        if (window.matchMedia('(min-width: 769px)').matches) {
            animateOther();
        }
    }, [getContactUsInfo]);

    useEffect(() => {
        const info = contactUsInfo
            ? contactUsInfo.find((item) => {
                  return i18n.language === item.lang;
              })
            : null;
        setContect(info && info.translation);
    }, [contactUsInfo, i18n.language]);

    return (
        <div className={styles.root}>
            <img src={LeftArr} className={styles.leftArr} alt="" />
            <img src={RightArr} className={styles.rigthArr} alt="" />
            <div className={styles.flex}>
                <div className={styles.logoBlock}>
                    <div className={styles.logoBlockContent}>
                        <img
                            src={Logo}
                            className={styles.contactUsImg}
                            alt=""
                        />
                        <img
                            src={contactMobileImg}
                            className={styles.contactUsMobileImg}
                            alt=""
                        />
                        <h1>Contact Us</h1>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoContent}>
                                <p className={styles.headers}>Address:</p>
                                <p className={styles.values}>
                                    {content && content.contact_us_address}
                                </p>
                            </div>
                            <div className={styles.infoContent}>
                                <p className={styles.headers}>E-mail:</p>
                                <p className={styles.values}>
                                    {content && content.contact_us_email}
                                </p>
                            </div>
                            {/* TODO removed for now */}
                            {/* <div className={styles.infoContent}>
                <p className={styles.headers}>Skype:</p>
                <p className={styles.values}>
                  {content && content.contact_us_skype}
                </p>
              </div> */}
                            <div className={styles.infoContent}>
                                <p className={styles.headers}>Phone:</p>
                                <div>
                                    <p className={styles.values}>
                                        {content && content.contact_us_phone}
                                    </p>
                                    <p className={styles.values}>
                                        {content && content.contact_us_phone2}
                                    </p>
                                    <p className={styles.values}>
                                        {content && content.contact_us_phone3}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.contactForm}>
                    <ContactUsPageForm
                        title={content && content.contact_us_form_title}
                    />
                </div>
            </div>
            <div className={styles.downloads}>
                <h3>{content && content.contact_us_app_title}</h3>
                <div className={styles.downloadsButtons}>
                    <a
                        target="blank"
                        href="https://apps.apple.com/ua/app/appprice/id1486838280"
                    >
                        <img src={Apple} alt="" />
                    </a>
                    <a
                        target="blank"
                        href="https://play.google.com/store/apps/details?id=com.estimatemyapp"
                    >
                        <img src={Google} alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

ContactUs.defaultProps = {};

ContactUs.propTypes = {
    getContactUsInfo: PropTypes.func,
    contactUsInfo: PropTypes.array,
    i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
    contactUsInfo: state.contactUsInfo.list,
});

const mapDispatchToProps = {
    getContactUsInfo,
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(ContactUs)
);

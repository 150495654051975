import React from "react";
import styles from "./about-us-tech-stack-logo.module.scss";

const AboutUsTechStackLogo = () => {
  return (
    <>
      <h2 className={styles.title}>
        <span>Technology</span>
        <span>Stack</span>
      </h2>
      <svg
        className={styles.letterAnim}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={styles.letterPos}>
          <g className={styles.letterTop}>
            <path
              d="M125.459 50.5L69.0217 50.5L97.2402 1.11769L125.459 50.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M34.8616 0.499997L91.2986 0.5L63.0801 49.8823L34.8616 0.499997Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.861641 50.5L57.2986 50.5L29.0801 1.11769L0.861641 50.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M136.862 50.5L193.299 50.5L165.08 1.11769L136.862 50.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M102.862 0.499997L159.299 0.5L131.08 49.8823L102.862 0.499997Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M170.862 0.499997L227.299 0.5L199.08 49.8823L170.862 0.499997Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
          <g className={styles.letterBottom}>
            <path
              d="M125.779 55.5L69.3421 55.5L97.5605 104.882L125.779 55.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M91.4587 110.5L35.0217 110.5L63.2402 159.882L91.4587 110.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M57.4587 165.5L1.02175 165.5L29.2402 214.882L57.4587 165.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M91.6188 105.5L35.1819 105.5L63.4004 56.1177L91.6188 105.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M57.4587 160.5L1.02175 160.5L29.2402 111.118L57.4587 160.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default AboutUsTechStackLogo;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./sidebar.module.scss";
import Accordion from "../Accordion";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getOffers } from "../../../stores/incomingOffers/actions";
import { getCV } from "../../../stores/cv/actions";

const MainContent = {
  links: [
    {
      title: "Title page",
      path: "/title-page",
    },
    {
      title: "About us",
      path: "/about-us",
    },
    {
      title: "What we do best",
      path: "/what-we-do",
    },
    {
      title: "Our benefits",
      path: "/our-benefits",
    },
    {
      title: "Contact us",
      path: "/contact-us",
    },
  ],
};
const AboutContent = {
  links: [
    {
      title: "Company's story",
      path: "/company-story",
    },
    {
      title: "Our team",
      path: "/our-team",
    },
    {
      title: "Technology stack",
      path: "/technology-stack",
    },
  ],
};
const HowWorkContent = {
  links: [
    {
      title: "Outstaff",
      path: "/outstaff",
    },
    {
      title: "Outsource",
      path: "/outsource",
    },
    {
      title: "Legal Basis",
      path: "/legal-basis",
    },
  ],
};

const noContent = {
  links: [],
};
const Sidebar = (props) => {
  const { getOffers, incomingOffers, getCV, cv } = props;
  let amount = incomingOffers.length;
  useEffect(() => {
    getOffers();
    //
  }, [getOffers]);

  let amountCV = cv.length;
  useEffect(() => {
    getCV();
  }, [getCV]);

  const CareerContent = {
    links: [
      {
        title: "Vacancy",
        path: "/vacancy",
      },
      {
        title: "Trainee Camp",
        path: "/trainee",
      },
      {
        title: "IT School",
        path: "/school",
      },
      {
        title: "CV",
        path: "/cv",
        p: `${amountCV}`,
      },
    ],
  };

  return (
    <nav className={styles.root}>
      <Accordion title="Main" content={MainContent} />
      <Link to="/header" className={styles.noArrow}>
        <Accordion title="Header" content={noContent} />
      </Link>
      <Link to="/footer" className={styles.noArrow}>
        <Accordion title="Footer" content={noContent} />
      </Link>
      <Accordion title="About us" content={AboutContent} />

      <Accordion title="How do we work" content={HowWorkContent} />

      <Link to="/portfolio" className={styles.noArrow}>
        <Accordion title="Portfolio" content={noContent} />
      </Link>
      <Accordion title="Career" content={CareerContent} />
      <Link to="/contact-us-form" className={styles.noArrow}>
        <Accordion title="Contact us" content={noContent} />
      </Link>
      <Link to="/incoming-offers" className={styles.noArrow}>
        <Accordion title="Incoming offers" content={noContent} />
        <p>{amount}</p>
      </Link>
    </nav>
  );
};

Sidebar.defaultProps = {};

Sidebar.propTypes = {
  incomingOffers: PropTypes.array,
  cv: PropTypes.array,
  getOffers: PropTypes.func,
  getCV: PropTypes.func,
};

const mapStateToProps = (state) => ({
  incomingOffers: state.incomingOffers.list,
  cv: state.cv.list,
});
const mapDispatchToProps = {
  getOffers,
  getCV,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

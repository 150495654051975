import React from "react";
import { connect } from "react-redux";
import styles from "../../pages/School/magi-school.module.scss";

import LeftArr from "../../../assets/images/leftArrowsHowWeWork.svg";
import RightArr from "../../../assets/images/rightArrowsHowWeWork.svg";

import { getContactUsInfo } from "../../../stores/contactUsInfo/actions";
import Logo from "../../../assets/images/T.svg";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { animate as animateOther } from "../../pages/School/anime";
import { useTranslation } from "react-i18next";
import TraineePageForm from "../../components/TraineePageForm/TraineePageForm";

const TraineePage = (props) => {
  const { t } = useTranslation("translations");
  const { getContactUsInfo, contactUsInfo, i18n } = props;

  const [content, setContect] = useState();

  useEffect(() => {
    getContactUsInfo();
    if (window.matchMedia("(min-width: 769px)").matches) {
      animateOther();
    }
  }, [getContactUsInfo]);

  useEffect(() => {
    const info = contactUsInfo
      ? contactUsInfo.find((item) => {
          return i18n.language === item.lang;
        })
      : null;
    setContect(info && info.translation);
  }, [contactUsInfo, i18n.language]);

  return (
    <div className={styles.root}>
      <img src={LeftArr} className={styles.leftArr} alt="" />
      <img src={RightArr} className={styles.rigthArr} alt="" />
      <div className={styles.flex}>
        <div className={styles.logoBlock}>
          <div className={styles.logoBlockContent}>
            <img src={Logo} className={styles.contactUsImg} alt="" />
            <h1>Get chance to work in IT</h1>
          </div>
        </div>

        <div className={styles.contactForm}>
          <div className={styles.schoolContent}>
            {t("trainee_text")
              .split("\n")
              .map((p, key) => (
                <p key={key}>{p}</p>
              ))}
          </div>
          <TraineePageForm title={content && content.contact_us_form_title} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contactUsInfo: state.contactUsInfo.list,
});

const mapDispatchToProps = {
  getContactUsInfo,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TraineePage)
);

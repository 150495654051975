export  const transformPortfolioObjToEditorObj = (portfolio, enT, uaT) => {
    return {
      title: enT(portfolio.title),
      descEn: enT(portfolio.description),
      descUa: uaT(portfolio.description),
      descRu: '',
      slide: null,
      slideName: portfolio.slide,
      logo: null,
      logoName: portfolio.logo,
      id: portfolio.id,
    }
  };
import axios from "axios";
import constants from "./constants";
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from "../../enviroments";
/**
 * GET CONTACT INFO
 */

export const getContactUsInfo = () => {
  return async (dispatch) => {
    dispatch(request());
    return await axios
      .get(`${PROD_BASE_URL}/internalization`)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
  function request() {
    return { type: constants.CONTACT_US_INFO_GET_REQUEST };
  }
  function success(data) {
    return { type: constants.CONTACT_US_INFO_GET_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.CONTACT_US_INFO_GET_FAILURE, error };
  }
};

/**
 * UPDATE CONTACT INFO
 */

export const updateContactUsInfo = (data) => {
  return async (dispatch) => {
    return await axios
      .put(`${PROD_BASE_URL}/internalization`, data)
      .then((response) => {
        toastr.success("Success", "Updated");

        dispatch(success(response.data));
      })
      .catch((error) => {
        if (error.response.data.message && error.response.data.message !== "") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
        dispatch(failure(error));
      });
  };

  // function request(id) { return { type: constants.CONTACT_US_INFO_UPDATE_ITEM_REQUEST, payload: id } }
  function success(data) {
    return {
      type: constants.CONTACT_US_INFO_UPDATE_ITEM_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return { type: constants.CONTACT_US_INFO_UPDATE_ITEM_FAILURE, error };
  }
};

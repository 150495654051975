import React, { useEffect } from "react";
import styles from "./company-story.module.scss";

import LeftArr from "../../../assets/images/leftArrowsHowWeWork.svg";
import RightArr from "../../../assets/images/rightArrowsHowWeWork.svg";

import { AboutUsCompanyStoryLogo } from "../../components";

import { animate as animateLetter } from "../../components/AboutUsCompanyStoryLogo/anime";
import { animate as animateOther } from "./anime";
import { useTranslation } from "react-i18next";

const CompanyStory = () => {
  const { t } = useTranslation("translations");

  useEffect(() => {
    if (window.matchMedia("(min-width: 769px)").matches) {
      animateLetter();
      animateOther();
    }
  }, []);
  return (
    <div className={styles.root}>
      <img src={LeftArr} className={styles.leftArr} alt="" />
      <img src={RightArr} className={styles.rigthArr} alt="" />
      <div className={styles.content}>
        {t("company_story_text")
          .split("\n")
          .map((p, key) => (
            <p key={key}>{p}</p>
          ))}
      </div>
      <div className={styles.logoBlock}>
        <AboutUsCompanyStoryLogo />
      </div>
    </div>
  );
};

export default CompanyStory;

import anime from "animejs/lib/anime.es.js";
export const animate = () => {
    anime({
        targets: "#do-best-letter-anim",
        opacity: [0, 1],
        duration: 2000,
        easing: "easeOutExpo"
    });
    anime({
        targets: "#do-best-part-1",
        translateX: ['100%', 'calc(50% + 20px)'],
        duration: 2000,
        easing: "easeOutExpo"
    });
    anime({
        targets: "#do-best-part-2",
        translateX: ['-100%', 'calc(50% + 20px)'],
        duration: 2000,
        easing: "easeOutExpo"
    });
    anime({
        targets: "#do-best-title",
        fontSize: [70, 50],
        translateY: [125, 0],
        duration: 2000,
        delay: 300,
        easing: "easeOutExpo"
    });
}
import  { useEffect } from 'react';

export function useVisibility(ref, callback, opts = {}) {
    useEffect(() => {
        // let previousY = 0;
        let timesTriggered = 0;
        const observer = new IntersectionObserver((
            [entry]
        ) => {
            let intRatio = entry.intersectionRatio;
            let minIntRatio = opts.intersectionRatio;
            // let scrollDirection = opts.scrollDirection;
            // let currentY = entry.boundingClientRect.y;
            if (intRatio > minIntRatio) {
                if (timesTriggered < 1) {
                    callback(intRatio);
                    timesTriggered++;
                }
            }
            // previousY = currentY;
        }, {
                root: null,
                rootMargin: '0px',
                threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
            });

        if (ref.current) {
            observer.observe(ref.current);
        }
    }, [ref, callback, opts])
}
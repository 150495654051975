import React from "react";
import styles from "./main-logo.module.scss";
import "./main-logo.scss";

const MainLogo = () => {
  return (
    <div className={styles.root}>
      <svg className={styles.mainLogoLetterAnim}>
        <g
          id="main-logo-letter-group"
          className={styles.mainLogoLetterGroup}
          // filter="url(#filter0_d)"
        >
          <path
            id="logo-part-1"
            d="M137.5 10L95 34.2857L137.5 58.5714V10Z"
            fill="#3E3E3E"
          />
          <path
            id="logo-part-2"
            d="M95 34.2856L137.5 58.5714L95 82.8571V34.2856Z"
            fill="#0F0F10"
          />
          <path
            id="logo-part-3"
            d="M137.5 58.5714L94.9999 82.8571L137.5 107.143V58.5714Z"
            fill="#CAE5EE"
          />
          <path
            id="logo-part-4"
            d="M95 82.8572L137.5 107.143L95 131.429V82.8572Z"
            fill="#D6E7EE"
          />
          <path
            id="logo-part-5"
            d="M137.5 107.143L94.9999 131.429L137.5 155.714V107.143Z"
            fill="#CAE5EE"
          />
          <path
            id="logo-part-6"
            d="M137.5 10L180 34.2857L137.5 58.5714V10Z"
            fill="#202020"
          />
          <path
            id="logo-part-7"
            d="M180 34.2856L137.5 58.5714L180 82.8571V34.2856Z"
            fill="#242423"
          />
          <path
            id="logo-part-8"
            d="M137.5 58.5714L180 82.8571L137.5 107.143V58.5714Z"
            fill="#282D31"
          />
          <path
            id="logo-part-9"
            d="M180 82.8572L137.5 107.143L180 131.429V82.8572Z"
            fill="#141819"
          />
          <path
            id="logo-part-10"
            d="M137.5 107.143L180 131.429L137.5 155.714V107.143Z"
            fill="#0F0F10"
          />
          <path
            id="logo-part-1-b"
            d="M52.5 10L95 34.2857L52.5 58.5714V10Z"
            fill="#282D31"
          />
          <path
            id="logo-part-2-b"
            d="M95 34.2856L52.5 58.5714L95 82.8571V34.2856Z"
            fill="#202020"
          />
          <path
            id="logo-part-3-b"
            d="M52.5 58.5714L95 82.8571L52.5 107.143V58.5714Z"
            fill="#B1D9E3"
          />
          <path
            id="logo-part-4-b"
            d="M95 82.8572L52.5 107.143L95 131.429V82.8572Z"
            fill="#CAE5EE"
          />
          <path
            id="logo-part-5-b"
            d="M52.5 107.143L95 131.429L52.5 155.714V107.143Z"
            fill="#D6E7EE"
          />
          <path
            id="logo-part-6-b"
            d="M52.5 10L10 34.2857L52.5 58.5714L52.5 10Z"
            fill="#202020"
          />
          <path
            id="logo-part-7-b"
            d="M10 34.2856L52.5 58.5714L10 82.8571L10 34.2856Z"
            fill="#141819"
          />
          <path
            id="logo-part-8-b"
            d="M52.5 58.5714L10 82.8571L52.5 107.143L52.5 58.5714Z"
            fill="#0F0F10"
          />
          <path
            id="logo-part-9-b"
            d="M10 82.8572L52.5 107.143L10 131.429L10 82.8572Z"
            fill="#242423"
          />
          <path
            id="logo-part-10-b"
            d="M52.5 107.143L10 131.429L52.5 155.714L52.5 107.143Z"
            fill="#3E3E3E"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default MainLogo;

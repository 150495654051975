import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import styles from "./admin-about-us.module.scss";
import AdminContentTitle from "../../components/AdminContentTitle";
import Button from "../../components/AdminButton";
import Delete from "../../../assets/images/delete.svg";

import {
  getTechStack,
  addTech,
  deleteTech,
  updateTech
} from "../../../stores/techStack/actions";

import {
  transformEditorObjToTechStackObj,
  transformTechStackObjToEditorObj
} from "./helpers";
import { ModalWindow } from "../../components";
import { jsonToFormData } from "../../../utils/index";

import i18next from "i18next";

import EditFormTechStack from "./EditFormTechStack";
import cn from "classnames";

const initialSlideData = {
  titleEn: "new tech",
  titleUa: "нова технологiя",
  titleRu: "",
  summaryEn: "summary",
  summaryUa: "стислий опис",
  summaryRu: "",
  descriptionEn: "description",
  descriptionUa: "детальний опис [абзаци]",
  descriptionRu: "",
  logo: null
};

const AdminTechnologyStack = props => {
  const { getTechStack, addTech, updateTech, deleteTech, techStack } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const uaT = i18next.getFixedT("ua", "translations");
  const enT = i18next.getFixedT("en", "translations");

  useEffect(() => {
    getTechStack();
  }, [getTechStack]);

  const handleTabSelect = tabIndex => {
    setTabIndex(tabIndex);
  };

  const onDelete = id => {
    if (window.confirm("Пiдтвердiть видалення обьекту")) {
      deleteTech(id);
    }
  };

  const onUpdate = val => {
    updateTech(jsonToFormData(transformEditorObjToTechStackObj(val)), val.id);
  };

  const onCreate = val => {
    addTech(jsonToFormData(transformEditorObjToTechStackObj(val))).then(res => {
      if (res) {
        setIsOpenModal(false);
      }
    });
  };

  return (
    <div className={styles.root}>
      <AdminContentTitle title="Technology stack" />
      <Tabs
        forceRenderTabPanel
        onSelect={handleTabSelect}
        selectedIndex={tabIndex}
      >
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Stack</th>
              <th>Stack info</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TabList>
                  {techStack.map((t, index) => {
                    const itemClass = cn(styles.textTab, {
                      [styles.textTabHighlighted]: index === tabIndex
                    });
                    return (
                      <Tab key={index} className={itemClass}>
                        <div className={styles.tab}>
                          <h4 className={styles.textTab}>{enT(t.title)}</h4>
                          <img
                            onClick={() => onDelete(t.id)}
                            src={Delete}
                            alt=""
                          />
                        </div>
                      </Tab>
                    );
                  })}
                </TabList>
                <Button
                  view="add"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  Add new stack
                </Button>
              </td>
              <td className={styles.fields}>
                {techStack.map((t, i) => (
                  <TabPanel key={i}>
                    <EditFormTechStack
                      initialState={transformTechStackObjToEditorObj(
                        t,
                        enT,
                        uaT
                      )}
                      onSubmit={val => onUpdate(val)}
                      onCancel={() => {
                        alert("cancel");
                      }}
                      img={t.logo}
                    />
                  </TabPanel>
                ))}
                <ModalWindow
                  isOpen={isOpenModal}
                  onClose={() => {
                    setIsOpenModal(false);
                  }}
                  className={styles.modalContent}
                >
                  <EditFormTechStack
                    initialState={initialSlideData}
                    onSubmit={val => onCreate(val)}
                    onCancel={() => {
                      setIsOpenModal(false);
                    }}
                  />
                </ModalWindow>
              </td>
            </tr>
          </tbody>
        </table>
      </Tabs>
    </div>
  );
};

const mapStateToProps = state => ({
  techStack: state.techStack.list
});

const mapDispatchToProps = {
  getTechStack,
  addTech,
  deleteTech,
  updateTech
};

AdminTechnologyStack.defaultProps = {};

AdminTechnologyStack.propTypes = {
  getTechStack: PropTypes.func,
  addTech: PropTypes.func,
  updateTech: PropTypes.func,
  deleteTech: PropTypes.func,
  techStack: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTechnologyStack);

import anime from "animejs/lib/anime.es.js";
import styles from './career-frontend-logo.module.scss';

export function animate() {
    
    /**
     * Letter animation
     */

    anime({
      targets: `.${styles.letterTop}`,
      translateY: ['100vh', '60px'],
      translateX: ['-50vh', '240px'],
      easing: "easeOutExpo",
      duration: 2400,
      delay: 1000,
    });

    anime({
      targets: `.${styles.letterBottom}`,
      translateY: ['100vh', '60px'],
      translateX: ['-50vh', '240px'],
      easing: "easeOutExpo",
      duration: 2400,
      delay: 1400,
    });

    
    anime({
      targets: `.${styles.title}>span:first-child`,
      translateX: ['-50vh', 0],
      easing: "easeOutExpo",
      duration: 2400,
      delay: 800,
    });

    anime({
      targets: `.${styles.title}>span:last-child`,
      translateX: ['-50vh', 0],
      easing: "easeOutExpo",
      duration: 2400,
      delay: 1000,
    });

}
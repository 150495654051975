import axios from "axios";
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from '../../enviroments';


const SEND_USER_MESSAGE = "SEND_USER_MESSAGE";
const apiUrl = PROD_BASE_URL;

export const sendUserMessage = data => {
  return async dispatch => {
    return await axios
      .post(`${apiUrl}/clientContacts`, data)
      .then(response => {
        dispatch(createPostSuccess(response.data));
        return response;
      })
      .catch(error => {
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
        throw error;
      });
  };
};

export const createPostSuccess = data => {
  return {
    type: SEND_USER_MESSAGE,
    payload: {
      message: data.message
    }
  };
};

export default SEND_USER_MESSAGE;

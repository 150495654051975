import React from "react";
import PropTypes from "prop-types";
import styles from "./how-we-work-outsource-logo.module.scss";
import outstaffImg from "../../../assets/images/outstaff-for-mobile.svg";

const HowWeWorkOutsourceLogo = ({ title }) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <span>{title}</span>
      </h2>
      <svg
        className={styles.letterAnim}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={styles.letterPos}>
          <g className={styles.letterTop}>
            <path
              d="M91.1957 169.7L34.6331 169.7L62.9144 219.192L91.1957 169.7Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M194.567 0.499999L138.004 0.5L166.286 49.9923L194.567 0.499999Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M91.7098 163.9L35.1472 163.9L63.4285 114.408L91.7098 163.9Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M35.1474 50.5L91.7099 50.5L63.4287 1.00779L35.1474 50.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M103.719 50.5L160.281 50.5L132 1.00779L103.719 50.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M57.4242 113.3L0.86159 113.3L29.1429 162.792L57.4242 113.3Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M35.1474 56.8996L91.7099 56.8996L63.4287 106.392L35.1474 56.8996Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M57.4242 107.5L0.86159 107.5L29.1429 58.0075L57.4242 107.5Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M125.995 0.500003L69.4329 0.5L97.7142 49.9923L125.995 0.500003Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
          <g className={styles.letterBottom}>
            <path
              d="M159.767 169.7L103.204 169.7L131.486 219.192L159.767 169.7Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M172.29 51.1006L228.853 51.1006L200.571 1.60839L172.29 51.1006Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M137.49 220.3L194.053 220.3L165.772 170.808L137.49 220.3Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M172.29 56.8422L228.853 56.8422L200.571 106.334L172.29 56.8422Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M206.576 107.442L263.138 107.442L234.857 57.9501L206.576 107.442Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M228.853 163.842L172.29 163.842L200.571 114.35L228.853 163.842Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M206.576 113.242L263.138 113.242L234.857 162.734L206.576 113.242Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M228.338 169.7L171.776 169.7L200.057 219.192L228.338 169.7Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M68.9188 220.3L125.481 220.3L97.2001 170.808L68.9188 220.3Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
        </g>
      </svg>
      <div className={styles.letterMobile}>
        <img src={outstaffImg} alt="" />
        <h2 className={styles.titleMobile}>
          <span>{title}</span>
        </h2>
      </div>
    </div>
  );
};

HowWeWorkOutsourceLogo.defaultProps = {};

HowWeWorkOutsourceLogo.propTypes = {
  title: PropTypes.string
};

export default HowWeWorkOutsourceLogo;

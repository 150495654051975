import { connect } from 'react-redux';

import { getTechStack } from './../../../stores/techStack/actions';

import TechnologyStack from './TechnologyStack';



const mapStateToProps = (state) => ({
    techStack: state.techStack.list
})

const mapDispatchToProps = {
    getTechStack
}

export default connect(mapStateToProps, mapDispatchToProps)(TechnologyStack)

export const parseStrArray = (str) => {
    let res = '';
    let arr = [];
    try{
        arr = JSON.parse(str);
       
    }
    catch(e){

    }

    if(Array.isArray(arr)){
        res = arr;
    } 
    else {
        res = [];
    }

    return res;
} 
import React, { useRef } from "react";
import styles from "./benefits.module.scss";
import Card from "../../../../components/Card";
import cardLogo1 from "../../../../../assets/images/cardLogo1.svg";
import cardLogo2 from "../../../../../assets/images/cardLogo2.svg";
import cardLogo3 from "../../../../../assets/images/cardLogo3.svg";
import rightImg from "../../../../../assets/images/benefitsSectionRightImg.svg";
import { BenefitsTitleWithAnime } from "./../../../../components";
import Slider from "react-slick";
import { useVisibility } from "./../../../../../utils/hooks";
import { animate as animateLetter } from "../../../../components/BenefitsTitle/anime";
import { animate as animateBenefits } from "./anime";
import { useTranslation } from "react-i18next";
import letterO from "../../../../../assets/images/o.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Benefits = () => {
  const ref = useRef(null);

  useVisibility(
    ref,
    () => {
      if (window.matchMedia("(min-width: 769px)").matches) {
        animateLetter();
        animateBenefits();
      }
    },
    {
      intersectionRatio: 0.5
    }
  );

  const { t } = useTranslation("translations");

  return (
    <section ref={ref} id="benefits" className={styles.root}>
      <img src={rightImg} className={styles.rightImg} alt="" />
      <BenefitsTitleWithAnime />
      <img src={letterO} className={styles.letterO} alt="" />
      <div className={styles.benefitsCards}>
        <Card
          cardLogo={cardLogo1}
          cardContent={t("our_benefits_block_1_text")
            .split("\n")
            .map((p, key) => (
              <p key={key}>{p}</p>
            ))}
          tilt={1}
        />
        <Card
          cardLogo={cardLogo2}
          cardContent={t("our_benefits_block_2_text")
            .split("\n")
            .map((p, key) => (
              <p key={key}>{p}</p>
            ))}
          tilt={0}
        />
        <Card
          cardLogo={cardLogo3}
          cardContent={t("our_benefits_block_3_text")
            .split("\n")
            .map((p, key) => (
              <p key={key}>{p}</p>
            ))}
          tilt={1}
        />
      </div>
      {/* Mobile */}

      <Slider
        className={styles.slider}
        adaptiveHeight={true}
        arrows={false}
        dots={true}
        autoplaySpeed={3000}
        centerMode={true}
        dotsClass={styles.sliderDots}
        focusOnSelect={true}
        slidesToShow={1}
        centerPadding={"0"}
        customPaging={i => <button></button>}
      >
        <Card
          cardLogo={cardLogo1}
          cardContent={t("our_benefits_block_1_text")
            .split("\n")
            .map((p, key) => (
              <p key={key}>{p}</p>
            ))}
          tilt={1}
        />
        <Card
          cardLogo={cardLogo2}
          cardContent={t("our_benefits_block_2_text")
            .split("\n")
            .map((p, key) => (
              <p key={key}>{p}</p>
            ))}
          tilt={0}
        />
        <Card
          cardLogo={cardLogo3}
          cardContent={t("our_benefits_block_3_text")
            .split("\n")
            .map((p, key) => (
              <p key={key}>{p}</p>
            ))}
          tilt={1}
        />
      </Slider>

      {/* Mobile */}
    </section>
  );
};

export default Benefits;

import React from "react";
import { HowWeWorkOutsourceLogo } from "../../components";
import HowDoWeWork from "./HowDoWeWork";

const Outstaff = () => {
  return (
    <HowDoWeWork
      logo={<HowWeWorkOutsourceLogo title="Outstaff" />}
      title={"outstaff_title_text"}
      text={"outstaff_text"}
    />
  );
};

export default Outstaff;

export default {
    VACANCIES_GET_REQUEST: 'VACANCIES_GET_REQUEST',
    VACANCIES_GET_SUCCESS: 'VACANCIES_GET_SUCCESS',
    VACANCIES_GET_FAILURE: 'VACANCIES_GET_FAILURE',
    VACANCIES_ADD_REQUEST: 'VACANCIES_ADD_REQUEST',
    VACANCIES_ADD_SUCCESS: 'VACANCIES_ADD_SUCCESS',
    VACANCIES_ADD_FAILURE: 'VACANCIES_ADD_FAILURE',
    VACANCIES_UPDATE_REQUEST: 'VACANCIES_UPDATE_REQUEST',
    VACANCIES_UPDATE_SUCCESS: 'VACANCIES_UPDATE_SUCCESS',
    VACANCIES_UPDATE_FAILURE: 'VACANCIES_UPDATE_FAILURE',
    VACANCIES_DELETE_REQUEST: 'VACANCIES_DELETE_REQUEST',
    VACANCIES_DELETE_SUCCESS: 'VACANCIES_DELETE_SUCCESS',
    VACANCIES_DETELE_FAILURE: 'VACANCIES_DETELE_FAILURE',
}
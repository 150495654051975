export default {
    PORTFOLIO_GET_REQUEST: 'PORTFOLIO_GET_REQUEST',
    PORTFOLIO_GET_SUCCESS: 'PORTFOLIO_GET_SUCCESS',
    PORTFOLIO_GET_FAILURE: 'PORTFOLIO_GET_FAILURE',
    PORTFOLIO_ADD_ITEM_REQUEST: 'PORTFOLIO_ADD_ITEM_REQUEST',
    PORTFOLIO_ADD_ITEM_SUCCESS: 'PORTFOLIO_ADD_ITEM_SUCCESS',
    PORTFOLIO_ADD_ITEM_FAILURE: 'PORTFOLIO_ADD_ITEM_FAILURE',
    PORTFOLIO_DELETE_ITEM_REQUEST: 'PORTFOLIO_DELETE_ITEM_REQUEST',
    PORTFOLIO_DELETE_ITEM_SUCCESS: 'PORTFOLIO_DELETE_ITEM_SUCCESS',
    PORTFOLIO_DELETE_ITEM_FAILURE: 'PORTFOLIO_DELETE_ITEM_FAILURE',
    PORTFOLIO_UPDATE_ITEM_REQUEST: 'PORTFOLIO_UPDATE_ITEM_REQUEST',
    PORTFOLIO_UPDATE_ITEM_SUCCESS: 'PORTFOLIO_UPDATE_ITEM_SUCCESS',
    PORTFOLIO_UPDATE_ITEM_FAILURE: 'PORTFOLIO_UPDATE_ITEM_FAILURE',
}
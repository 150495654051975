import anime from "animejs/lib/anime.es.js";
import styles from './portfolio-title.module.scss';

export function animate(opts) {

    anime({
      targets: `.${styles.title}`,
      translateX: ['-800px', '0px'],
      ...opts
    });

    anime({
      targets: `.${styles.portfolioLetterGroup1}`,
      translateY: ['100vh', '0vh'],
      ...opts
    });

    anime({
      targets: `.${styles.portfolioLetterGroup2}`,
      translateY: ['-100vh', '0vh'],
      ...opts
    });

}
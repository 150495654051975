import constants from "./constants";

export default function sendOfferReducer(state = {}, action) {
  switch (action.type) {
    case constants.SEND_OFFER_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}

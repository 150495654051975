import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Tabs } from "react-tabs";
import PropTypes from "prop-types";
import styles from "./main-admin.module.scss";
import AdminContentTitle from "../AdminContentTitle";
import AdminTitleEditDelete from "../AdminTitleEditDelete";

import AdminSaveButton from "../AdminButton";
import LgTextarea from "../AdminTextareas/LgTextarea";

import i18next from "i18next";

import { updateTranslations } from "../../../stores/translation/actions";
import { snakeCaseToCapitalized } from "../../pages/MainAdmin/helpers";

/**
 * @rafactor move this outside
 */

const TextBlockEdit = props => {
  const { updateTranslations, editKeys, blockName = "" } = props;

  const uaT = i18next.getFixedT("ua", "translations");
  const enT = i18next.getFixedT("en", "translations");

  useEffect(() => {
    const keys = editKeys || [];
    const companyTitlePageTextInitial = keys.map(key => ({
      key,
      ua: uaT(key) === key ? "Missing translation" : uaT(key),
      en: enT(key) === key ? "Missing translation" : enT(key)
    }));
    setTextData(companyTitlePageTextInitial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editKeys]);

  const [textData, setTextData] = useState([]);

  const setTextField = key => field => e => {
    const index = textData.findIndex(item => item.key === key);
    const newItem = { ...textData[index], [field]: e.target.value };
    const newData = [...textData];
    newData[index] = newItem;
    setTextData(newData);
  };

  const onUpdate = () => {
    updateTranslations(textData);
  };

  return (
    <div className={styles.root}>
      <AdminContentTitle title={blockName} />
      <Tabs>
        <div>
          {" "}
          {textData && (
            <>
              <div className={styles.flexContent}>
                <div className={styles.typeContent}>
                  <h2>English</h2>
                  {textData.map((text, key) => {
                    return (
                      <div key={key}>
                        <AdminTitleEditDelete 
                          title={snakeCaseToCapitalized(text.key)}
                        />
                        <LgTextarea
                          value={text.en}
                          onChange={setTextField(text.key)("en")}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={styles.typeContent}>
                  <h2>Українська</h2>
                  {textData.map((text, key) => {
                    return (
                      <div key={key}>
                        <AdminTitleEditDelete
                          title={snakeCaseToCapitalized(text.key)}
                        />
                        <LgTextarea
                          value={text.ua}
                          onChange={setTextField(text.key)("ua")}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <AdminSaveButton onClick={onUpdate} view="primary">
                Update
              </AdminSaveButton>
            </>
          )}
        </div>
      </Tabs>
    </div>
  );
};

const mapDispatchToProps = {
  updateTranslations
};

export default connect(null, mapDispatchToProps)(TextBlockEdit);

TextBlockEdit.defaultProps = {};

TextBlockEdit.propTypes = {
  updateTranslations: PropTypes.func,
  editKeys: PropTypes.array,
  blockName: PropTypes.string,
};

import anime from "animejs/lib/anime.es.js";
import styles from './header.module.scss';

export const animate = (delay) => {
  setTimeout(() => {
    anime({
      targets: `.${styles.headerRoot}`,
      translateY: ['-100%', 0],
      duration: 1000,
      easing: "linear",
    });
  }, delay);
}

import React from "react";
import PropTypes from "prop-types";
import styles from "./cv-form.module.scss";
import ClientButton from "../../components/ClientButton/ClientButton";

// const maxLengthRule = (length) => (str) => {
//   if (str.length > length) {
//     throw new Error(`Length must be less ${length}`)
//   }
// }

// const emailRule = (str) => {
//   var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (!re.test(String(str).toLowerCase())) {
//     throw new Error('Invalid Email');
//   };
// }

// const extentionRule = (extentions) => (filename) => {
//   let isValid = false;
//   for (var j = 0; j < extentions.length; j++) {
//     var curr = extentions[j];
//     if (filename.substr(filename.length - curr.length, curr.length).toLowerCase() === curr.toLowerCase()) {
//       isValid = true;
//       break;
//     }
//   }
//   if (!isValid) {
//     throw new Error('Not valid file type');
//   }
// }

const CVForm = ({ formDataJson, setFieldFormDataJson, onSubmit }) => {
  const uploaded = () => {
    document.getElementById(
      "uploadedFileName"
    ).innerHTML = document.getElementById("file").value;
  };

  return (
    <form
      id="contact-us-form"
      className={styles.rootContainer}
      onSubmit={onSubmit}
    >
      <h2 id="form-title" className={styles.formTitle}>
        Apply
      </h2>
      <div id="form-container" className={styles.formContainer}>
        <div className={styles.formContent}>
          <div id="modalCV" className={styles.modalRes}>
            <p>Your message has been sent !</p>
          </div>
          <input
            placeholder="Name*"
            value={formDataJson.name}
            onChange={setFieldFormDataJson("name")}
            className={styles.input}
            type="text"
            required
          />
          <input
            placeholder="E-mail*"
            value={formDataJson.email}
            onChange={setFieldFormDataJson("email")}
            className={styles.input}
            type="email"
            required
          />
          <div className={styles.dropdown}>
            <select
              className={styles.input}
              onChange={setFieldFormDataJson("vacancyId")}
              value={formDataJson.vacancyId || ""}
            >
              {formDataJson.options.map((opt, idx) => (
                <option key={opt[1]} value={opt[1]}>
                  {opt[0]}
                </option>
              ))}
            </select>
          </div>
          <textarea
            placeholder="Message"
            value={formDataJson.message}
            onChange={setFieldFormDataJson("message")}
            className={`${styles.input} ${styles.inputLast}`}
            type="text"
          />
          <p className={styles.fileInputText}>Upload CV (pdf, doc, docx)</p>
          <input
            id="file"
            className={styles.fileInput}
            type="file"
            accept="application/pdf, application/msword"
            onChange={setFieldFormDataJson("cv")}
            onInput={() => {
              uploaded();
            }}
          />
          <br />
          <span id="uploadedFileName"></span>
        </div>
      </div>
      <ClientButton
        button
        id={"cv-form-submit-btn"}
        type="submit"
        text="Send"
        position="center"
      />
    </form>
  );
};

CVForm.defaultProps = {};

CVForm.propTypes = {
  formDataJson: PropTypes.object,
  setFieldFormDataJson: PropTypes.func,
  onSubmit: PropTypes.func
};

export default CVForm;

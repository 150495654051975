import React from "react";
import styles from "./portfolio-title.module.scss";

const PortfolioTitle = () => {
  return (
    <>
      <h2 className={styles.title}>Portfolio</h2>
      <svg className={styles.letter}>
        <g className={styles.letterContainer}>
          <g className={styles.portfolioLetterGroup1}>
            <path
              d="M50.4642 159.331L50.4642 102.746L1.00696 131.039L50.4642 159.331Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.500061 193.293L0.500061 136.707L49.9573 165L0.500061 193.293Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M50.4642 170.669L50.4642 227.254L1.00696 198.961L50.4642 170.669Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.5 261.216L0.5 204.63L49.9572 232.923L0.5 261.216Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.499939 329.138L0.499939 272.552L49.9571 300.845L0.499939 329.138Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M50.4642 238.591L50.4642 295.177L1.00696 266.884L50.4642 238.591Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.500061 68.7846L0.500061 125.37L49.9573 97.0774L0.500061 68.7846Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M0.500554 57.4476L0.500549 0.862082L49.9578 29.1548L0.500554 57.4476Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M50.4642 91.4088L50.4642 34.8233L1.00696 63.1161L50.4642 91.4088Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
          <g className={styles.portfolioLetterGroup2}>
            <path
              d="M160.5 102.746L160.5 159.331L111.043 131.039L160.5 102.746Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M110.536 136.707L110.536 193.293L159.993 165L110.536 136.707Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M55.5376 159.41L55.5376 102.825L104.995 131.118L55.5376 159.41Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M105.502 136.787L105.502 193.372L56.0445 165.079L105.502 136.787Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M55.5179 91.4088L55.5179 34.8233L104.975 63.1161L55.5179 91.4088Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M105.482 57.4476L105.482 0.862082L56.025 29.1548L105.482 57.4476Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M160.5 34.8235L160.5 91.409L111.043 63.1162L160.5 34.8235Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M110.536 57.4476L110.536 0.862082L159.993 29.1548L110.536 57.4476Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
            <path
              d="M110.536 68.7846L110.536 125.37L159.993 97.0774L110.536 68.7846Z"
              fill="#0C0B0B"
              stroke="#555555"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default PortfolioTitle;

import anime from "animejs/lib/anime.es.js";
import styles from './career.module.scss';

export function animate() {
    anime({
        targets: `.${styles.logoBlock}`,
        translateX: ['-540px', 0],
        easing: "easeOutExpo",
        duration: 3400,
        delay: 300,
    });

    anime({
        targets: `.${styles.listItem},
        .${styles.description},
        .${styles.subtitleText},
        .${styles.title},
        .${styles.cvFormContainer}`,
        translateX: ['100vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
        delay: (el, i) => 30 * i,
    });
}
import { connect } from 'react-redux';
import * as portfolioActions from '../../../stores/portfolio/actions';
import AdminPortfolio from './AdminPortfolio';

const mapStateToProps = (state) => ({
    portfolio: state.portfolio.list
})

const mapDispatchToProps = { ...portfolioActions };

export default connect(mapStateToProps, mapDispatchToProps)(AdminPortfolio)

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { parseStrArray } from "../../../../../utils/strings";
import styles from "./slide.module.scss";
import PropTypes from "prop-types";

const Slide = props => {
  const { slideItem } = props;
  const { t } = useTranslation("translations");

  const [active, setActive] = useState(false);

  const isMobile = window.matchMedia("(max-width: 769px)").matches;
  const shouldShowDesc = !isMobile || active;

  // console.log(isMobile, active);

  return (
    <div className={styles.slideItem}>
      <div className={styles.head}>
        <img
          src={`https://magisoft.solutions/assets/${slideItem.logo}`}
          alt=""
        />
        <div>
          <h2>{t(slideItem.title)}</h2>
          <h3>{t(slideItem.summary)}</h3>
          {isMobile && (
            <p className={styles.more} onClick={() => setActive(!active)}>
              {active ? "Show less" : "Show more"}
            </p>
          )}
        </div>
      </div>
      {shouldShowDesc && (
        <div className={styles.flex}>
          <div className={styles.shape} />
          <div className={`${styles.description}`}>
            {parseStrArray(t(slideItem.description)).map((item, key) => (
              <p key={key}> {item} </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Slide.propTypes = {
  slideItem: PropTypes.object
};

export default Slide;

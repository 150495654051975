export default {
    TEAM_GET_REQUEST: 'TEAM_GET_REQUEST',
    TEAM_GET_SUCCESS: 'TEAM_GET_SUCCESS',
    TEAM_GET_FAILURE: 'TEAM_GET_FAILURE',
    TEAM_DELETE_MEMBER_REQUEST: 'TEAM_DELETE_MEMBER_REQUEST',
    TEAM_DELETE_MEMBER_SUCCESS: 'TEAM_DELETE_MEMBER_SUCCESS',
    TEAM_DELETE_MEMBER_FAILURE: 'TEAM_DELETE_MEMBER_FAILURE',
    TEAM_ADD_MEMBER_REQUEST: 'TEAM_ADD_MEMBER_REQUEST',
    TEAM_ADD_MEMBER_SUCCESS: 'TEAM_ADD_MEMBER_SUCCESS',
    TEAM_ADD_MEMBER_FAILURE: 'TEAM_ADD_MEMBER_FAILURE',
    TEAM_UPDATE_MEMBER_REQUEST: 'TEAM_UPDATE_MEMBER_REQUEST',
    TEAM_UPDATE_MEMBER_SUCCESS: 'TEAM_UPDATE_MEMBER_SUCCESS',
    TEAM_UPDATE_MEMBER_FAILURE: 'TEAM_UPDATE_MEMBER_FAILURE',
}
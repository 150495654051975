import constants from "./constants";
import axios from "axios";
import { toastr } from "react-redux-toastr";

const URL = "https://magisoft.solutions/api/schoolClientContacts";

const sendOfferRequest = (data) => ({
  type: constants.SEND_OFFER_REQUEST,
  data,
});

const sendOfferSucces = (data) => ({
  type: constants.SEND_OFFER_SUCCESS,
  data,
});

const sendOfferFailure = (error) => ({
  type: constants.SEND_OFFER_FAILURE,
  error,
});

const sendSchoolOffer = (data) => {
  return async (dispatch) => {
    dispatch(sendOfferRequest(data));
    return await axios
      .post(URL, data)
      .then((response) => {
        dispatch(sendOfferSucces(response.data));
        return response;
      })
      .catch((error) => {
        if (error.response.data.message && error.response.data.message !== "") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
        dispatch(sendOfferFailure(error));
      });
  };
};

export default sendSchoolOffer;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import i18next from "i18next";
import PropTypes from "prop-types";
import {
  getTeam,
  addTeamMember,
  updateTeamMember,
  deleteTeamMember
} from "../../../stores/team/actions";
import { updateTranslations } from "../../../stores/translation/actions";
import { ModalWindow } from "../../components";

import AdminContentTitle from "../../components/AdminContentTitle";
import AdminTitleEditDelete from "../../components/AdminTitleEditDelete";
import Button from "../../components/AdminButton/Button";
import EditForm from "./EditForm";
import LgTextarea from "../../components/AdminTextareas/LgTextarea";

import styles from "./admin-about-us.module.scss";
import "react-tabs/style/react-tabs.css";

import Delete from "../../../assets/images/delete.svg";
import { transformTeamObjToEditorObj } from "./helpers";
import { jsonToFormData } from "../../../utils/index";
import cn from "classnames";

const initialSlideData = {
  nameEn: "name",
  nameUa: "iмя",
  nameRu: "",
  positionEn: "position",
  positionUa: "позицiя",
  positionRu: "",
  image: null
};

const AdminOurTeam = props => {
  const teamDescriptionKey = "team_description_text";

  const uaT = i18next.getFixedT("ua", "translations");
  const enT = i18next.getFixedT("en", "translations");

  const {
    team,
    getTeam,
    addTeamMember,
    updateTeamMember,
    deleteTeamMember,
    updateTranslations
  } = props;

  function compare(a, b) {
    if (a.location < b.location) {
      return -1;
    }
    if (a.location > b.location) {
      return 1;
    }
    return 0;
  }

  team.sort(compare);

  /**
   * refactor this
   */
  const moveUp = index => {
    if (index === 0) {
      return;
    }

    const currentPosition = team[index].location;
    let targetPosition = team[index - 1].location;

    team[index - 1].location = currentPosition;
    team[index].location = targetPosition;

    updateTeamMember(
      jsonToFormData({
        location: currentPosition
      }),
      team[index - 1].id
    );

    updateTeamMember(
      jsonToFormData({
        location: targetPosition
      }),
      team[index].id
    );
  };

  const moveDown = index => {
    if (index === team.length - 1) {
      return;
    }

    const currentPosition = team[index].location;
    let targetPosition = team[index + 1].location;

    team[index + 1].location = currentPosition;
    team[index].location = targetPosition;

    updateTeamMember(
      jsonToFormData({
        location: currentPosition
      }),
      team[index + 1].id
    );

    updateTeamMember(
      jsonToFormData({
        location: targetPosition
      }),
      team[index].id
    );
  };

  /**
   *  Team description
   */

  const [teamDescription, setTeamDescription] = useState({
    key: teamDescriptionKey,
    ua: uaT(teamDescriptionKey),
    en: enT(teamDescriptionKey)
  });

  const setTeamDescriptionField = field => e =>
    setTeamDescription({ ...teamDescription, [field]: e.target.value });

  const onUpdateDescription = () => {
    updateTranslations([teamDescription]);
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    getTeam();
  }, [getTeam]);

  const handleTabSelect = tabIndex => {
    setTabIndex(tabIndex);
  };

  const onUpdate = json => {
    updateTeamMember(jsonToFormData(json), json.id);
  };

  const onCreate = val => {
    addTeamMember(jsonToFormData(val)).then(res => {
      if (res) {
        setIsOpenModal(false);
      }
    });
  };

  const onDelete = id => {
    if (window.confirm("Пiдтвердiть видалення обьекту")) {
      deleteTeamMember(id);
    }
  };

  return (
    <div className={styles.root}>
      <Tabs forceRenderTabPanel defaultIndex={0}>
        <AdminContentTitle title="Our Team" />
        <div className={styles.buttonTabs}>
          <TabList className={styles.buttons}>
            <Tab>Text</Tab>
            <Tab>Staff info</Tab>
          </TabList>
        </div>
        <TabPanel>
          <Tabs forceRenderTabPanel>
            <div>
              <>
                <div className={styles.flexContent}>
                  <div className={styles.typeContent}>
                    <AdminTitleEditDelete title="Text 1" />
                    <LgTextarea
                      value={teamDescription.en}
                      onChange={setTeamDescriptionField("en")}
                    />
                  </div>
                  <div className={styles.typeContent}>
                    <AdminTitleEditDelete title="Text 1" />
                    <LgTextarea
                      value={teamDescription.ua}
                      onChange={setTeamDescriptionField("ua")}
                    />
                  </div>
                </div>
                <Button
                  view="primary"
                  onClick={() => {
                    onUpdateDescription();
                  }}
                >
                  {" "}
                  Update{" "}
                </Button>
              </>
            </div>
          </Tabs>
        </TabPanel>
        <TabPanel>
          <Tabs
            forceRenderTabPanel
            onSelect={handleTabSelect}
            selectedIndex={tabIndex}
          >
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Staff</th>
                  <th>Staff info</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <TabList>
                      {team.map((t, index) => {
                        const itemClass = cn(styles.textTab, {
                          [styles.textTabHighlighted]: index === tabIndex
                        });
                        return (
                          <Tab key={index} className={itemClass}>
                            <div className={styles.tab}>
                              <span>{t.location}</span>
                              <h4 className={styles.textTab}>{enT(t.name)}</h4>
                              <div>
                                <span
                                  onClick={e => {
                                    e.stopPropagation();
                                    moveUp(index);
                                  }}
                                >
                                  ↑
                                </span>
                                <span
                                  onClick={e => {
                                    e.stopPropagation();
                                    moveDown(index);
                                  }}
                                >
                                  ↓
                                </span>
                              </div>
                              <img
                                onClick={() => onDelete(t.id)}
                                src={Delete}
                                alt=""
                              />
                            </div>
                          </Tab>
                        );
                      })}
                    </TabList>
                    <Button
                      view="add"
                      onClick={() => {
                        setIsOpenModal(true);
                      }}
                    >
                      Add new staff
                    </Button>
                  </td>
                  <td className={styles.fields}>
                    {team.map((t, i) => (
                      <TabPanel key={i}>
                        <EditForm
                          initialState={transformTeamObjToEditorObj(
                            t,
                            enT,
                            uaT
                          )}
                          onSubmit={val => {
                            onUpdate(val);
                          }}
                          onCancel={() => {
                            alert("cancel");
                          }}
                        />
                      </TabPanel>
                    ))}

                    <ModalWindow
                      isOpen={isOpenModal}
                      onClose={() => {
                        setIsOpenModal(false);
                      }}
                      className={styles.modalContent}
                    >
                      <EditForm
                        initialState={{
                          ...initialSlideData,
                          location:
                            Math.max.apply(
                              Math,
                              team.map(t => t.location)
                            ) + 1
                        }}
                        onSubmit={val => {
                          onCreate(val);
                        }}
                        onCancel={() => setIsOpenModal(false)}
                      />
                    </ModalWindow>
                  </td>
                </tr>
              </tbody>
            </table>
          </Tabs>
        </TabPanel>
      </Tabs>
    </div>
  );
};

AdminOurTeam.defaultProps = {};

AdminOurTeam.propTypes = {
  team: PropTypes.array,
  getTeam: PropTypes.func,
  addTeamMember: PropTypes.func,
  updateTeamMember: PropTypes.func,
  deleteTeamMember: PropTypes.func,
  updateTranslations: PropTypes.func
};

const mapStateToProps = state => ({
  team: state.team.list,
  error: state.team.errors
});

const mapDispatchToProps = {
  getTeam,
  addTeamMember,
  updateTeamMember,
  deleteTeamMember,
  updateTranslations
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOurTeam);

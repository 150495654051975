import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./career.module.scss";
import LeftArrows from "../../../assets/images/careerLeftArrows.svg";
import { CareerFrontendLogo, CareerBackendLogo } from "../../components";
import CVForm from "../../components/CVForm";

import { animate as animateLetter } from "../../components/CareerFrontendLogo/anime";
import { animate as animateOther } from "./anime";

import { useTranslation } from "react-i18next";
import letterF from "../../../assets/images/f.svg";
import letterB from "../../../assets/images/b.svg";

import { jsonToFormData } from "../../../utils";

const Career = props => {
  const { createJobApplication, getVacancies, vacancies, spec } = props;

  const openVacancy = e => {
    e.target.style.display = "none";
    let el = e.target.previousElementSibling;
    el.style.display = "block";
  };

  const [formDataJson, setFormDataJson] = useState({
    name: "",
    email: "",
    message: "",
    cv: null,
    options: [],
    vacancyId: null,
    errors: {}
  });

  const { t } = useTranslation("translations");

  const setFieldFormDataJson = field => e => {
    setFormDataJson({
      ...formDataJson,
      [field]:
        e.target.files && e.target.files[0] ? e.target.files[0] : e.target.value
    });
  };

  const getVacancyList = vacancies => {
    return vacancies.map((vac, idx) => {
      return [vac.name, vac.id];
    });
  };

  useEffect(() => {
    const options = getVacancyList(vacancies);
    setFormDataJson({
      ...formDataJson,
      options,
      vacancyId: options.length > 0 && options[0][1]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancies]);



  useEffect(() => {
    getVacancies().then(() => {
      setTimeout(() => {
        if (window.matchMedia("(min-width: 769px)").matches) {
          animateLetter();
          animateOther();
        }
      }, 0);
    });
  }, [spec, getVacancies]);

  const onFormSubmit = e => {
    e.preventDefault();
    createJobApplication(jsonToFormData(formDataJson)).then(res => {
      const modalCV = document.getElementById("modalCV");
      if (res) {
        modalCV.style.display = "flex";
        setTimeout(() => {
          if (modalCV) {
            modalCV.style.display = "none";
          }
        }, 2000);
      }
    });
  };

  const getLogo = spec => {
    let LogoComponent = null;

    if (spec === "front-end") {
      LogoComponent = CareerFrontendLogo;
    } else if (spec === "back-end") {
      LogoComponent = CareerBackendLogo;
    }

    return <LogoComponent />;
  };

  const getLabel = spec => {
    let label = null;

    if (spec === "front-end") {
      label = "Front-end";
    } else if (spec === "back-end") {
      label = "Back-end";
    }

    return label;
  };

  const extractList = str => {
    let arr = [];

    try {
      let parsed = JSON.parse(str);
      arr = Array.isArray(parsed) ? parsed : [];
    } catch (err) {}

    return arr;
  };

  const isFront = spec === "front-end";
  const getVacanciesBySpec = spec => vac =>
    vac.filter(v => v.specialization === spec);

  const getFrontVac = getVacanciesBySpec("frontend");
  const getBackVac = getVacanciesBySpec("backend");

  const vac = isFront ? getFrontVac(vacancies) : getBackVac(vacancies);


  return (
    <div className={styles.root}>
      <div className={styles.logoBlock}>
        <img className={styles.leftArrows} src={LeftArrows} alt="" />
        {getLogo(spec)}
      </div>

      {isFront ? (
        <div className={styles.mobileLogo}>
          <img src={letterF} className={styles.letter} alt="" />
          <h2>
            <span>Front-end</span>
            <br />
            <span>developer</span>
          </h2>
        </div>
      ) : (
        <div className={styles.mobileLogo}>
          <img src={letterB} className={styles.letter} alt="" />
          <h2>
            <span>Back-end</span>
            <br />
            <span>developer</span>
          </h2>
        </div>
      )}
      <div className={styles.content}>
        <ul className={styles.ulRoot}>
          {vac.map((vacancy) => {
            const list = [
              {
                title: "Requirements",
                list: vacancy.requirements
              },
              {
                title: "Will be plus",
                list: vacancy.willBePlus
              },
              {
                title: "Responsibilities",
                list: vacancy.responsibilities
              },
              {
                title: "Conditions",
                list: vacancy.conditions
              }
            ];

            return (
              <div key={vacancy.id}  id="fullInfo" className={styles.vacancyBlock}>
                <h3 className={styles.title}>
                  {getLabel(spec)} Developer <span>(Vinnytsia, Ukraine)</span>
                </h3>
                <div className={styles.mobileMore}>
                  <div className={styles.moreInfoBlock}>
                    {list.map((item, key) => {
                      return (
                        <li key={key} className={`${styles.subtitle} ${styles.open}`}>
                          <span className={styles.subtitleText}>
                            {item.title}
                          </span>
                          <ul>
                            {extractList(t(item.list)).map((text, key) => {
                              return (
                                <li key={key} className={styles.listItem}>{t(text)}</li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })}
                  </div>
                  <p onClick={openVacancy}>More information</p>
                </div>
              </div>
            );
          })}
        </ul>

        <div className={styles.cvFormContainer}>
          <CVForm
            setFieldFormDataJson={setFieldFormDataJson}
            formDataJson={formDataJson}
            onSubmit={onFormSubmit}
          />
        </div>
      </div>
    </div>
  );
};

Career.defaultProps = {};

Career.propTypes = {
  createJobApplication: PropTypes.func,
  getVacancies: PropTypes.func,
  vacancies: PropTypes.array,
  spec: PropTypes.string
};

export default Career;

import axios from "axios";
import constants from "./constants";
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from '../../enviroments';
import { getTranslation } from "../translation/actions";
/**
 * @TODO PRIORITY LOW reduce boilerplate
 */

/**
 * GET LIST OF TEAM MEMBERS
 */

export const getTeam = () => {
  return async dispatch => {
    dispatch(request());
    return await axios
      .get(`${PROD_BASE_URL}/team`)
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: constants.TEAM_GET_REQUEST };
  }
  function success(data) {
    return { type: constants.TEAM_GET_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.TEAM_GET_FAILURE, error };
  }
};

/**
 * REMOVE ONE TEAM MEMBER
 */

export const deleteTeamMember = id => {
  return async dispatch => {
    dispatch(request(id));
    return await axios
      .delete(`${PROD_BASE_URL}/team/${id}`)
      .then(response => {
        dispatch(success(id));
        toastr.success("Success", "Deleted");

        return response;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request() {
    return { type: constants.TEAM_DELETE_MEMBER_REQUEST };
  }
  function success(id) {
    return { type: constants.TEAM_DELETE_MEMBER_SUCCESS, payload: { id } };
  }
  function failure(error) {
    return { type: constants.TEAM_DELETE_MEMBER_FAILURE, error };
  }
};

/**
 * ADD NEW TEAM MEMBER
 */

export const addTeamMember = data => {
  return dispatch => {
    dispatch(request(data));
    return axios
      .post(`${PROD_BASE_URL}/team/`, data)
      .then(response => {
        dispatch(getTranslation()).then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Created");

        return response.data;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request() {
    return { type: constants.TEAM_ADD_MEMBER_REQUEST };
  }
  function success(data) {
    return { type: constants.TEAM_ADD_MEMBER_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.TEAM_ADD_MEMBER_FAILURE, error };
  }
};

/**
 * UPDATE ONE TEAM MEMBER
 */

export const updateTeamMember = (data, id) => {
  const tmId = id || data.id || data._id;
  return async dispatch => {
    dispatch(request(data));
    return await axios
      .put(`${PROD_BASE_URL}/team/${tmId}`, data)
      .then(response => {
        dispatch(getTranslation()).then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Updated");

        return response.data;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request() {
    return { type: constants.TEAM_UPDATE_MEMBER_REQUEST, payload: data };
  }
  function success(data) {
    return { type: constants.TEAM_UPDATE_MEMBER_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.TEAM_UPDATE_MEMBER_FAILURE, error };
  }
};

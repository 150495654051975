import React  from "react";
import PropTypes from "prop-types";
import styles from "./slide.module.scss";

import { useTranslation } from "react-i18next";

const Slide = props => {
  const { slideItem } = props;
  const { t } = useTranslation("translations");

  return (
    <div className={styles.root}>
      <div className={styles.logoDesc}>
        <img
          className={styles.logoDescImg}
          src={`https://magisoft.solutions/assets/${slideItem.logo}`}
          alt="Product Logo"
        />
        <p className={styles.logoDescText}>{t(slideItem.description)}</p>
      </div>
      <div className={styles.slideImageContainer}>
        <div className={styles.slideImageWrap}>
          <img
            className={styles.slideImage}
            src={`https://magisoft.solutions/assets/${slideItem.slide}`}
            alt="Project sample"
          />
        </div>
        <div className={styles.logoDescTextMobileBlock}>
          <p className={`${styles.logoDescText} ${styles.logoDescTextMobile}`}>
            {t(slideItem.description)}
          </p>
        </div>
      </div>
    </div>
  );
};

Slide.defaultProps = {};

Slide.propTypes = {
  slideItem: PropTypes.object
};

export default Slide;

import React from "react";
import PropTypes from "prop-types";
import styles from "./admin-upload.module.scss";

const AdminUpload = props => {
  const { onChange, name } = props;

  return (
    <div className={styles.root}>
      <input
        onChange={e => {
          onChange(e);
        }}
        className={styles.fileInput}
        id="file"
        type="file"
        placeholder="sda"
      />
      <label htmlFor="file">{name}</label>
    </div>
  );
};

AdminUpload.defaultProps = {};

AdminUpload.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string
};

export default AdminUpload;

/**
 * Main Page Title Section
 */

const companyNameKey = "company_name_text";
const companySecondNameKey = "company_second_name_text";
const companySloganKey = "company_slogan_text";
const getInTouchBtnKey = "company_get_in_touch_btn_text";

/**
 * Main Page About Us Section
 */

const aboutUsSectionNameKey = "about_us_section_name_text";
const aboutUsTitleKey = "about_us_title_text";
const aboutUsParagraphKey = "about_us_paragraph_text";
const aboutUsBtnKey = "about_us_btn_text";

/**
 * Main Page What We Do Best Section
 */

const whatWeDoBestSectionNameKey = "what_we_do_best_section_name_text";
const whatWeDoBestCapabilitiesTitleKey =
  "what_we_do_best_capabilities_title_text";
const whatWeDoBestCapabilitiesParagraphKey =
  "what_we_do_best_capabilities_paragraph_text";
const whatWeDoBestBlock1Key = "what_we_do_best_block_1_text";
const whatWeDoBestBlock2Key = "what_we_do_best_block_2_text";
const whatWeDoBestBlock3Key = "what_we_do_best_block_3_text";
const whatWeDoBestBlock4Key = "what_we_do_best_block_4_text";
const whatWeDoBestBlock5Key = "what_we_do_best_block_5_text";
const whatWeDoBestBlock6Key = "what_we_do_best_block_6_text";
const whatWeDoBestBlock7Key = "what_we_do_best_block_7_text";
const whatWeDoBestActionBtnKey = "what_we_do_best_action_btn_text";

/**
 *  Main Page Our Benefits Section
 */
const ourBenefitsSectionNameKey = "our_benefits_section_name_text";
const ourBenefitsBlock1Key = "our_benefits_block_1_text";
const ourBenefitsBlock2Key = "our_benefits_block_2_text";
const ourBenefitsBlock3Key = "our_benefits_block_3_text";

/**
 * Main Page Contact Us Section
 */

const contactUsSectionNameKey = "contact_us_section_name_text";
const contactUsFormTitleKey = "contact_us_form_title_text";
const contactUsFormFullNamePhKey = "contact_us_form_full_name_ph_text";
const contactUsFormEmailPhKey = "contact_us_form_email_ph_text";
const contactUsFormMessagePhKey = "contact_us_form_message_ph_text";
const contactUsFormSubmitBtnKey = "contact_us_form_submit_btn_text";

/**
 *  About Us Page Company Story
 */

const companyStoryKey = "company_story_text";

/**
 *  About Us Page Our Team
 */

const teamDescriptionKey = "team_description_text";

/**
 * How Do We Work, Outstaff
 */

const outstaffKey = "outstaff_text";
const outstaffTitleKey = "outstaff_title_text";

/**
 * How Do We Work, Outsource
 */

const outsourceKey = "outsource_text";
const outsourceTitleKey = "outsource_title_text";
/**
 *  How Do We Work Legal Basis
 */

const legalBasisKey = "legal_basis_text";
const legalBasisTitleKey = "legal_basis_title_text";

/**
 *  Header Nav Titles
 */
const aboutUsNavKey = "about_us_nav";
const companyStoryNavKey = "company_story_nav";
const ourTeamNavKey = "our_team_nav";
const techStackNavKey = "tech_stack_nav";
const howDoWeWorkNavKey = "how_do_we_work_nav";
const outstaffNavKey = "outstaff_nav";
const outsourceNavKey = "outsource_nav";
const legalBasisNavKey = "legal_basis_nav";
const portfolioNavKey = "portfolio_nav";
const careerNavKey = "career_nav";
const frontDevNavKey = "front_dev_nav";
const backDevNavKey = "back_dev_nav";
const contactUsNavKey = "contact_us_nav";

/**
 * Contact Us Page Titles
 */
const contactUsAddressKey = "contact_us_address";
const contactUsEmailKey = "contact_us_email";
const contactUsSkypeKey = "contact_us_skype";
const contactUsPhoneKey = "contact_us_phone";
const contactUsPhone2Key = "contact_us_phone2";
const contactUsPhone3Key = "contact_us_phone3";
const contactUsPageFormTitleKey = "contact_us_form_title";
const contactUsAppTitleKey = "contact_us_app_title";

/**
 *  Footer Nav Titles
 */
const footerCategoriesKey = "footer_categories_key";
const footerAboutUsKey = "footer_about_us_key";
const footerWhatWeDoBestKey = "footer_what_we_do_best_key";
const footerOurBenefitsKey = "footer_our_benefits_key";
const footerContactUsKey = "footer_contact_us_key";
const footerTechnologiesKey = "footer_technologies_key";
const footerTech1Key = "footer_tech_1_key";
const footerTech2Key = "footer_tech_2_key";
const footerTech3Key = "footer_tech_3_key";
const footerContactKey = "footer_contact_key";
const footerAddressKey = "footer_address_key";
const footerEmailKey = "footer_email_key";
const footerSkypeKey = "footer_skype_key";
const footerPrivacyPolicyKey = "footer_privacy_policy_key";
const footerConsentPolicyKey = "footer_consent_policy_key";

export const MainPageTitleSectionKeys = [
  companyNameKey,
  companySecondNameKey,
  companySloganKey,
  getInTouchBtnKey
];
export const MainPageAboutUsSectionKeys = [
  aboutUsSectionNameKey,
  aboutUsTitleKey,
  aboutUsParagraphKey,
  aboutUsBtnKey
];
export const MainPageWhatWeDoBestSectionKeys = [
  whatWeDoBestSectionNameKey,
  whatWeDoBestCapabilitiesTitleKey,
  whatWeDoBestCapabilitiesParagraphKey,
  whatWeDoBestBlock1Key,
  whatWeDoBestBlock2Key,
  whatWeDoBestBlock3Key,
  whatWeDoBestBlock4Key,
  whatWeDoBestBlock5Key,
  whatWeDoBestBlock6Key,
  whatWeDoBestBlock7Key,
  whatWeDoBestActionBtnKey
];
export const MainPageOurBenefitsKeys = [
  ourBenefitsSectionNameKey,
  ourBenefitsBlock1Key,
  ourBenefitsBlock2Key,
  ourBenefitsBlock3Key
];
export const MainPageContactUsPageKeys = [
  contactUsSectionNameKey,
  contactUsFormTitleKey,
  contactUsFormFullNamePhKey,
  contactUsFormEmailPhKey,
  contactUsFormMessagePhKey,
  contactUsFormSubmitBtnKey
];

export const AboutUsPageCompanyStoryKeys = [companyStoryKey];

export const AboutUsPageOurTeamKeys = [teamDescriptionKey];

export const HowDoWeWorkOutstaffKeys = [outstaffKey, outstaffTitleKey];

export const HowDoWeWorkOutsourceKeys = [outsourceKey, outsourceTitleKey];

export const HowDoWeWorkLegalBasisKeys = [legalBasisKey, legalBasisTitleKey];

export const HeaderNavTitlesKeys = [
  aboutUsNavKey,
  companyStoryNavKey,
  ourTeamNavKey,
  techStackNavKey,
  howDoWeWorkNavKey,
  outsourceNavKey,
  outstaffNavKey,
  legalBasisNavKey,
  portfolioNavKey,
  careerNavKey,
  frontDevNavKey,
  backDevNavKey,
  contactUsNavKey
];

export const FooterTitlesKeys = [
  footerCategoriesKey,
  footerAboutUsKey,
  footerWhatWeDoBestKey,
  footerOurBenefitsKey,
  footerContactUsKey,
  footerTechnologiesKey,
  footerTech1Key,
  footerTech2Key,
  footerTech3Key,
  footerContactKey,
  footerAddressKey,
  footerEmailKey,
  footerSkypeKey,
  footerPrivacyPolicyKey,
  footerConsentPolicyKey
];

export const ContactsInfoKeys = [
  contactUsAddressKey,
  contactUsEmailKey,
  contactUsSkypeKey,
  contactUsPhoneKey,
  contactUsPhone2Key,
  contactUsPhone3Key,
  
];

export const ContactsFormTitleKeys= [contactUsPageFormTitleKey];
export const ContactsAppTitleKeys = [contactUsAppTitleKey];

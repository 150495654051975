import anime from "animejs/lib/anime.es.js";
import styles from './about-us-our-team-logo.module.scss';

export function animate() {
    
    /**
     * Letter animation
     */

    anime({
      targets: `.${styles.letterTop}`,
      translateY: ['-100vh', 0],
      translateX: ['-50vh', 0],
      easing: "easeOutExpo",
      duration: 3400,
    });

    anime({
      targets: `.${styles.letterBottom}`,
      translateY: ['100vh', 0],
      translateX: ['50vh', 0],
      easing: "easeOutExpo",
      duration: 3400,
    });

    
    anime({
      targets: `.${styles.title}>span:first-child`,
      translateY: ['-100vh', 0],
      translateX: ['-50vh', 0],
      easing: "easeOutExpo",
      duration: 3600,
    });

    anime({
      targets: `.${styles.title}>span:last-child`,
      translateY: ['-100vh', 0],
      translateX: ['-50vh', 0],
      easing: "easeOutExpo",
      duration: 2600,
    });

}
import React from "react";
import styles from "./career-backend-logo.module.scss";

const CareerBackendLogo = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <span>Back-end</span>
        <span>Developer</span>
      </h2>

      <svg className={styles.letterAnim} xmlns="http://www.w3.org/2000/svg">
        <g className={styles.letterTop}>
          <path
            d="M77.494 72.4464L28.5093 44.1651L77.7478 15.4442L77.494 72.4464Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M131.981 44.1651L82.9966 72.4464L82.7428 15.4442L131.981 44.1651Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M188.981 77.1651L139.997 105.446L139.743 48.4442L188.981 77.1651Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M135.5 114.862L135.5 171.424L86.0082 143.143L135.5 114.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M77.4891 204.446L28.5045 176.165L77.7429 147.444L77.4891 204.446Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M74.994 76.7764L26.0093 48.4952L25.7555 105.497L74.994 76.7764Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M134.481 48.4952L85.4966 76.7764L134.735 105.497L134.481 48.4952Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M140.5 114.862L140.5 171.424L189.993 143.143L140.5 114.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M191.481 81.4952L142.497 109.776L191.735 138.497L191.481 81.4952Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M83.5005 147.862L83.5005 204.424L132.993 176.143L83.5005 147.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M26.0011 114.495L74.9858 142.777L25.7473 171.497L26.0011 114.495Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M77.4862 138.446L28.5015 110.165L77.74 81.4442L77.4862 138.446Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
        <g className={styles.letterBottom}>
          <path
            d="M136.5 246.862L136.5 303.424L87.0082 275.143L136.5 246.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M193.5 213.862L193.5 270.424L144.008 242.143L193.5 213.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M84.5005 279.862L84.5005 336.424L133.993 308.143L84.5005 279.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M141.5 246.862L141.5 303.424L190.993 275.143L141.5 246.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M140.5 180.862L140.5 237.424L189.993 209.143L140.5 180.862Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M74.9891 208.776L26.0045 180.495L25.7506 237.497L74.9891 208.776Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M25.9963 246.495L74.9809 274.776L25.7424 303.497L25.9963 246.495Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M77.4813 270.446L28.4966 242.165L77.7351 213.444L77.4813 270.446Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
          <path
            d="M77.9813 336.446L28.9966 308.165L78.2351 279.444L77.9813 336.446Z"
            fill="#0C0B0B"
            stroke="#555555"
          />
        </g>
      </svg>
    </div>
  );
};

export default CareerBackendLogo;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./admin-about-us.module.scss";

import AdminUpload from "../../components/AdminUpload";
import Button from "../../components/AdminButton";
import LgTextarea from "../../components/AdminTextareas/LgTextarea";
import AdminTitleEditDelete from "../../components/AdminTitleEditDelete";

/**
 * @refactor this form logic can be splitted to component
 */

const EditForm = props => {

  const { onSubmit, onCancel, initialState } = props;

  const [formData, setFormData] = useState(initialState);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);

  useEffect(() => {
    const hasDataChanged =
      JSON.stringify(initialState) !== JSON.stringify(formData);
    setHasUnsavedData(hasDataChanged);
  }, [initialState, formData]);

  const setFormDataField = field => {
    return e => {
      let newStateObj = { ...formData };

      if (e.target.files && e.target.files[0]) {
        newStateObj[field] = e.target.files[0];
        newStateObj[field + "Name"] = e.target.value;
      } else {
        newStateObj[field] = e.target.value;
      }

      setFormData(newStateObj);

      const hasDataChanged =
        JSON.stringify(initialState) !== JSON.stringify(newStateObj);
      setHasUnsavedData(hasDataChanged);
    };
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(formData);
      }}
    >
      {formData && (
        <>
          <div className={styles.actionBlock}></div>
          <div className={styles.upload}>
            <AdminUpload
              onChange={setFormDataField("image")}
              name={
                formData.imageName || "Team member image (recommended ?x?px)"
              }
            />
          </div>
          <div className={styles.flex}>
            <div className={styles.leaderItem}>
              <AdminTitleEditDelete title={formData.nameEn} />
              <LgTextarea
                title="Name"
                value={formData.nameEn}
                onChange={setFormDataField("nameEn")}
              />
              <LgTextarea
                title="Position"
                value={formData.positionEn}
                onChange={setFormDataField("positionEn")}
              />
            </div>
            <div className={styles.leaderItem}>
              <AdminTitleEditDelete title={formData.nameUa} />
              <LgTextarea
                value={formData.nameUa}
                onChange={setFormDataField("nameUa")}
                title="Ім’я"
              />
              <LgTextarea
                value={formData.positionUa}
                onChange={setFormDataField("positionUa")}
                title="Посада"
              />
            </div>
          </div>
          <div className={styles.actionBlockBottom}>
            <Button
              view="primary"
              type="submit"
              disabled={!hasUnsavedData}
            >
              {" "}
              Save{" "}
            </Button>
            <Button
              view="danger"
              type="button"
              onClick={() => {
                if (hasUnsavedData) {
                  if (
                    window.confirm(
                      "Ви впевнені, що хочете скасувати внесені зміни?"
                    )
                  ) {
                    onCancel();
                  }
                } else {
                  onCancel();
                }
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

EditForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initialState: PropTypes.object
};

export default EditForm;

import styles from './doBest.module.scss';
import anime from "animejs/lib/anime.es.js";

export const animate = () => {
    anime({
        targets: `.${styles.capabilitiesBack}`,
        translateX: [-400, 0],
        duration: 3500,
        easing: 'easeOutExpo'
    });
    anime({
        targets: `.${styles.capabilitiesTitle}`,
        translateX: [-400, 0],
        fontSize: [70, 50],
        duration: 3500,
        easing: 'easeOutExpo'
    });
    anime({
        targets: `.${styles.capabilitiesBackTrap}`,
        translateX: [-400, 0],
        translateY: [100, 0],
        duration: 3500,
        easing: 'easeOutExpo'
    });
    anime({
        targets: `.${styles.capabilitiesText}`,
        translateX: [-400, 0],
        translateY: [100, 0],
        duration: 3500,
        easing: 'easeOutExpo'
    });
    anime({
        targets: `.${styles.iconCards}`,
        opacity: [0, 1],
        duration: 1500,
        delay: 1500,
        easing: 'easeOutExpo'
    });

    anime({
        targets: `.${styles.iconCardsBottom}`,
        opacity: [0, 1],
        duration: 1500,
        delay: 2000,
        easing: 'easeOutExpo'
    });

    anime({
        targets: `.${styles.button}`,
        translateY: [1000, 0],
        duration: 1500,
        delay: 1500,
        easing: 'easeOutExpo'
    });

}
import axios from "axios";
import constants from "./constants";
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from '../../enviroments';

export const login = data => {
  return async dispatch => {
    dispatch(request(data));
    return await axios
      .post(`${PROD_BASE_URL}/auth/login`, data)
      .then(response => {
        localStorage.setItem("usertoken", response.data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        dispatch(success(response.data));
        return response;
      })
      .catch(error => {
        if (error.response.data.message && error.response.data.message !== "") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
        dispatch(failure(error));
      });
  };

  function request(data) {
    return { type: constants.LOGIN_REQUEST, data };
  }
  function success(data) {
    return { type: constants.LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.LOGIN_FAILURE, error };
  }
};


export const checkToken = token => {
  return async dispatch => {
    dispatch(request(token));
    return await axios
      .get(`${PROD_BASE_URL}/auth/checkToken`, { headers: { Authorization: `Bearer ${token}` }} )
      .then(response => {
        dispatch(success(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request(data) {
    return { type: constants.CHECK_TOKEN_REQUEST, payload: data };
  }
  function success(data) {
    return { type: constants.CHECK_TOKEN_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.CHECK_TOKEN_FAILURE, error };
  }
};

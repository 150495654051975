import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import styles from "./admin-contact-us.module.scss";
import "react-tabs/style/react-tabs.css";
import TextBlockEdit from "../../components/TextBlockEdit/TextBlockEdit";
import {
  ContactsInfoKeys,
  ContactsFormTitleKeys,
  ContactsAppTitleKeys
} from "../../../routes/setupKeys";


const AdminContactUs = () => {
  return (
    <div className={styles.root}>
      <Tabs forceRenderTabPanel defaultIndex={0}>
        <div className={styles.buttonTabs}>
          <TabList className={styles.buttons}>
            <Tab>Contacts info</Tab>
            <Tab>Form title</Tab>
            <Tab>App title</Tab>
          </TabList>
        </div>
        <TabPanel>
          <TextBlockEdit
            blockName="Contacts page info"
            editKeys={ContactsInfoKeys}
          />
        </TabPanel>
        <TabPanel>
          <TextBlockEdit
            blockName="Form title"
            editKeys={ContactsFormTitleKeys}
          />
        </TabPanel>
        <TabPanel>
          <TextBlockEdit
            blockName="App title"
            editKeys={ContactsAppTitleKeys}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AdminContactUs;

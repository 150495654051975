import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientButton from "../../components/ClientButton/ClientButton";

import PropTypes from "prop-types";
import styles from "./contact-us-page-form.module.scss";
import { sendOffer } from "../../../stores/contactUsForm/actions";

const initialState = {
  fullname: "",
  email: "",
  skype: "",
  companyName: "",
  projectType: "",
  message: ""
};

const ContactUsPageForm = props => {
  const [
    { fullname, email, skype, companyName, projectType, message },
    setState
  ] = useState(initialState);

  const { sendOffer } = props;
  const clearState = () => {
    setState({ ...initialState });
  };

  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = { fullname, email, skype, companyName, projectType, message };
    console.log(data);
    sendOffer(data).then(res => {
      const modalContact = document.getElementById("modalContact");
      if (res) {
        modalContact.style.display = "flex";
        setTimeout(() => {
          if (modalContact) {
            modalContact.style.display = "none";
          }
        }, 2000);
      }
      clearState();
    });
  };

  return (
    <form
      id="contact-us-form"
      onSubmit={handleSubmit}
      className={styles.rootContainer}
    >
      <h2 id="form-title" className={styles.formTitle}>
        {props.title}
      </h2>
      <div id="form-container" className={styles.formContainer}>
        <div className={styles.formContent}>
          <div id="modalContact" className={styles.modalRes}>
            <p>Your message has been sent !</p>
          </div>
          <input
            placeholder="Full Name*"
            className={styles.input}
            type="text"
            name="fullname"
            value={fullname}
            onChange={onChange}
            required
          />
          <input
            placeholder="E-mail*"
            className={styles.input}
            type="text"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
          <input
            placeholder="Skype"
            name="skype"
            value={skype}
            className={styles.input}
            type="text"
            onChange={onChange}
          />
          <input
            placeholder="Company Name"
            className={styles.input}
            type="text"
            name="companyName"
            onChange={onChange}
            value={companyName}
          />
          <div className={styles.dropdown}>
            <select
              placeholder="Type of project"
              name="projectType"
              value={projectType}
              onChange={onChange}
              className={`${styles.input} ${styles} ${styles.select}`}
            >
              <option>Type of Project*</option>
              <option>WEB/MOBILE APPLICATION</option>
              <option>DESKTOP APPLICATION</option>
              <option>BUSINESS CARD WEBSITE</option>
              <option>LANDING PAGE</option>
              <option>ONLINE SHOP</option>
              <option>CORPORATE IDENTITY</option>
              <option>CRM</option>
            </select>
          </div>
          <textarea
            placeholder="Message*"
            value={message}
            name="message"
            className={`${styles.input} ${styles.inputLast}`}
            type="text"
            onChange={onChange}
          />
        </div>
      </div>
      <ClientButton
        button
        id={"cv-form-submit-btn"}
        type="submit"
        text="Send"
        position="center"
      />
    </form>
  );
};

ContactUsPageForm.defaultProps = {};

ContactUsPageForm.propTypes = {
  sendOffer: PropTypes.func,
  title: PropTypes.string,
  data: PropTypes.object
};

const mapStateToProps = state => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendOffer: bindActionCreators(sendOffer, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPageForm);

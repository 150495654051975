import React, { useEffect, useState } from 'react';
import styles from './footer.module.scss';
import { connect } from 'react-redux';

import Accordion from '../Accordion';

import logoFooter from '../../../assets/images/magisoftFooterLogo.png';
import facebookLogo from '../../../assets/icons/facebookLogo.png';
import linkedinLogo from '../../../assets/icons/linkedinLogo.png';
import instagramLogo from '../../../assets/icons/instagramLogo.png';
import addressIcon from '../../../assets/images/address.svg';
import emailIcon from '../../../assets/images/contact.svg';
import skypeIcon from '../../../assets/images/skype.svg';
import phoneIcon from '../../../assets/images/phone.svg';
import mobilefacebookLogo from '../../../assets/images/facebookLogo.png';
import mobilelinkedinLogo from '../../../assets/images/linkedinLogo.png';
import mobileinstagramLogo from '../../../assets/images/instagramLogo.png';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Policy from '../../../assets/privacy-policy.pdf';
/**
 * @TODO обсудить куда подключить роуты
 */

const Footer = (props) => {
    const { contactUsInfo, i18n } = props;
    const [content, setContect] = useState();

    const { t } = useTranslation('translations');

    useEffect(() => {
        const info = contactUsInfo
            ? contactUsInfo.find((item) => {
                  return i18n.language === item.lang;
              })
            : null;
        setContect(info && info.translation);
    }, [contactUsInfo, i18n.language]);

    const Categories = {
        links: [
            {
                title: t('footer_about_us_key'),
                path: '#',
            },
            {
                title: t('footer_what_we_do_best_key'),
                path: '#',
            },
            {
                title: t('footer_our_benefits_key'),
                path: '#',
            },
            {
                title: t('footer_contact_us_key'),
                path: '#',
            },
        ],
    };
    const Technologies = {
        links: [
            {
                title: t('footer_tech_1_key'),
                path: '/technology-stack?t=node',
            },
            {
                title: t('footer_tech_2_key'),
                path: '/technology-stack?t=react',
            },
            {
                title: t('footer_tech_3_key'),
                path: '/technology-stack?t=native',
            },
        ],
    };

    const Contact = {
        links: [
            {
                title: t('footer_address_key'),
                icon: addressIcon,
            },
            {
                title: t('footer_email_key'),
                icon: emailIcon,
            },
            {
                title: t('footer_skype_key'),
                icon: skypeIcon,
            },
            {
                title: `${content && content.contact_us_phone}`,
                icon: phoneIcon,
            },
        ],
    };

    return (
        <div className={styles.container}>
            <div className={styles.footerRoot}>
                <div className={styles.logoRefs}>
                    <img
                        src={logoFooter}
                        className={styles.logoFooter}
                        alt="logo"
                    />
                    <div className={styles.socialLogos}>
                        <a
                            href="https://www.facebook.com/magisoft.solutions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={facebookLogo} alt="facebook" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/magisoft-inc"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={linkedinLogo} alt="linkedin" />
                        </a>
                        <a
                            href="https://www.instagram.com/magisoft.solutions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={instagramLogo} alt="instagram" />
                        </a>
                    </div>
                </div>

                <div className={styles.footerContent}>
                    <div className={styles.footerItem}>
                        <h2>{t('footer_categories_key')}</h2>
                        <HashLink to="/#about-us">
                            {t('footer_about_us_key')}
                        </HashLink>
                        <HashLink to="/#what-we-do-best">
                            {t('footer_what_we_do_best_key')}
                        </HashLink>
                        <HashLink to="/#benefits">
                            {t('footer_our_benefits_key')}
                        </HashLink>
                        <HashLink to="/#contact-us">
                            {t('footer_contact_us_key')}
                        </HashLink>
                    </div>
                    <div className={styles.footerItem}>
                        <h2>{t('footer_technologies_key')}</h2>
                        <Link to="/technology-stack?t=node">
                            {t('footer_tech_1_key')}
                        </Link>
                        <Link to="/technology-stack?t=react">
                            {t('footer_tech_2_key')}
                        </Link>
                        <Link to="/technology-stack?t=native">
                            {t('footer_tech_3_key')}
                        </Link>
                    </div>
                    <div className={styles.footerItem}>
                        <h2>{t('footer_contact_key')}</h2>
                        <div className={styles.info} type="address">
                            <div>
                                <img src={addressIcon} alt="" />
                            </div>
                            {t('footer_address_key')}
                        </div>
                        <div className={styles.info}>
                            <div>
                                <img src={emailIcon} alt="" />
                            </div>
                            {t('footer_email_key')}
                        </div>
                        {/* //TODO removed fot now */}
                        {/* <div className={styles.info}>
              <div>
                <img src={skypeIcon} alt="" />
              </div>
              {t("footer_skype_key")}
            </div> */}
                        <div className={styles.info} type="tel">
                            <div>
                                <img src={phoneIcon} alt="" />
                            </div>
                            {content && content.contact_us_phone}
                        </div>
                    </div>
                </div>

                {/* /*Mobile*/}
                <div className={styles.menuDropdown}>
                    <Accordion
                        title={t('footer_categories_key')}
                        content={Categories}
                    />
                    <Accordion
                        title={t('footer_technologies_key')}
                        content={Technologies}
                    />
                    <Accordion
                        title={t('footer_contact_key')}
                        content={Contact}
                    />
                </div>
                <div className={styles.links}>
                    <a
                        href="https://www.facebook.com/magisoft.solutions"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={mobilefacebookLogo} alt="facebook" />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/magisoft-inc"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={mobilelinkedinLogo} alt="linkedin" />
                    </a>
                    <a
                        href="https://www.instagram.com/magisoft.solutions/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={mobileinstagramLogo} alt="instagram" />
                    </a>
                </div>
                <div className={styles.mainLogoMobile}>
                    <img
                        src={logoFooter}
                        className={styles.logoFooterMobile}
                        alt="logo"
                    />
                    <div>
                        <a
                            href={Policy}
                            // href="https://magisoft.solutions/assets/privacy-policy.pdf"
                            // download="privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('footer_privacy_policy_key')}
                        </a>
                        {/* eslint-disable-next-line  */}
                        {/* <a href="#" target="_blank" rel="noopener noreferrer">
              {t("footer_consent_policy_key")}
            </a> */}
                    </div>
                </div>

                {/* Mobile */}
            </div>
            <hr />
            <div className={styles.lowerText}>
                <p>© 2019 MAGISOFT, ALL RIGHTS RESERVED</p>
                <div className={styles.rightsLinks}>
                    <a
                        //href="https://magisoft.solutions/assets/privacy-policy.pdf"
                        href={Policy}
                        //  download="privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('footer_privacy_policy_key')}
                    </a>
                    {/* eslint-disable-next-line  */}
                    {/* <a href="#" target="_blank" rel="noopener noreferrer">
            {t("footer_consent_policy_key")}
          </a> */}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    contactUsInfo: state.contactUsInfo.list,
});

export default withTranslation()(connect(mapStateToProps)(Footer));

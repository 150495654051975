import React from "react";
import HowDoWeWork from "./HowDoWeWork";
import { HowWeWorkLegalBasisLogo } from "../../components";

const LegalBasis = () => {
  return (
    <HowDoWeWork
      logo={<HowWeWorkLegalBasisLogo />}
      title={"legal_basis_title_text"}
      text={"legal_basis_text"}
    />
  );
};

export default LegalBasis;

import React from "react";
import styles from "../AdminAboutUs/admin-about-us.module.scss";
import "react-tabs/style/react-tabs.css";
import { Tabs } from "react-tabs";
import AdminContentTitle from "../../components/AdminContentTitle";
import AdminTitleEditDelete from "../../components/AdminTitleEditDelete";
import i18next from "i18next";
import LgTextarea from "../../components/AdminTextareas/LgTextarea";
import { useState } from "react";
import Button from "../../components/AdminButton";
import { updateTranslations } from "../../../stores/translation/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AdminSchool = (props) => {
  const schoolInfoKey = "school_text";

  const { updateTranslations } = props;

  const uaT = i18next.getFixedT("ua", "translations");
  const enT = i18next.getFixedT("en", "translations");

  const [schoolInfo, setSchoolInfo] = useState({
    key: schoolInfoKey,
    ua: uaT(schoolInfoKey),
    en: enT(schoolInfoKey),
  });

  const setSchoolInfoField = (field) => (e) =>
    setSchoolInfo({ ...schoolInfo, [field]: e.target.value });

  const onUpdate = () => {
    updateTranslations([schoolInfo]);
  };

  return (
    <div className={styles.root}>
      <AdminContentTitle title="IT School info" />
      <Tabs>
        <div>
          {" "}
          <>
            <div className={styles.flexContent}>
              <div className={styles.typeContent}>
                <AdminTitleEditDelete title="School info en" />
                <LgTextarea
                  value={schoolInfo.en}
                  onChange={setSchoolInfoField("en")}
                />
              </div>
              <div className={styles.typeContent}>
                <AdminTitleEditDelete title="School info ua" />
                <LgTextarea
                  value={schoolInfo.ua}
                  onChange={setSchoolInfoField("ua")}
                />
              </div>
            </div>

            <Button onClick={() => onUpdate()} view="primary">
              Update
            </Button>
          </>
        </div>{" "}
      </Tabs>{" "}
    </div>
  );
};

AdminSchool.defaultProps = {};

AdminSchool.propTypes = {
  updateTranslations: PropTypes.func,
};

const mapDispatchToProps = {
  updateTranslations,
};

export default connect(null, mapDispatchToProps)(AdminSchool);

import constants from "./constants";

export default function loginReducer(state = {}, action) {
  switch (action.type) {
    case constants.LOGIN_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}

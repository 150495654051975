import axios from "axios";
import constants from "./constants";
import i18n from "../../i18n";
import { toastr } from "react-redux-toastr";
import { PROD_BASE_URL } from '../../enviroments';

export const getTranslation = () => {
  return async dispatch => {
    dispatch(request());
    return await axios
      .get(`${PROD_BASE_URL}/internalization`)
      .then(response => {
        i18n.addResourceBundle(
          "en",
          "translations",
          response.data[0].translation
        );
        i18n.addResourceBundle(
          "ru",
          "translations",
          response.data[1].translation
        );
        i18n.addResourceBundle(
          "ua",
          "translations",
          response.data[2].translation
        );
        i18n.changeLanguage("en");
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: constants.TRANSLATION_GET_REQUEST };
  }
 
  function failure(error) {
    return { type: constants.TRANSLATION_GET_FAILURE, error };
  }
};

export const updateTranslations = data => {
  return async dispatch => {
    dispatch(request(data));
    const promises = data.map(d =>
      axios.put(`${PROD_BASE_URL}/internalization`, d)
    );
    return await Promise.all(promises)
      .then(response => {
        dispatch(getTranslation()).then(() => {
          dispatch(success(response.data));
        });
        toastr.success("Success", "Updated");
        return response;
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.data.message && error.response.data.message !=="") {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong");
        }
      });
  };

  function request() {
    return { type: constants.TRANSLATION_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: constants.TRANSLATION_UPDATE_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: constants.TRANSLATION_UPDATE_SUCCESS, error };
  }
};

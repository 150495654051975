import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./modal-window.module.scss";

const ModalWindow = props => {
  const { children, isOpen, onClose } = props;

  useEffect(() => {
    if (isOpen) {
      scrollingOff();
    }
    else {
      scrollingOn();
    }
  }, [isOpen])

  const scrollingOn = () => {
    const body = document.getElementsByTagName("body");
    body[0].style.webkitOverflowScrolling = "touch";

    body[0].style.overflow = "visible";
    body.ontouchstart = e => {
      e.preventDefault();
    };
  };

  const scrollingOff = () => {
    const body = document.getElementsByTagName("body");
    body[0].style.webkitOverflowScrolling = "touch";

    body[0].style.overflow = "hidden";
    body.ontouchstart = e => {
      e.preventDefault();
    };
  };

  const closeModal = () => {
    onClose();
  }


  return (
    <>
      {isOpen && (
        <>
          <div
            className={styles.modalWrap}
            onClick={e => {
              e.stopPropagation();
              closeModal();
            }}
          />
          <div className={styles.modal}>
            {props.login ? null : (
              <span
                onClick={e => {
                  e.stopPropagation();
                  closeModal();
                }}
                className={styles.crossModal}
              >
                &#10006;
              </span>
            )}

            {children}
          </div>
        </>
      )}
    </>
  );
};

ModalWindow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default ModalWindow;


import anime from "animejs/lib/anime.es.js";
import styles from './our-team.module.scss';

export function animate() {

    anime({
        targets: `.${styles.teamCEOImg}`,
        translateX: ['50vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
    });

    anime({
        targets: `.${styles.teamCEO} h2, h3 `,
        translateX: ['50vw', 0],
        easing: "easeOutExpo",
        duration: 4000,
        delay: 300
    });

    anime({
        targets: `.${styles.backGroundRectangleLeft}`,
        translateY: ['-50vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
    });

    anime({
        targets: `.${styles.description} p`,
        translateX: ['-100vw', 0],
        easing: "easeOutExpo",
        duration: 3000,
        delay: (el, i) => 200 * i,
    });

 
}
import React from "react";
import { Route, Switch } from "react-router-dom";

// import pages

import Home from "../views/pages/Home";
import Portfolio from "../views/pages/Portfolio";
import CompanyStory from "./../views/pages/CompanyStory";
import Career from "./../views/pages/Career";
import ContactUs from "./../views/pages/ContactUs";
import TechnologyStack from "../views/pages/TechnologyStack";
import Outstaff from "../views/pages/HowDoWeWork/Outstaff";
import Outsource from "../views/pages/HowDoWeWork/Outsource";
import LegalBasis from "../views/pages/HowDoWeWork/LegalBasis";
import OurTeam from "./../views/pages/OurTeam";
import AdminPanel from "./../views/pages/AdminPanel";
import MagiSchool from "../views/pages/School/MagiSchool";
import TraineePage from "../views/pages/Trainee/TraineePage";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/company-story" component={CompanyStory} />
        <Route exact path="/career" component={Career} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/technology-stack" component={TechnologyStack} />
        {/* intentionally remount components */}
        <Route
          exact
          path="/career-front-end"
          render={() => <Career key={Date.now()} spec="front-end" />}
        />
        <Route
          exact
          path="/career-back-end"
          render={() => <Career key={Date.now()} spec="back-end" />}
        />
        <Route exact path="/outstaff" component={Outstaff} />
        <Route exact path="/outsource" component={Outsource} />
        <Route exact path="/legal-basis" component={LegalBasis} />
        <Route exact path="/our-team" component={OurTeam} />
        <Route exact path="/admin-panel" component={AdminPanel} />
        <Route exact path="/school" component={MagiSchool} />
        <Route exact path="/trainee" component={TraineePage} />
      </Switch>
    </>
  );
};
export default Routes;

export default {
    TECH_STACK_GET_REQUEST: 'TECH_STACK_GET_REQUEST',
    TECH_STACK_GET_SUCCESS: 'TECH_STACK_GET_SUCCESS',
    TECH_STACK_GET_FAILURE: 'TECH_STACK_GET_FAILURE',
    TECH_STACK_DELETE_ITEM_REQUEST: 'TECH_STACK_DELETE_ITEM_REQUEST',
    TECH_STACK_DELETE_ITEM_SUCCESS: 'TECH_STACK_DELETE_ITEM_SUCCESS',
    TECH_STACK_DELETE_ITEM_FAILURE: 'TECH_STACK_DELETE_ITEM_FAILURE',
    TECH_STACK_ADD_ITEM_REQUEST: 'TECH_STACK_ADD_ITEM_REQUEST',
    TECH_STACK_ADD_ITEM_SUCCESS: 'TECH_STACK_ADD_ITEM_SUCCESS',
    TECH_STACK_ADD_ITEM_FAILURE: 'TECH_STACK_ADD_ITEM_FAILURE',
    TECH_STACK_UPDATE_ITEM_REQUEST: 'TECH_STACK_UPDATE_ITEM_REQUEST',
    TECH_STACK_UPDATE_ITEM_SUCCESS: 'TECH_STACK_UPDATE_ITEM_SUCCESS',
    TECH_STACK_UPDATE_ITEM_FAILURE: 'TECH_STACK_UPDATE_ITEM_FAILURE',
}
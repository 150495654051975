import styles from '../../../../components/Card/card.module.scss';
import anime from "animejs/lib/anime.es.js";

export const animate = () => {
    anime({
        targets: `.${styles.root} `,
        rotateY: [60, 0],
        duration: 3000,
        delay: 2000,
        easing: "easeOutExpo"
    });
    anime({
        targets: `.${styles.root}`,
        opacity: [0, 1],
        duration: 1000,
        delay: 2000,
        easing: "easeOutExpo"
    });
} 